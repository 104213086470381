import angular from 'angular';
import moment from 'moment';
import { TipoBeneficios, TipoBeneficiosEnum } from '../../beneficios.enum';
import { TrabalhadoresService } from '../../../../trabalhadores.service';
import { PlanosModalService } from '../../modal/planos/planos.modal.service';
import { ICancelamentoBeneficioGet } from '../../model/cancelamento.model';

export class MeurhBeneficiosCancelamentoFormController {

    static $inject = [
        '$scope',
        'PlanosModalService',
        'TrabalhadoresService'
    ];

    public colaborador: string;
    public tipoBeneficio: TipoBeneficiosEnum;
    public limitDate: Date | string;
    public entity: ICancelamentoBeneficioGet;
    public form: angular.IFormController;
    public editable = false;

    public tiposDeBeneficiosConst = TipoBeneficios;
    public nomeBeneficiario: string;
    public busy = false;

    constructor(
        public $scope: angular.IScope,
        public PlanosModalService: PlanosModalService,
        public TrabalhadoresService: TrabalhadoresService
    ) {}

    $onInit() {
        if (!this.editable) {
            this.setBeneficiario();
        } else {
            this.validateDataExclusao();
        }
    }

    setBeneficiario() {
        if (!this.entity.dependentetrabalhador?.dependentetrabalhador) {
            this.nomeBeneficiario = this.entity.trabalhador.nome + ' (Titular)';
            return;
        }
        this.nomeBeneficiario = this.entity.dependentetrabalhador.nome + ' (Dependente)';
    }

    validateDataExclusao() {
        this.$scope.$watch('$ctrl.entity.dataexclusao', (newValue: string, oldValue: string) => {
            if (
                this.limitDate &&
                newValue !== oldValue &&
                moment(newValue).isBefore(moment(this.limitDate))
            ) {
                this.entity.dataexclusao = '';
            }
        }, true);
    }

    openPlanosModal() {
        const modal = this.PlanosModalService.open({
            beneficio: this.entity.beneficio,
            tipoBeneficio: this.tipoBeneficio,
            editable: this.editable
        });
        modal.result.then((response) => {
            this.entity.beneficio = response;
        }).catch(() => {/**/});
    }

}
