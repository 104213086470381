import angular from 'angular';
import moment from 'moment';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { Tiposcalculos } from '../../../../modules/Tiposcalculos/tiposcalculos.service';
import { ModalConfirmService } from '../../../../shared/components/modal-confirm/modal.confirm.service';
import { MeurhRecibospagamentosService } from '../../../Meurh/Recibospagamentos/recibospagamentos.service';

type IContrachequesIndexFilter = {
    empresa?: string;
    estabelecimento?: string;
    ano?: number;
    mes?: number;
    tipo?: string;
    trabalhador?: {
        trabalhador: string;
    } | null;
    lotacao?: {
        lotacao: string;
    } | null;
};

export class ContrachequesIndexController {
    static $inject = [
        '$rootScope',
        '$http',
        '$uibModal',
        'NewToaster',
        'Tiposcalculos',
        'MeurhRecibospagamentosService',
        'ModalConfirmService',
        'ListagemService',
        'RelatoriosService',
        'ClientSession',
        'nsjRouting',
    ];

    public busy: boolean = false;
    public checkboxPreenchidos: any = [];
    public tiposcalculos: any;
    public lookupTrabalhadores: boolean = false;
    public primeiroLookupTrabalhadoresCarregado: boolean = false;
    public parametrosSessao: any = null;
    public primeiroMes = moment().format('MM');
    public filters: IContrachequesIndexFilter = {
        ano: moment().year(),
        mes: moment().month(),
        tipo: '0',
    };
    public contracheques: any;
    public isDownloading: boolean;
    public carregandoRelatorio: boolean;
    public multiplosRecibos: boolean = false;
    public estabelecimentos: Array<Estabelecimento>;
    public empresas: Array<Empresa>;
    public listagemPermissao: Array<string> = ['relatorios_contracheques'];
    public permissaoGrupoEmpresarial = false;

    constructor(
        public $rootScope: angular.IRootScopeService & {
            nsjGlobals: any,
            modoGestorPermissao: (arg: string) => boolean,
            temPermissaoGrupoEmpresarial: (permissoes: string[], grupo: string) => boolean,
            session: any
        },
        public $http: angular.IHttpService,
        public $uibModal: ng.ui.bootstrap.IModalService,
        public NewToaster: { pop: Function },
        public Tiposcalculos: Tiposcalculos,
        public MeurhRecibospagamentos: MeurhRecibospagamentosService,
        public ModalConfirmService: ModalConfirmService,
        public ListagemService: ListagemService,
        public RelatoriosService: any,
        public ClientSession: any,
        public nsjRouting: any,
    ) {
        this.tiposcalculos = this.Tiposcalculos.load();
        this.filters = {
            ...this.filters,
            empresa: $rootScope.session.entities.empresa.empresa,
            estabelecimento: $rootScope.session.entities.estabelecimento.estabelecimento,
        };

        this.permissaoGrupoEmpresarial = $rootScope.temPermissaoGrupoEmpresarial(
            this.listagemPermissao,
            $rootScope.nsjGlobals.a.grupoempresarial
        );

        $rootScope.$on('Trabalhadores_loading', () => {
            if (!this.primeiroLookupTrabalhadoresCarregado) {
                this.lookupTrabalhadores = true;
                this.primeiroLookupTrabalhadoresCarregado = true;
            }
        });

        $rootScope.$on('Trabalhadores_loaded', () => {
            this.lookupTrabalhadores = false;
        });

        $rootScope.$on('meurh_recibospagamentos_list_finished', () => {
            this.checkboxPreenchidos = [];
        });

        this.montaListagemVisibilidade();

        ClientSession.load();
    }

    $onInit() {
        this.carregaClientSession();
    }

    carregaClientSession() {
        this.$rootScope.$on('meurh_clientsession_session_loaded_service', (event, args) => {
            if (!angular.isUndefined(args.empresa)) {
                this.parametrosSessao = args;
                this.filter();
            }
        });
    }

    loadMore() {
        this.MeurhRecibospagamentos.loadMore();
    }

    isBusy() {
        return this.MeurhRecibospagamentos.loadParams.busy;
    }

    finished() {
        return this.MeurhRecibospagamentos.loadParams.finished;
    }

    search() {
        this.MeurhRecibospagamentos.filter = this.filters.tipo;
        this.contracheques = this.MeurhRecibospagamentos.reload();
    }

    montaListagemVisibilidade() {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa!, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos() {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
        delete this.filters.trabalhador;
        delete this.filters.lotacao;
        this.$rootScope.$broadcast('trabalhador_refresh', this.constructorTrabalhadores());
        this.$rootScope.$broadcast('lotacao_refresh', this.constructorEmpresaEstabelecimento());
        this.filter();
    }

    filter() {
        if (this.parametrosSessao != null) {
            var checktoggle: any = document.getElementById('check_all');
            checktoggle.checked = false;

            this.MeurhRecibospagamentos.constructors = this.setConstructor();
            this.contracheques = this.MeurhRecibospagamentos.reload();
            this.checkboxPreenchidos = [];
        }
    }

    setConstructor() {
        return {
            ...this.filters,
            ...(this.filters.trabalhador && { trabalhador: this.filters.trabalhador.trabalhador }),
            ...(this.filters.lotacao && { lotacao: this.filters.lotacao.lotacao }),
        };
    }

    checar_todos() {
        let checktoggle: any = document.getElementById('check_all');
        let elementos: any = document.getElementsByClassName('elementos_check');

        if (checktoggle.checked) {
            this.checkboxPreenchidos = angular.copy(this.contracheques);
            for (let i = 0; i < elementos.length; i++) {
                elementos[i].checked = true;
            }
        } else {
            this.checkboxPreenchidos = [];
            for (let i = 0; i < elementos.length; i++) {
                elementos[i].checked = false;
            }
        }
    }


    desmarcar_todos() {
        let checktoggle: any = document.getElementById('check_all');
        let elementos: any = document.getElementsByClassName('elementos_check');
        this.checkboxPreenchidos = [];
        checktoggle.checked = false;
        for (let i = 0; i < elementos.length; i++) {
            elementos[i].checked = false;
        }
        for (let i = 0; i < this.contracheques.length; i++) {
            this.contracheques[i].check = false;
        }
    }

    checar_elemento(entity: any) {
        let removeIndex: any = this.checkboxPreenchidos.map((item) => { return item.recibopagamento; }).indexOf(entity.recibopagamento);
        let checktoggle: any = document.getElementById('check_all');
        let elementos: any = document.getElementsByClassName('elementos_check');
        let temElementoChecked: any = false;
        let elementosChecked: any = [];

        if (removeIndex > -1) {
            this.checkboxPreenchidos.splice(removeIndex, 1);
        } else {
            this.checkboxPreenchidos.push(entity);
        }
        elementos.forEach(elemento => {
            if (elemento.checked === true) {
                temElementoChecked = true;
                elementosChecked.push(elemento);
            }
        });
        if (elementos.length === elementosChecked.length && temElementoChecked === true) {
            checktoggle.checked = true;
        } else {
            checktoggle.checked = false;
        }
    }

    constructorTrabalhadores() {
        return {
            ...this.constructorEmpresaEstabelecimento(),
            'forcagestor': this.$rootScope.modoGestorPermissao('relatorios_contracheques')
        };
    }

    constructorEmpresaEstabelecimento() {
        return {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento
        };
    }

    delete() {
        if (this.checkboxPreenchidos.length < 1) {
            this.NewToaster.pop({
                type: 'error',
                title: 'É necessário selecionar pelo menos um contracheque'
            });
        } else {
            let itens = this.checkboxPreenchidos.map((entity: any) => {
                let item = { 'recibopagamento': entity.recibopagamento, 'idtrabalhador': entity.trabalhador.trabalhador };
                return item;
            });
            let customText = {
                title: 'Excluir',
                confirmText: 'Você tem certeza que deseja excluir o(s) contracheque(s) selecionado(s)?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, excluir',
            };
            let confirm = this.ModalConfirmService.open(null, 'contracheque', false, customText);
            confirm.result.then( () => {
                itens.forEach((item: any) => {
                    let constructor = {
                        'id': item.recibopagamento,
                        'empresa': this.filters.empresa,
                        'estabelecimento': this.filters.estabelecimento
                    };
                    this.$http.delete(
                        this.nsjRouting.generate('meurh_recibospagamentos_delete', constructor, true),
                    )
                    .then((response: any): void => {
                        if (this.checkboxPreenchidos.length > 1) {
                            this.multiplosRecibos = true;
                        }
                        this.NewToaster.pop({
                            type: 'success',
                            title: (this.multiplosRecibos ? 'Os contracheques foram excluídos' : 'O contracheque foi excluído') + ' com sucesso!'
                        });
                        this.filter();
                    })
                    .catch((response: any): void => {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Ocorreu um erro ao excluir o(s) contracheque(s)!'
                        });
                    });
                });
            }).catch(() => {/* */});
        }
    }

    download_lote() {
        let itens = this.checkboxPreenchidos.map((entity: any) => {
            let item = { 'recibopagamento': entity.recibopagamento, 'idtrabalhador': entity.trabalhador.trabalhador };
            return item;
        });

        this.isDownloading = true;
        let constructor = {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento
        };

        this.$http({
            method: 'post',
            url: this.nsjRouting.generate('meurh_recibospagamentos_downloadinformeslote', constructor, true),
            data: { 'entities': itens },
            responseType: 'blob'
        }).then((response: any) => {
            var nomeArquivo = '';
            var blob: any = response.data;
            var fileURL = (window.URL || window.webkitURL).createObjectURL(blob);
            var a = document.createElement('a');
            nomeArquivo = 'contracheques_lote';
            nomeArquivo += '.zip';

            document.body.appendChild(a);
            a.style.display = 'none';
            a.href = fileURL;
            a.download = nomeArquivo;
            a.click();
            this.desmarcar_todos();
            window.URL.revokeObjectURL(fileURL);
            this.isDownloading = false;
        });
    }

    abrirContracheque(contracheque: any) {
        this.carregandoRelatorio = true;
        let constructor = {
            'id': contracheque.recibopagamento,
            'idtrabalhador': contracheque.trabalhador.trabalhador,
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento,
            'offset': 0,
        };
        let callback = () => {
            this.carregandoRelatorio = false;
        };

        this.busy = true;

        this.$http.get(
            this.nsjRouting.generate('meurh_recibospagamentos_get_pdf', constructor, true), { responseType: 'blob' })
            .then((response) => {
                let data: any = response.data;
                let file = new Blob([data], { type: 'application/pdf' });
                let fileURL = URL.createObjectURL(file);

                let uibModalInst = this.$uibModal.open({
                    animation: true,
                    keyboard: true,
                    backdrop: true,
                    template:
                        `<a id="closeModal" class="pull-right" style="margin-top: -30px;color: lightgray;cursor: pointer;" ng-click="ctrlModal.close()">
                        <i class="fas fa-times"></i>
                    </a>` +
                        '<embed src="' +
                        fileURL +
                        '" width="100%" height="100%" ng-init="ctrlModal.setStyle()"/>',

                    controller: function(): void {
                        let selfModal: any = this;

                        selfModal.setStyle = function(): void {
                            let modalDialog: any = document.getElementsByClassName('modal-dialog')[0];
                            modalDialog.style.width = '90%';
                            modalDialog.style.height = '90%';

                            let modalContent: any = document.getElementsByClassName('modal-content')[0];
                            modalContent.style.padding = '40px 20px 20px 20px';
                            modalContent.style.height = '100%';
                        };

                        selfModal.close = function() {
                            uibModalInst.close(false);
                        };
                    },
                    controllerAs: 'ctrlModal'
                });

                uibModalInst.result.then(() => {/* */}).catch(() => {/* */});
            })
            .catch((error: any) => {
                if (error.status === 404) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'O arquivo não foi encontrado'
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error.data.message
                    });
                }
            }).finally(() => {
                if (typeof callback === 'function') {
                    callback();
                }
                this.busy = false;
            });
    }
}
