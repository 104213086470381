import angular from 'angular';
import moment from 'moment';
import { ITrabalhador } from '../models/trabalhador.model';
import { TrabalhadoresService } from '../trabalhadores.service';
import { ListagemService } from '../../../shared/services/listagem.service';
import { Empresa, Estabelecimento, Usuario } from '../../../core/usuario/models/usuario-model';

type ITrabalhadoresListFilter = {
    empresa?: string | null;
    estabelecimento?: string | null;
    status?: string | null;
    tipo?: string | null;
    lotacao?: {
        lotacao: string;
    } | null;
    trabalhador?: {
        trabalhador: string;
    } | null;
};

export class TrabalhadoresListController implements angular.IController {
    static $inject = ['$rootScope', '$scope', '$state', '$http', '$stateParams', '$location', '$transitions', 'nsjRouting', 'NewToaster', 'TrabalhadoresService', 'RelatoriosService', '$filter', '$injector', 'BuscaAvancadaService', 'ListagemService'];

    public entity: Partial<ITrabalhador>;
    public entities: Array<ITrabalhador> = [];
    public busy: boolean = false;
    public carregandoRelatorio: boolean = false;
    public entitiesSelecionadas: Array<ITrabalhador> = [];
    public trabalhadorinput: string = '';
    public entitiesTrabalhadores: Array<ITrabalhador>;
    public entitySearchList: any = [];
    public dataFinal: string | moment.Moment = moment().endOf('month').format('YYYY-MM-DD');
    public dataInicial: string | Date = new Date();
    public primeiroLookupTrabalhadoresCarregado: boolean = false;
    public constructors: any = [];
    public options = { 'trabalhador': 'trabalhador' };
    public maxoccurs: object = {};
    public selects = {};
    public checkboxTodos: boolean = false;
    public checkboxNenhum: boolean = true;
    public filter: any = {};
    public filters: ITrabalhadoresListFilter = {};
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['visualizar_funcionarios', 'relatorios_fichascadastrais'];
    public permissaoGrupoEmpresarial = false;
    public lookupTrabalhadores: boolean = false;
    public checkAll: boolean;
    public fields: any = [
        {
            value: 'status',
            label: 'Situação',
            type: 'string',
            style: 'label',
            copy: '',
            label_class: '{ "label-warning": entity.situacao === 0, "label-success": entity.situacao === 1, "label-danger": entity.situacao === 2 }',
        },

        {
            value: 'codigo',
            label: 'Código',
            type: 'string',
            style: 'title',
            copy: '',
        },

        {
            value: 'nome',
            label: 'Nome',
            type: 'string',
            style: 'default',
            copy: '',
        },

        {
            value: 'cpf',
            label: 'CPF',
            type: 'string',
            style: 'default',
            copy: '',
        },

        {
            value: 'dataadmissao',
            label: 'Data admissão',
            type: 'date',
            style: 'default',
            copy: '',
        },

        {
            value: 'datarescisao',
            label: 'Data rescisão',
            type: 'date',
            style: 'default',
            copy: '',
        },

        {
            value: 'datavencimentoatestadomedico',
            label: 'datavencimentoatestadomedico',
            type: 'datetime',
            style: 'default',
            copy: '',
        },

        {
            value: 'categoriatrabalhador',
            label: 'CategoriaTrabalhador',
            type: 'string',
            style: 'default',
            copy: '',
        },

    ];

    constructor(
        public $rootScope: angular.IRootScopeService & {
            temPermissao: (arg: string) => boolean,
            modoGestorPermissao: (arg: string) => boolean,
            temPermissaoGrupoEmpresarial: (permissoes: string[], grupo: string) => boolean,
            session: any,
            nsjGlobals: any,
            usuario: Usuario
        },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $http: angular.IHttpService,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
        public $transitions: any,
        public nsjRouting: any,
        public NewToaster: any,
        public entityService: TrabalhadoresService,
        public RelatoriosService: any,
        public $filter: any,
        public $injector: any,
        public BuscaAvancadaService: any,
        public ListagemService: ListagemService,
    ) {
        this.entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
        this.filters = this.entityService.filters = {};
        this.filter.search = $stateParams['q'];

        this.permissaoGrupoEmpresarial = $rootScope.temPermissaoGrupoEmpresarial(
            this.listagemPermissao,
            $rootScope.nsjGlobals.a.grupoempresarial
        );

        this.montaListagemVisibilidade();

        if ($stateParams['tipo'] !== null && $stateParams['tipo'] !== '' && $stateParams['tipo'] !== undefined) {
            this.entityService.constructors['tipo'] = $stateParams['tipo'];
        } else {
            this.entityService.constructors['tipo'] = '-1';
        }

        if (this.entityService.filters.trabalhador !== null || this.entityService.filters.trabalhador !== '') {
            delete this.entityService.filters.trabalhador;
        }

        if (!this.filters.tipo) {
            this.filters.tipo = '-1';
        }

        if (!this.filters.status) {
            this.filters.status = '00';
        }

        if (!this.$rootScope.temPermissao('visualizar_funcionarios') || !this.$rootScope.temPermissao('relatorios_fichascadastrais')) {
            $state.go('dp', $stateParams);
        }

        this.$rootScope.$on('persona_trabalhadores_list_finished', (event: angular.IAngularEvent, args: any): void => {
            this.entitySearchList = [...args];
            for (let i = 0; i < this.entitySearchList.length; i++) {
                this.entitySearchList[i]['tipoFiltrado'] = $filter('lookupTipoTrabalhador')(this.entitySearchList[i]['tipo']);
            }
        });

        for (let i in $stateParams) {
            if (['trabalhador', ].indexOf(i) > -1 && $stateParams[i] !== undefined) {
                this.entityService.filters[i] = $stateParams[i];
            } else if (typeof $stateParams[i] !== 'undefined' && typeof $stateParams[i] !== 'function' && i !== 'q' && i !== 'trabalhador') {
                this.entityService.constructors[i] = $stateParams[i];
            }
        }

        this.$rootScope.$on('Trabalhadores_loading', (event: angular.IAngularEvent, args): void => {
            if (!this.primeiroLookupTrabalhadoresCarregado) {
                this.lookupTrabalhadores = true;
                this.primeiroLookupTrabalhadoresCarregado = true;
            }
        });

        $rootScope.$on('Trabalhadores_loaded', (event: angular.IAngularEvent, args: any): void => {
            this.lookupTrabalhadores = false;
        });

        $scope.$on('trabalhadores_deleted', (event: angular.IAngularEvent): void => {
            this.entityService.reload();
        });

        $scope.$on('$destroy', (): void => {
            if (this.entityService.loading_deferred) {
                this.entityService.loading_deferred.resolve();
            }
            this.entityService.constructors = {};
        });

        $rootScope.$on('trabalhadores_submitted', (event: angular.IAngularEvent, args: any): void => {
            if (!args.autosave) {
                this.entityService.reload();
            }
        });
    }

    $onInit() {
        this.entity = {};
    }

    montaListagemVisibilidade() {
        this.filters.empresa = this.$rootScope.session.entities.empresa.empresa;
        this.filters.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa!, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos() {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
        this.limpaNsjSearch('trabalhador');
        this.resetFiltroLotacao();
        this.entityService.constructors['empresa'] = this.filters.empresa;
        this.entityService.constructors['estabelecimento'] = this.filters.estabelecimento;
        this.entities = this.entityService.search();
        this.$rootScope.$broadcast('lotacao_refresh', this.constructorLotacao());
    }

    carregandoLookups(): boolean {
        return this.lookupTrabalhadores;
    }

    buscaAvancada(): void {
        this.entityService.constructors.tipo = this.filters.tipo;
        delete this.entityService.constructors.trabalhador;

        let columns = ['Nome', 'Tipo'];
        let values = ['nome', 'tipo'];
        let enumValues: any = [];
        enumValues['nome'] = '';
        enumValues['tipo'] = 'lookupTipoTrabalhador';
        this.entityService.constructors['status'] = '00';
        let modal = this.BuscaAvancadaService.open(this, this.filters, this.entityService, columns, values, 'Colaboradores', enumValues, 'trabalhador', true, false, true, () => this.filtraTrabalhador());
        modal.result.then(() => {/* */}).catch(() => {/* */});
    }

    evento(event: angular.IAngularEvent & { detail: any }): void {
        this.constructors = {
            'estabelecimento': this.filters.estabelecimento,
            'tipo': this.filters.tipo
        };

        this.filter.search = event.detail.value;
        this.entityService.constructor = this.constructors;

        this.entityService.search(this.filter);
        this.entityService.filter = event.detail;
    }

    comboBusy(): boolean {
        return this.entityService.loadParams.busy;
    }

    limpaNsjSearch(id: string): void {
        document.querySelectorAll(`#${id} .componentPosition .customIcon`)[0]?.dispatchEvent(new Event('click'));
        document.querySelectorAll(`#${id} .componentPosition .customIconMobile`)[0]?.dispatchEvent(new Event('click'));
    }

    retornaListagemCombo() {
        return this.entitySearchList;
    }

    search(): Array<ITrabalhador> {
        if (this.filter.search !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.filter.search, }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, this.entityService.constructors));
        }

        return this.entityService.search(this.filter);
    }

    loadMore(): void {
        this.entityService.loadMore();
    }

    generateRoute(route: string, params: any): string {
        return this.nsjRouting.generate(route, params, true);
    }

    finished(): boolean {
        return this.entityService.loadParams.finished;
    }

    isBusy(): boolean {
        return this.entityService.loadParams.busy;
    }

    searchRelatorios(): void {
        this.entityService.constructors = this.constructors;
        this.entities = this.entityService.search(this.filter);
    }

    reload(): void {
        this.entityService.constructors['empresa'] = this.filters.empresa;
        this.entityService.constructors['estabelecimento'] = this.filters.estabelecimento;
        this.entities = this.entityService.reload();
    }

    semGeridos(): boolean {
        if (this.$rootScope.modoGestorPermissao('visualizar_funcionarios')) {
            let semGeridos = this.$rootScope.nsjGlobals.get('semGeridos');
            let semGeridosAtivos = this.$rootScope.nsjGlobals.get('semGeridosAtivos');

            return semGeridos || semGeridosAtivos;
        }

        return false;
    }

    visualizarFichaCadastral(trabalhador: string | null = null): void {
        this.carregandoRelatorio = true;
        let ids: any = [];

        if (trabalhador) {
            ids.push(trabalhador);
        } else {
            this.entities.forEach((entity): void => {
                if (entity.selecionado) {
                    ids.push(entity.trabalhador);
                }
            });
        }

        if (ids.length > 0) {
            let constructor = { 'trabalhadores': ids, 'id': 'null' };
            let callback = (): void => {
                this.carregandoRelatorio = false;
            };

            this.RelatoriosService.abrirRelatorioFop('persona_trabalhadores_ficha', constructor, callback, false);
        }
    }

    visualizarFichaCadastralIndividual(id: string): void {
        this.carregandoRelatorio = true;
        let ids: any = id !== null ? [id] : [];

        if (ids.length > 0) {
            let constructor = { 'trabalhadores': ids, 'id': 'null' };
            let callback = (): void => {
                this.carregandoRelatorio = false;
            };

            this.RelatoriosService.abrirRelatorioFop('persona_trabalhadores_ficha', constructor, callback, false);
        }
    }

    baixarFichaCadastral(): void {
        this.carregandoRelatorio = true;
        let ids: any = [];

        angular.forEach(this.entities, (entity): void => {
            if (entity.selecionado) {
                ids.push(entity.trabalhador);
            }
        });

        if (ids.length > 0) {
            let constructor = {
                'trabalhadores': ids,
                'id': 'null',
                'empresa': this.filters.empresa,
                'estabelecimento': this.filters.estabelecimento
            };
            let callback = (): void => {
                this.carregandoRelatorio = false;
            };
            this.RelatoriosService.baixarRelatorioFop('persona_trabalhadores_ficha', constructor, callback, false);
        }
    }

    checkboxEntidades(acao: any = null): void {
        this.entities.forEach((entity): void => {
            if (acao === null) {
                entity.selecionado = this.checkboxTodos ? true : false;
            } else {
                entity.selecionado = acao;
            }
        });

        if (acao !== null) {
            this.checkboxTodos = acao ? true : false;
        }
        this.checkboxNenhum = this.checkboxTodos ? false : true;
    }

    renovaCheckbox(): void {
        this.checkboxNenhum = true;
        this.checkboxTodos = false;
    }

    quantidadeFuncionariosSelecionados(): number {
        let qtd = 0;

        this.entities.forEach((entity): void => {
            if (entity.selecionado) {
                qtd++;
            }
        });

        return qtd;
    }

    todosFuncionariosSelecionados(): void {
        let selecionado = true;

        if (this.entities.length === 0) {
            selecionado = false;
        }

        this.entities.forEach((entity): void => {
            if (!entity.selecionado) {
                selecionado = false;
            }
        });

        this.checkboxTodos = selecionado;
    }

    nenhumFuncionarioSelecionado(): void {
        let nenhumSelecionado = true;

        this.entities.forEach((entity): void => {
            if (entity.selecionado) {
                nenhumSelecionado = false;
            }
        });

        this.checkboxNenhum = nenhumSelecionado;
    }

    resetFiltroTrabalhador() {
        this.filters.trabalhador = null;
        delete this.entityService.constructors['trabalhador'];

        this.entityService.filter = '';
        this.entities = this.entityService.reload();
    }

    resetFiltroLotacao() {
        this.filters.lotacao = null;
        delete this.entityService.constructors['lotacao'];
    }

    filtraTrabalhador(eventoTrabalhador: { detail: {value: ITrabalhador} } | null = null) {
        if (eventoTrabalhador) {
            this.filters.trabalhador = eventoTrabalhador.detail.value;
        }

        if (this.filters.trabalhador?.trabalhador) {
            this.entityService.constructors['trabalhador'] = this.filters.trabalhador.trabalhador;
            this.entities = this.entityService.reload();
        } else {
            delete this.entityService.constructors['trabalhador'];
            this.entities = this.entityService.reload();
        }
    }

    filtraStatus() {
        if (this.filters.status && this.filters.status !== '') {
            this.entityService.constructors['status'] = this.filters.status;
            this.entities = this.entityService.reload();
        } else {
            delete this.entityService.constructors['status'];
            this.entities = this.entityService.reload();
        }
    }

    filtraTipo() {
        if (this.filters.tipo && this.filters.tipo !== '') {
            this.entityService.constructors['tipo'] = this.filters.tipo;
            this.entities = this.entityService.reload();
        } else {
            delete this.entityService.constructors['tipo'];
            this.entities = this.entityService.reload();
        }
    }

    filtraLotacao() {
        if (this.filters.lotacao?.lotacao) {
            this.entityService.constructors['lotacao'] = this.filters.lotacao.lotacao;
            this.entities = this.entityService.reload();
        } else {
            delete this.entityService.constructors['lotacao'];
            this.entities = this.entityService.reload();
        }
    }

    constructorLotacao() {
        return {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento
        };
    }

    relatorioPonto(action: any): void {
        this.carregandoRelatorio = true;
        let ids: any = [];

        this.entitiesSelecionadas.forEach((entity): void => {
            ids.push(entity.trabalhador);
        });

        if (ids.length > 0 && this.dataInicial !== null  && this.dataFinal !== null  && this.dataInicial !== ''  && this.dataFinal !== '') {
            if (this.dataInicial < this.dataFinal) {
                let constructor = {
                    'trabalhadores': ids,
                    'dataInicial': this.dataInicial,
                    'dataFinal': this.dataFinal,
                    'empresa': this.filters.empresa,
                    'estabelecimento': this.filters.estabelecimento
                };
                let callback = (): void => {
                    this.carregandoRelatorio = false;
                };
                action === 'view' ? this.RelatoriosService.abrirRelatorioFop('persona_trabalhadores_ponto', constructor, callback, false) : this.RelatoriosService.baixarRelatorioFop('persona_trabalhadores_ponto', constructor, callback, false);
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'A data inicial do ponto não pode ser igual ou posterior a data final do ponto'
                });

                this.carregandoRelatorio = false;
            }
        } else {
            if (ids.length <= 0) {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Pelo menos um trabalhador precisa ser selecionado'
                });
            } else if (this.dataInicial !== null  || this.dataFinal !== null  || this.dataInicial !== ''  || this.dataFinal !== '') {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'A data inicial/final do ponto não pode ser vazia'
                });
            }

            this.carregandoRelatorio = false;
        }
    }

    selecionaTrabalhador() {
        if (this.filters.trabalhador?.trabalhador) {
            let naoSelecionado = !this.entitiesSelecionadas.some((trabalhador: any) => trabalhador.trabalhador === this.filters.trabalhador?.trabalhador);

            if (naoSelecionado) {
                this.entitiesSelecionadas = [];
                this.entitiesSelecionadas.push(this.filters.trabalhador as ITrabalhador);
            }
        }

        let index = this.entities.findIndex(
            trabalhador => trabalhador.trabalhador === this.filters.trabalhador?.trabalhador
        );

        if (index > -1) {
            this.entities[index].check = true;
        }

        delete this.filters.trabalhador;
    }

    adicionarRemoverListaSelecionados(entity: any): void {
        entity.forEach( entity => {
            if (entity.selecionado) {
                let naoSelecionado = !this.entitiesSelecionadas.some(
                    trabalhador => trabalhador.trabalhador === entity.trabalhador
                );

                if (naoSelecionado) {
                    this.entitiesSelecionadas.push(entity);
                }
            } else {
                let index = this.entitiesSelecionadas.findIndex(
                    trabalhador => trabalhador.trabalhador === entity.trabalhador
                );

                if (index > -1) {
                    this.entitiesSelecionadas.splice(index, 1);
                    this.checkAll = false;
                }
            }
        });
    }

    removerSelecionado(k: any): void {
        let guid = this.entitiesSelecionadas[k].trabalhador;
        this.entitiesSelecionadas[k].selecionado = false;
        this.checkboxTodos = false;

        this.entitiesSelecionadas.splice(k, 1);

        let index = this.entities.findIndex(
            trabalhador => trabalhador.trabalhador === guid
        );

        if (index > -1) {
            this.entities[index].check = false;
            this.checkAll =  false;
        }
    }

    exportarPdfTodosTrabalhadores(empresa: string, estabelecimento: string, tipoTrabalhador: string, status: string) {

        if (this.dataInicial === null || this.dataFinal === null || this.dataInicial === '' || this.dataFinal === '') {
            this.NewToaster.pop({
                type: 'error',
                title: 'A data inicial/final do ponto não pode ser vazia'
            });
        } else {

            this.carregandoRelatorio = true;

            let callback = (): void => {
                this.carregandoRelatorio = false;
            };

            let constructor = {
                'dataInicial': this.dataInicial,
                'dataFinal': this.dataFinal,
                empresa,
                estabelecimento,
                tipo: tipoTrabalhador,
                status,
                todos: true
            };

            this.RelatoriosService.baixarRelatorioFop(
                'persona_trabalhadores_ponto',
                constructor,
                callback,
                false
            );
        }

    }

}
