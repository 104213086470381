import angular from 'angular';
import { IPlanosModal } from './planos.modal.service';
import { TipoBeneficios } from '../../beneficios.enum';

export class PlanosModalController {
  static $inject = ['$rootScope', '$uibModalInstance', 'entity'];

  public form: angular.IFormController;
  public tiposDeBeneficiosConst = TipoBeneficios;

  constructor(
    public $rootScope: angular.IRootScopeService & { session: any },
    public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    public entity: IPlanosModal
  ) {}

  submit() {
    this.form.$submitted = true;
    if (this.form.$invalid) {
        return;
    }
    this.$uibModalInstance.close(this.entity.beneficio);
  }

  close() {
    this.$uibModalInstance.dismiss('fechar');
  }
}
