import ng from 'angular';
import angular from 'angular';
import { ISolicitacoesGenericasList } from '../models/solicitacaogenerica.model';
import { MeurhSolicitacoesgenericasService } from '../solicitacoesgenericas.service';
import { MeurhTipossolicitacoesgenericasService } from '../tipossolicitacoesgenericas.service';

type IMeurhSolicitacoesgenericasIndexFilter = {
    empresa?: string;
    estabelecimento?: string;
    tiposolicitacaogenerica?: string;
    'wkf.estado'?: {
        codigo: string;
    };
    lotacao?: {
        lotacao: string;
    } | null;
};

export class MeurhSolicitacoesgenericasIndexController implements ng.IController {
	static $inject = [
		'$rootScope',
		'$scope',
		'$state',
		'$stateParams',
		'NewToaster',
		'MeurhSolicitacoesgenericasService',
		'MeurhTipossolicitacoesgenericasService',
		'PermissaoService',
	];

	public entities: Array<ISolicitacoesGenericasList>;
	public busy: boolean = false;
	public filters: IMeurhSolicitacoesgenericasIndexFilter = {};
	public listagemPermissao: Array<string> = ['visualizar_solicitacaogenerica'];
	public estadosWorkflow = [];
	public listaTipos: Array<{ tiposolicitacaogenerica: string, codigo: string, nome: string }>;

	public fields = [
		{
			value: 'tiposolicitacaogenerica.nome',
			label: 'Tipo',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'solicitacao',
			label: 'Competência',
			type: 'string',
			style: 'title',
			copy: '',
			customCollumnScope: {
                getCompetencia: (entity: any) => {

					var nomeMes = [
						'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
						'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
					];

					if (entity.mes && entity.ano) {
						return `${nomeMes[entity.mes - 1]}/${entity.ano}`;
					} else {
						return '-';
					}
                },
            },
            customCollumnHtml: () => {
                let someHtmlVar = `<span ng-bind="customCollumnScope.getCompetencia(tableRowEntity)"></span>`;
                return someHtmlVar;
            },
		},
		{
			value: 'created_by.nome',
			label: 'Criado por',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'previsao',
			label: 'Previsão de entrega',
			type: 'date',
			style: 'title',
			copy: '',
			customCollumnHtml: () => {
                let someHtmlVar = `
					<span ng-if="tableRowEntity.previsao">{{tableRowEntity.previsao | asDate | date:'dd/MM/yyyy' }}</span>
					<span ng-if="!tableRowEntity.previsao">-</span>
				`;
                return someHtmlVar;
            },
		},
		{
			value: 'wkf_estado',
			label: 'Situação',
			type: 'string',
			style: 'title',
			copy: '',
			customCollumnHtml: (): string => {

				let someHtmlVar = `
					<span class="label label-default margin-left-5"
						ng-class='{
							"label-info": tableRowEntity.wkf_estadocodigo == "EM_EXECUCAO",
							"label-warning": tableRowEntity.wkf_estadocodigo == "ENVIADA",
							"label-success": tableRowEntity.wkf_estadocodigo == "Efetuada",
							"label-danger": (
								tableRowEntity.wkf_estadocodigo == "Cancelada" ||
								tableRowEntity.wkf_estadocodigo == "RECUSADA"
							)
						}'>

						<span ng-if='tableRowEntity.wkf_estado' ng-bind="tableRowEntity.wkf_estado"></span>
						<span ng-if='!tableRowEntity.wkf_estado'>Não informado</span>

					</span>
				`;

				return someHtmlVar;
			},
		},
	];

	constructor(
		public $rootScope: angular.IScope & { session: any, },
		public $scope: angular.IScope,
		public $state: angular.ui.IStateService,
		public $stateParams: angular.ui.IStateParamsService,
		public NewToaster: { pop: Function },
		public entityService: MeurhSolicitacoesgenericasService,
		public serviceTipo: MeurhTipossolicitacoesgenericasService,
		public PermissaoService: any,
	) {
		if (!PermissaoService.temPermissao(this.listagemPermissao)) {
			$state.go('dp', $stateParams);
		}

		entityService.filter = '';
		entityService.filters = {};
		entityService.constructors = {};

		this.filters = {
            ...this.filters,
			estabelecimento: $rootScope.session.entities.estabelecimento.estabelecimento,
		};

		$scope.$on('$destroy', () => {
			if (this.entityService.loading_deferred) {
				this.entityService.loading_deferred.resolve();
			}
		});
	}

	async $onInit(): Promise<void> {

		// preenchendo o select de tipos
		await this.serviceTipo._load({}, {}, '')
        .then((tiposRetornados: any) => {
            this.listaTipos = tiposRetornados;
        })
        .catch((error) => {
            this.NewToaster.pop({
                type: 'error',
                title: 'Não foi possível buscar os tipos de solicitação extraordinária',
                body: error.data?.message ?? error,
            });
        })
        .finally(() => this.busy = false);

		await this.buscarEstadosWorkflowSolGenericas();
		this.reload();
	}

	reload(): void {
		this.entityService.constructors = this.montaConstrutor();
		this.entities = this.entityService.reload();
	}

	finished(): boolean {
		return this.entityService.loadParams.finished;
	}

	montaConstrutor(): object {
		const construtor: any = angular.copy(this.filters);

        if (!this.valorExiste(construtor.lotacao)) {
            delete construtor.lotacao;
        } else {
            construtor.lotacao = construtor.lotacao.lotacao;
        }

		if (!this.valorExiste(construtor.tiposolicitacaogenerica)) {
			delete construtor.tiposolicitacaogenerica;
		}

		if (!this.valorExiste(construtor['wkf.estado'])) {
			delete construtor['wkf.estado'];
		} else {
            construtor['wkf.estado'] = construtor['wkf.estado'].codigo;
        }

		return construtor;
	}

	isBusy(): boolean {
		return this.entityService.loadParams.busy;
	}

	loadMore(): void {
		this.entityService.loadMore();
	}

	goToShow(entity: any): void {

		let rota = 'meurh_solicitacoesgenericas_get';

		this.busy = true;
		this.$state.go(rota,
			angular.extend({}, {
				'solicitacao': entity.solicitacao,
			})
		);

	}

	valorExiste(valor: any): boolean {
		return valor !== '' && valor !== undefined && valor !== null ? true : false;
	}

	goToCreate () {
		this.$state.go('meurh_solicitacoesgenericas_new', this.entityService.constructors);
    }

	async buscarEstadosWorkflowSolGenericas() {
        await this.entityService.getEstadosWorkflow()
        .then((estados: any) => {
            this.estadosWorkflow = estados;
        })
        .catch((error) => {
            this.NewToaster.pop({
                type: 'error',
                title: 'Não foi possível buscar os estados de workflow de solicitação extraordinária',
                body: error,
            });
        });
    }

}
