import angular from 'angular';
import moment from 'moment';
import { AtestadosAVencerService } from '../atestadosavencer.service';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

type IAtestadosAVencerIndexFilter = {
    empresa?: string;
    estabelecimento?: string;
    situacao?: string;
    data?: string;
    tipo?: string;
    trabalhador?: {
        trabalhador: string;
    } | null;
    departamento?: {
        departamento: string;
    } | null;
    lotacao?: {
        lotacao: string;
    } | null;
};

export class AtestadosAVencerIndexController {
    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        'NewToaster',
        'AtestadosAVencerService',
        'RelatoriosService',
        'ListagemService'
    ];

    public lookupTrabalhadores = false;
    public primeiroLookupTrabalhadoresCarregado = false;
    public busy: boolean;
    public trabalhadores: any;
    public carregandoRelatorio: boolean;
    public listagemPermissao: Array<string> = ['relatorios_atestadosavencer'];
    public permissaoGrupoEmpresarial = false;
    public estabelecimentos: Array<Estabelecimento>;
    public empresas: Array<Empresa>;
    public filters: IAtestadosAVencerIndexFilter = {
        situacao: '00',
        data: moment().format('YYYY-MM-DD'),
        tipo: '-1',
    };

    constructor(
        public $rootScope: angular.IRootScopeService & {
            temPermissao: (arg: string) => boolean,
            modoGestorPermissao: (arg: string) => boolean,
            temPermissaoGrupoEmpresarial: (permissoes: string[], grupo: string) => boolean,
            session: any,
            nsjGlobals: any,
        },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public NewToaster: { pop: Function },
        public AtestadosAVencerService: AtestadosAVencerService,
        public RelatoriosService: any,
        public ListagemService: ListagemService,
    ) {
        if (!$rootScope.temPermissao('relatorios_atestadosavencer')) {
            $state.go('dp', $stateParams);
        }

        this.permissaoGrupoEmpresarial = $rootScope.temPermissaoGrupoEmpresarial(
            this.listagemPermissao,
            $rootScope.nsjGlobals.a.grupoempresarial
        );

        this.filters = {
            ...this.filters,
            empresa: $rootScope.session.entities.empresa.empresa,
            estabelecimento: $rootScope.session.entities.estabelecimento.estabelecimento
        };

        this.montaListagemVisibilidade();

        $rootScope.$on('Trabalhadores_loading', () => {
            if (!this.primeiroLookupTrabalhadoresCarregado) {
                this.lookupTrabalhadores = true;
                this.primeiroLookupTrabalhadoresCarregado = true;
            }
        });

        $rootScope.$on('Trabalhadores_loaded', () => {
            this.lookupTrabalhadores = false;
        });
    }

    montaListagemVisibilidade() {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa!, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos() {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
        delete this.filters.trabalhador;
        delete this.filters.departamento;
        delete this.filters.lotacao;
        this.$rootScope.$broadcast('trabalhador_refresh', this.constructorColaboradores());
        this.$rootScope.$broadcast('departamento_refresh', this.constructorEmpresaEstabelecimento());
        this.$rootScope.$broadcast('lotacao_refresh', this.constructorEmpresaEstabelecimento());
        this.carregarRelatorio();
    }

    constructorColaboradores() {
        return {
            'status': '00',
            'tipo': this.filters.tipo,
            'tipo_excluir': 1,
            'forcagestor': this.$rootScope.modoGestorPermissao('relatorios_atestadosavencer'),
            ...this.constructorEmpresaEstabelecimento()
        };
    }

    constructorEmpresaEstabelecimento() {
        return {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento
        };
    }

    loadMore() {
        this.AtestadosAVencerService.loadMore();
    }

    carregarRelatorio() {
        const construtor = this.setConstructor();
        if (construtor.data != null && construtor.data !== '') {
            this.AtestadosAVencerService.constructors = construtor;
            this.busy = true;
            this.trabalhadores = this.AtestadosAVencerService.reload();
            this.busy = false;
        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'O campo data é obrigatório para a pesquisa'
            });
        }

    }

    setConstructor(relatorio: boolean = false) {
        return {
            ...this.filters,
            ...(this.filters.trabalhador && { trabalhador: this.filters.trabalhador.trabalhador }),
            ...(this.filters.departamento && { departamento: this.filters.departamento.departamento }),
            ...(this.filters.lotacao && { lotacao: this.filters.lotacao.lotacao }),
            relatorio,
        };
    }

    abrirRelatorio() {
        this.carregandoRelatorio = true;
        const construtor = this.setConstructor(true);
        let callback = () => {
            this.carregandoRelatorio = false;
        };
        this.RelatoriosService.abrirRelatorio('relatoriosliberados_atestadosavencer', construtor, callback);
    }

    isBusy() {
        return this.AtestadosAVencerService.loadParams.busy;
    }

    finished() {
        return this.AtestadosAVencerService.loadParams.finished;
    }
}
