import angular from 'angular';
import { MeurhBeneficiosIndexController } from './index/beneficios.index.controller';
import { MeurhBeneficiosNewController } from './new/beneficios.new.controller';
import { MeurhBeneficiosShowController } from './show/beneficios.show.controller';
import { MeurhBeneficiosComponent } from './beneficios.component';
import { MeurhBeneficiosHistoricoComponent } from './historico/beneficios.historico.component';
import { MeurhBeneficiosInclusaoFormComponent } from './form/inclusao/inclusao.form.component';
import { MeurhBeneficiosCancelamentoFormComponent } from './form/cancelamento/cancelamento.form.component';
import { MeurhBeneficiosService } from './beneficios.service';
import { MeurhBeneficiosListRouting } from './beneficios.routes';

import { PlanosModalController } from './modal/planos/planos.modal.controller';
import { PlanosModalService } from './modal/planos/planos.modal.service';

import { CancelamentoModalController } from './modal/cancelamento/cancelamento.modal.controller';
import { CancelamentoModalService } from './modal/cancelamento/cancelamento.modal.service';

export const MeurhBeneficiosModule = angular.module('MeurhBeneficiosModule', ['ui.router.state'])
    .controller('MeurhBeneficiosIndexController', MeurhBeneficiosIndexController)
    .controller('MeurhBeneficiosNewController', MeurhBeneficiosNewController)
    .controller('MeurhBeneficiosShowController', MeurhBeneficiosShowController)
    .controller('PlanosModalController', PlanosModalController)
    .controller('CancelamentoModalController', CancelamentoModalController)
    .component('meurhBeneficiosComponent', MeurhBeneficiosComponent)
    .component('meurhBeneficiosHistoricoComponent', MeurhBeneficiosHistoricoComponent)
    .component('meurhBeneficiosInclusaoFormComponent', MeurhBeneficiosInclusaoFormComponent)
    .component('meurhBeneficiosCancelamentoFormComponent', MeurhBeneficiosCancelamentoFormComponent)
    .service('MeurhBeneficiosService', MeurhBeneficiosService)
    .service('PlanosModalService', PlanosModalService)
    .service('CancelamentoModalService', CancelamentoModalService)
    .config(MeurhBeneficiosListRouting)
    .name;
