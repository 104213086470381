import angular from 'angular';
import moment from 'moment';
import { MeurhBeneficiosService } from '../beneficios.service';
import { IBeneficioHistorico, IBeneficioTrabalhadorDetalhado } from '../model/beneficios.model';
import { SituacaoBeneficioEnum } from '../beneficios.enum';

export class MeurhBeneficiosHistoricoController {

    static $inject = [
        '$scope',
        'MeurhBeneficiosService'
    ];

    public busy = false;
    public visible = false;
    public beneficioTrabalhador: IBeneficioTrabalhadorDetalhado;
    public historicoBeneficiosTrabalhador: {
        situacao: SituacaoBeneficioEnum,
        data: Date | string
    }[] = [];

    constructor(
        public $scope: angular.IScope,
        public MeurhBeneficiosService: MeurhBeneficiosService
    ) {
        $scope.$on('beneficios_historico_open', (event, args: { beneficioTrabalhador: IBeneficioTrabalhadorDetalhado }) => {
            this.beneficioTrabalhador = args.beneficioTrabalhador;
            this.visible = true;
            this.busy = true;
            this.MeurhBeneficiosService
                .getHistoricoBeneficiosTrabalhador(args.beneficioTrabalhador.beneficiotrabalhador)
                .then((response) => {
                    this.setTimeline(response);
                    this.busy = false;
                });
        });
    }

    close() {
        this.visible = false;
    }

    setTimeline(historico: IBeneficioHistorico[]) {
        this.historicoBeneficiosTrabalhador = [];
        historico.forEach((beneficioTrabalhador) => {
            this.historicoBeneficiosTrabalhador.push({
                    situacao: SituacaoBeneficioEnum.ATIVO,
                    data: beneficioTrabalhador.dataadesao
            });
            beneficioTrabalhador.suspensoes.forEach(suspensoes => {
                this.historicoBeneficiosTrabalhador.push({
                    situacao: SituacaoBeneficioEnum.SUSPENSO,
                    data: suspensoes.datainicio
                });
                if (suspensoes.datafim) {
                    this.historicoBeneficiosTrabalhador.push({
                        situacao: SituacaoBeneficioEnum.REATIVADO,
                        data: moment(suspensoes.datafim, 'YYYY-MM-DD').add(1, 'd').format('YYYY-MM-DD')
                    });
                }
            });
            if (beneficioTrabalhador.dataexclusao) {
                this.historicoBeneficiosTrabalhador.push({
                    situacao: SituacaoBeneficioEnum.CANCELADO,
                    data: beneficioTrabalhador.dataexclusao
                });
            }
        });
    }

    getNomeSituacao(situacao: SituacaoBeneficioEnum) {
        switch (situacao) {
            case 1:
                return 'Adesão';
            case 2:
                return 'Cancelado';
            case 3:
                return 'Suspensão';
            case 4:
                return 'Reativação';
            default:
                return '';
        }
    }

    getClasseCorSituacao(situacao: SituacaoBeneficioEnum) {
        switch (situacao) {
            case 1:
                return 'adesao';
            case 2:
                return 'cancelado';
            case 3:
                return 'suspenso';
            default:
                return '';
        }
    }
}
