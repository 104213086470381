import angular = require('angular');
import moment = require('moment');
import { ICardContent } from '../models/cardContent.model';
import { ICreditoDesconto } from '../models/creditoDesconto.model';
import { IDadosInit } from '../models/dadosInit.model';
import { IMensagensPassos } from '../models/mensagensPassos.model';

export class MeurhCreditosdescontosPassodoisController {

    static $inject = ['$rootScope', '$scope', '$stateParams', 'TrabalhadoresService', '$state', 'ModalConfirmService', '$q', '$transitions', 'NewToaster'];

    public busy: boolean = false;

    public entity: any; // tipagem: obj trabalhador
    public entityDadosCriacao: IDadosInit;
    public entitiesCreditosDescontos: Array<Partial<ICreditoDesconto>>;

    public passo: number = 2;
    public passoUmAtivo: string;
    public colaboradorSelecionado: any; // tipagem: obj trabalhador

    public trabalhador: string = this.$stateParams['trabalhador'];
    public mensagensPassos: IMensagensPassos;

    public cardContents: Array<ICardContent> = [];

    public allowRefresh: boolean = false;
    public competencia;
    public exibeCardAdiantamento: boolean = true;

    public dataLimiteFolha: string;
    public dataLimiteAdiantamento: string;

    public desativaCardFolha: boolean = false;
    public desativaCardAdiantamento: boolean = false;

    public corDataLimiteVermelhaCard: boolean = false;

    constructor(
        public $rootScope: angular.IRootScopeService & { nsjGlobals: any },
        public $scope: angular.IScope,
        public $stateParams: angular.ui.IStateParamsService,
        public entityService: any,
        public $state: angular.ui.IStateService,
        public ModalConfirmService: any,
        public $q: any,
        public $transitions: any,
        public NewToaster: any,
    ) {
        $scope.$on('persona_trabalhadores_loaded', (event: any, args: any) => {
            this.entity = args;
            this.busy = false;
        });

        this.exitScreenDescartarModal();
        document.onkeydown = this.refreshDescartarModal;
        this.allowRefresh = true;
    }

    $onInit() {
        this.busy = true;

        if (this.passoUmAtivo === '0' && !this.valorExiste(this.entity.trabalhador)) {
            this.entityService.get(this.colaboradorSelecionado.trabalhador);
        } else {
            this.entity = this.entityService.get(this.colaboradorSelecionado.trabalhador);
            this.busy = false;
        }

        this.exibeCardAdiantamento = !!this.$rootScope.nsjGlobals.a.configuracoes.DATA_PAGAMENTO_ADIANTAMENTO && this.$rootScope.nsjGlobals.a.configuracoes.EXIBE_ADIANTAMENTO;
        this.entityDadosCriacao.dataFolhaAdiantamentoSalarial = moment(this.$rootScope.nsjGlobals.a.configuracoes.DATA_PAGAMENTO_ADIANTAMENTO, 'YYYY-MM-DD').format('DD/MM/YYYY');
        this.entityDadosCriacao.dataFolhaPagamento = moment(this.$rootScope.nsjGlobals.a.configuracoes.DATA_PAGAMENTO_FOLHA, 'YYYY-MM-DD').format('DD/MM/YYYY');
        this.competencia = this.$rootScope.nsjGlobals.a.configuracoes.COMPETENCIA_MES_ANO;

        this.desativaCardAdiantamento = moment().startOf('day').isAfter(moment(this.$rootScope.nsjGlobals.a.configuracoes.DATA_FECHAMENTO_ADIANTAMENTO, 'YYYY-MM-DD').startOf('day'));
        this.dataLimiteAdiantamento = moment(this.$rootScope.nsjGlobals.a.configuracoes.DATA_FECHAMENTO_ADIANTAMENTO, 'YYYY-MM-DD').format('DD/MM/YYYY');

        this.desativaCardFolha = moment().startOf('day').isAfter(moment(this.$rootScope.nsjGlobals.a.configuracoes.DATA_FECHAMENTO_FOLHA, 'YYYY-MM-DD').startOf('day'));
        this.dataLimiteFolha = moment(this.$rootScope.nsjGlobals.a.configuracoes.DATA_FECHAMENTO_FOLHA, 'YYYY-MM-DD').format('DD/MM/YYYY');

        // card único já carrega selecionado, caso não esteja desativado
        if (!this.exibeCardAdiantamento && !this.desativaCardFolha) {
            this.toggleTipoCalculo(1);
        }

        this.montaCardContents();

        if (!this.competencia) {
            this.NewToaster.pop({
                type: 'warning',
                title: 'Não há competências',
                body: 'Não existe uma competência para lançamento do movimento.',
                timeout: 10000
            });
        } else if ((!this.exibeCardAdiantamento || this.desativaCardAdiantamento) && this.desativaCardFolha) {

            this.NewToaster.pop({
                type: 'warning',
                title: this.exibeCardAdiantamento && this.desativaCardFolha ?
                    'Verifique as datas limite dos lançamentos' :
                    'Verifique a data limite do lançamento',
                body: this.exibeCardAdiantamento && this.desativaCardFolha ?
                    'As datas limite para lançamentos são anteriores à data de hoje.' :
                    'A data limite para lançamento é anterior à data de hoje.',
                timeout: 10000
            });

            this.corDataLimiteVermelhaCard = true;

        }

    }

    isBusy() {
        return this.busy;
    }

    montaCardContents() {
        this.cardContents = [
            {
                'title': '',
                'description': '',
            },
            {
                'title': '',
                'description': '',
            }
        ];

    }

    verificaTipoCalculo(valor: any) {
        if (this.entityDadosCriacao.tipocalculo === valor) {
            return true;
        } else {
            return false;
        }
    }

    toggleTipoCalculo(tipo: any) {

        // adiantamento
        if (tipo === 0 && this.desativaCardAdiantamento) {
            return;
        } else if (tipo === 1 && this.desativaCardFolha) {
            return;
        }

        this.entityDadosCriacao.tipocalculo = tipo;
        this.mensagensPassos.mensagemPasso02 = 'Efetuar os movimentos na folha ' +
            (this.entityDadosCriacao.tipocalculo === 0 ? 'de adiantamento salarial' : 'de pagamento') + ` da competência ${this.competencia}`;
        this.entitiesCreditosDescontos = [];
    }

    formInvalido() {
        if (this.valorExiste(this.entityDadosCriacao.tipocalculo) && this.valorExiste(this.entity.trabalhador)) {
            return false;
        } else {
            return true;
        }
    }

    calculaPasso(continua: boolean) {
        this.allowRefresh = false;
        if (continua) {
            this.passo = this.passo + 1;
        } else {
            this.passo = this.passo - 1;
        }
    }

    valorExiste(valor: any) {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    refreshDescartarModal = (e: any) => {
        e = e || window.event;
        if (this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                })
                .catch((error: any): void => {/* */ });
            }
        }
    }

    exitScreenDescartarModal() {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (trans._targetState._identifier !== 'creditosdescontos' && this.allowRefresh) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                })
                .catch(function () { /**/ });
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }
}
