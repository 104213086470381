import ng from 'angular';
import angular from 'angular';
import moment from 'moment';
import { IRescisao } from '../models/rescisao.model';
import { MeurhSolicitacoesrescisoesService } from '../solicitacoesrescisoes.service';
import { IConfiguracaoRescisao } from '../models/configuracaorescisao';
import { IEstadoWorkflow } from '../models/estadoworkflow';

export class MeurhSolicitacoesrescisoesIndexController implements ng.IController {
    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        '$location',
        'nsjRouting',
        'NewToaster',
        'MeurhSolicitacoesrescisoesService'
    ];

    public entities: Array<IRescisao> = [];
    public busy: boolean = false;
    public situacao: string = '';
    public lotacao?: { lotacao: string };
    public situacaoWkf: string = '';
    public filter: any = {};
    public filters: any = {};
    public tipo: string = '';
    public tipoInicial: string = '';
    public dataInicial: string | null = null;
    public dataFinal: string | null = null;
    public dataIntervaloInicialRescisao: string | null = null;
    public dataIntervaloFinalRescisao: string | null = null;

    public dataIntervaloInicialAtualizacao: string | null = null;
    public dataIntervaloFinalAtualizacao: string | null = null;

    public dataAtual = moment();
    public constructors: any = {};
    public configuracaoRescisao: IConfiguracaoRescisao;
    public estadosWorkflow: IEstadoWorkflow[];
    public temOrdenacaoSelecionada: boolean = false;

    public ordenacao: any = {
        'atualizacao': false,
        'envio': false,
        'rescisao': false,
    };

    public fields = [
        {
            value: 'trabalhador.nome',
            label: 'Nome',
            type: 'string',
            style: 'default',
            copy: '',
        },

        {
            value: 'codigo',
            label: 'Código',
            type: 'integer',
            style: 'default',
            copy: '',
        },

        {
            value: 'datarescisao',
            label: 'Data de Rescisão',
            type: 'datetime',
            style: 'default',
            copy: '',
        },

        {
            value: 'situacao',
            label: 'Situação',
            type: 'options',
            style: 'label',
            copy: '',
            options: {
                'Enviada': 'entity.situacao === "0"',
                'Efetuada': 'entity.situacao === "1"',
                'Cancelada': 'entity.situacao === "2"',
                'Excluída': 'entity.situacao === "3"',
                'Recusada': 'entity.situacao === "4"',
            },
            label_class: `{
                'label-warning': entity.situacao === 0,
                'label-success': entity.situacao === 1,
                'label-danger': entity.situacao === 2,
            }`,
        },

    ];

    // data da rescisão, data de atualização e data de criação
    public filtroDatas: '1' | '2' | '3' = '1';
    private toastTimeout: number = 8000;

    constructor(
        private $rootScope: angular.IRootScopeService & {
            tipo: any,
            temPermissao: (arg: string) => boolean,
            configuracaoRescisao: IConfiguracaoRescisao
        },
        private $scope: angular.IScope,
        private $state: angular.ui.IStateService,
        private $stateParams: angular.ui.IStateParamsService,
        private $location: angular.ILocationService,
        private nsjRouting: any,
        private NewToaster: any,
        private entityService: MeurhSolicitacoesrescisoesService,
    ) {
        this.entityService.filter = this.$stateParams['q'] ? this.$stateParams['q'] : '';
        this.filters = this.entityService.filters = {};
        this.filter.search = this.$stateParams['q'];
        this.tipo = this.$stateParams['tipo'];
        this.tipoInicial = this.$stateParams['tipo'];
        this.constructors = this.entityService.constructors = [];
        this.entityService.ordenacao = 'default';

        for (let i in this.$stateParams) {
            if (this.$stateParams.hasOwnProperty(i)) {
                if (
                    Object.prototype.toString.call(this.$stateParams[i]) === '[object Array]' &&
                    this.$stateParams[i].length > 0
                ) {
                    this.entityService.filters[i] = this.$stateParams[i];
                } else if (
                    typeof this.$stateParams[i] !== 'undefined' &&
                    typeof this.$stateParams[i] !== 'function' &&
                    i !== 'q' && i !== 'solicitacao'
                ) {
                    this.entityService.constructors[i] = this.$stateParams[i];
                }
            }
        }

        if (!this.$rootScope.temPermissao('visualizar_rescisao')) {
            this.$state.go('dp', this.$stateParams);
        }

        !!this.$stateParams['tipo'] && this.tipo !== '' && this.tipo !== '-1'
            ? this.entityService.constructors['trabalhador_tipo'] = this.$stateParams['tipo']
            : delete this.entityService.constructors.trabalhador_tipo;

        this.$scope.$on('meurh_solicitacoesrescisoes_deleted', (event: angular.IAngularEvent) => {
            this.entities = this.entityService.reload();
        });

        this.$scope.$on('$destroy', () => {
            if (this.entityService.loading_deferred) {
                this.entityService.loading_deferred.resolve();
            }
        });

        this.$rootScope.$on('meurh_solicitacoesrescisoes_submitted', (event: angular.IAngularEvent, args: any) => {
            if (!args.autosave) {
                this.entities = this.entityService.reload();
            }
        });

        this.filterReload();
    }

    public async $onInit(): Promise<void> {
        !!this.$rootScope.configuracaoRescisao
            ? this.configuracaoRescisao = this.$rootScope.configuracaoRescisao
            : await this.getConfiguracaoRescisao();

        if (this.configuracaoRescisao.workflowativado) {
            await this.getEstadosWorkflow();
        }
    }

    public search(): void {
        if (this.filter.search !== '') {
            this.$location
                .path(this.$location.path())
                .search(
                    angular.extend(
                        {},
                        this.entityService.filters,
                        { 'q': this.filter.search }
                    )
                );
        } else {
            this.$location
                .path(this.$location.path())
                .search(
                    angular.extend(
                        {},
                        this.entityService.filters,
                        this.entityService.constructors
                    )
                );
        }
        this.entities = this.entityService.search(this.filter);
    }

    public canShowMore(): boolean {
        return !this.entityService.loadParams.finished;
    }

    public loadMore(): void {
        this.entityService.loadMore();
    }

    public filterReload(): void {
        let filtroDataInvalido = false;
        let filtroDataIntervaloInvalido = false;
        let filtroDataAtualizacaoInvalido = false;

        delete this.constructors.situacao;
        if (this.situacao !== '') {
            this.constructors.situacao = this.situacao;
        }

        delete this.constructors.lotacao;
        if (this.lotacao) {
            this.constructors.lotacao = this.lotacao.lotacao;
        }

        if (this.configuracaoRescisao && this.configuracaoRescisao.workflowativado && this.situacaoWkf !== '') {
            this.constructors['wkf.estado'] = this.situacaoWkf;
        } else {
            delete this.constructors['wkf.estado'];
        }

        if (
            !!this.dataInicial &&
            !!this.dataFinal &&
            this.dataInicial > this.dataFinal
        ) {
            this.NewToaster.pop({
                type: 'error',
                title: 'A data inicial não pode ser posterior a data final'
            });
            filtroDataInvalido = true;
        }

        // verificando se filtros de intervalo de datas de rescisão são inválidos
        if (
            !!this.dataIntervaloInicialRescisao &&
            !!this.dataIntervaloFinalRescisao &&
            this.dataIntervaloInicialRescisao > this.dataIntervaloFinalRescisao
        ) {
            this.NewToaster.pop({
                type: 'error',
                title: 'A data inicial do intervalo a ser buscado não pode ser posterior a data final'
            });
            filtroDataIntervaloInvalido = true;
        }


        if (
            !!this.dataIntervaloInicialAtualizacao &&
            !!this.dataIntervaloFinalAtualizacao &&
            this.dataIntervaloInicialAtualizacao > this.dataIntervaloFinalAtualizacao
        ) {
            this.NewToaster.pop({
                type: 'error',
                title: 'A data inicial do intervalo a ser buscado não pode ser posterior a data final'
            });
            filtroDataAtualizacaoInvalido = true;
        }

        if (!filtroDataInvalido && !filtroDataIntervaloInvalido && !filtroDataAtualizacaoInvalido) {

            this.busy = true;

            let created_at: Array<{ condition: string, value: string }> = [];
            let datarescisao: Array<{ condition: string, value: string }> = [];
            let lastupdate: Array<{ condition: string, value: string }> = [];

            if (this.dataInicial === undefined || (this.dataInicial !== null && this.dataInicial.length === 0)) {
                this.dataInicial = null;
                this.constructors.datainicial = null;
            }

            if (this.dataInicial !== null) {
                created_at.push(
                    {
                        condition: 'gte',
                        value: this.dataInicial + ' 00:00:00'
                    }
                );
                delete this.constructors.datainicial;
            }

            if (this.dataFinal === undefined || (this.dataFinal !== null && this.dataFinal.length === 0)) {
                this.dataFinal = null;
                this.constructors.datafinal = null;
            }

            if (this.dataFinal !== null) {
                created_at.push(
                    {
                        condition: 'lte',
                        value: this.dataFinal + ' 23:59:00'
                    }
                );
                delete this.constructors.datafinal;
            }

            this.constructors.created_at = created_at;

            // controles do filtro de intervalo de datas de rescisões
            if (this.dataIntervaloInicialRescisao === undefined || (this.dataIntervaloInicialRescisao !== null && this.dataIntervaloInicialRescisao.length === 0)) {
                this.dataIntervaloInicialRescisao = null;
            }

            if (this.dataIntervaloInicialRescisao !== null) {
                datarescisao.push(
                    {
                        condition: 'gte',
                        value: this.dataIntervaloInicialRescisao + ' 00:00:00'
                    }
                );
            }

            if (this.dataIntervaloFinalRescisao === undefined || (this.dataIntervaloFinalRescisao !== null && this.dataIntervaloFinalRescisao.length === 0)) {
                this.dataIntervaloFinalRescisao = null;
            }

            if (this.dataIntervaloFinalRescisao !== null) {
                datarescisao.push(
                    {
                        condition: 'lte',
                        value: this.dataIntervaloFinalRescisao + ' 23:59:00'
                    }
                );
            }

            this.constructors.datarescisao = datarescisao;

            if (this.dataIntervaloInicialAtualizacao === undefined || (this.dataIntervaloInicialAtualizacao !== null && this.dataIntervaloInicialAtualizacao.length === 0)) {
                this.dataIntervaloInicialAtualizacao = null;
            }

            if (this.dataIntervaloInicialAtualizacao !== null) {
                lastupdate.push(
                    {
                        condition: 'gte',
                        value: this.dataIntervaloInicialAtualizacao + ' 00:00:00'
                    }
                );
            }

            if (this.dataIntervaloFinalAtualizacao === undefined || (this.dataIntervaloFinalAtualizacao !== null && this.dataIntervaloFinalAtualizacao.length === 0)) {
                this.dataIntervaloFinalAtualizacao = null;
            }

            if (this.dataIntervaloFinalAtualizacao !== null) {
                lastupdate.push(
                    {
                        condition: 'lte',
                        value: this.dataIntervaloFinalAtualizacao + ' 23:59:00'
                    }
                );
            }

            this.constructors.lastupdate = lastupdate;

            this.entityService.constructors = this.constructors;
            this.entities = this.entityService.reload();
            return;
        }

        this.entities = [];
    }

    public filtraTipo(): void {
        if (
            this.entityService.constructors.hasOwnProperty('trabalhador_tipo') &&
            (this.tipo === '' || this.tipo === '-1')
        ) {
            delete this.entityService.constructors.trabalhador_tipo;
        } else {
            this.entityService.constructors.trabalhador_tipo = this.tipo;
        }
        this.entities = this.entityService.search();
    }

    public generateRoute(route: string, params: any): string {
        return this.nsjRouting.generate(route, params, true);
    }

    public async irParaRescisao(entity: IRescisao): Promise<void> {
        this.busy = true;
        await this.$state.go('meurh_solicitacoesrescisoes_show', {
            solicitacao: entity.solicitacao,
            tipo: this.tipo
        });
        this.busy = false;
        this.$scope.$applyAsync();
    }

    public isBusy(): boolean {
        return this.entityService.loadParams.busy;
    }

    public ordenarAtualizacao(): void {
        this.ordenacao = {
            'atualizacao': true,
            'envio': false,
            'rescisao': false,
        };

        this.entityService.ordenacao = 'atualizacao';
        this.temOrdenacaoSelecionada = true;
        this.entities = this.entityService.reload();
    }

    public ordenarEnvio(): void {
        this.ordenacao = {
            'atualizacao': false,
            'envio': true,
            'rescisao': false,
        };

        this.entityService.ordenacao = 'envio';
        this.temOrdenacaoSelecionada = true;
        this.entities = this.entityService.reload();
    }

    public ordenarRescisao(): void {
        this.ordenacao = {
            'atualizacao': false,
            'envio': false,
            'rescisao': true,
        };

        this.entityService.ordenacao = 'rescisao';
        this.temOrdenacaoSelecionada = true;
        this.entities = this.entityService.reload();
    }

    public limparOrdenacao(): void {
        this.ordenacao = {
            'atualizacao': false,
            'envio': false,
            'rescisao': false,
        };

        this.entityService.ordenacao = 'default';
        this.temOrdenacaoSelecionada = false;
        this.entities = this.entityService.reload();
    }

    public mudouExibicaoFiltroDatas () {

        this.dataInicial = null;
        this.dataFinal = null;
        this.dataIntervaloInicialRescisao = null;
        this.dataIntervaloFinalRescisao = null;
        this.dataIntervaloInicialAtualizacao = null;
        this.dataIntervaloFinalAtualizacao = null;

        this.filterReload();
    }

    // início - Requisições
    private async getConfiguracaoRescisao(): Promise<void> {
        this.busy = true;
        await this.entityService.getConfiguracaoRescisao()
            .then((configuracao: ng.IHttpResponse<IConfiguracaoRescisao>) => {
                this.configuracaoRescisao = this.$rootScope.configuracaoRescisao = configuracao.data;
            })
            .catch(() => {
                this.NewToaster.pop({
                    type: 'error',
                    tittle: 'Ocorreu um erro ao obter as configurações da rescisão',
                    timeout: this.toastTimeout
                });

                this.configuracaoRescisao = {
                    workflowativado: false
                };
            })
            .finally(() => this.busy = false);
    }

    private async getEstadosWorkflow(): Promise<void> {
        this.busy = true;
        await this.entityService.getEstadosWorkflow()
            .then((estados: ng.IHttpResponse<IEstadoWorkflow[]>) => {
                this.estadosWorkflow = estados.data;
            })
            .catch(() => {
                this.NewToaster.pop({
                    type: 'error',
                    tittle: 'Ocorreu um erro ao obter as situações para filtragem.',
                    timeout: this.toastTimeout
                });
            })
            .finally(() => this.busy = false);
    }
    // fim - Requisições
}
