import angular from 'angular';
import moment from 'moment';
import { MeurhFeriasService } from '../../ferias.service';
import { ListagemService } from '../../../../../shared/services/listagem.service';
import { IFerias } from '../../models/ferias.model';
import { Empresa, Estabelecimento } from '../../../../../core/usuario/models/usuario-model';
import { conversaoFormatoData, conversaoFormatoPeriodo } from '../../../../../utils/utils';

type IMeurhFeriasmarcacoesIndexFilter = {
    empresa?: string;
    estabelecimento?: string;
    situacao?: string;
    lotacao?: {
        lotacao: string;
    } | null;
};

export class MeurhFeriasmarcacoesIndexController implements angular.IController {
	static $inject = [
		'$rootScope',
		'$scope',
		'$state',
		'$http',
		'$stateParams',
		'$location',
		'nsjRouting',
		'NewToaster',
		'MeurhFeriasService',
		'ListagemService'
	];

	public busy: boolean = false;
	public dataInicial: string | null = null;
	public dataFinal: string | null = null;
	public dataAtual = moment();
	public tipoInicial: string;
	public filters: IMeurhFeriasmarcacoesIndexFilter = {};
	public entities: Array<IFerias>;

	public empresas: Array<Empresa>;
	public estabelecimentos: Array<Estabelecimento>;
	public listagemPermissao: Array<string> = ['visualizar_ferias'];
	public permissaoGrupoEmpresarial = false;

	constructor(
		public $rootScope: angular.IScope & {
			temPermissao: ((args: string) => boolean),
			temPermissaoGrupoEmpresarial: (permissoes: string[], grupo: string) => boolean,
			session: any,
			nsjGlobals: any
		},
		public $scope: angular.IScope,
		public $state: angular.ui.IStateService,
		public $http: angular.IHttpService,
		public $stateParams: angular.ui.IStateParamsService,
		public $location: angular.ILocationService,
		public nsjRouting: any,
		public NewToaster: { pop: Function },
		public entityService: MeurhFeriasService,
		public ListagemService: ListagemService
	) {
        if (!$rootScope.temPermissao('visualizar_ferias')) {
			$state.go('dp', $stateParams);
		}

		entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
		entityService.filters = {};
        entityService.constructors = {};

        this.permissaoGrupoEmpresarial = $rootScope.temPermissaoGrupoEmpresarial(
            this.listagemPermissao,
            $rootScope.nsjGlobals.a.grupoempresarial
        );

        this.filters = {
            ...this.filters,
            empresa: this.$rootScope.session.entities.empresa.empresa,
            estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento
        };

		this.montaListagemVisibilidade();
		this.filterReload();

		$rootScope.$on('meurh_solicitacoesferias_deleted', () => {
			entityService.reload();
		});

		$rootScope.$on('$destroy', () => {
			if (entityService.loading_deferred) {
				entityService.loading_deferred.resolve();
			}
		});

		$rootScope.$on('meurh_solicitacoesferias_submitted', (event: angular.IAngularEvent, args: any) => {
			if (!args.autosave) {
				entityService.reload();
			}
		});

		$rootScope.$on('meurh_solicitacoesferias_busca_padrao_list_finished', (event: angular.IAngularEvent, args: any) => {
			this.entities = args;
			this.tratarEntities();
		});
	}

	montaListagemVisibilidade() {
		this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
		this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa!, this.empresas, this.listagemPermissao);
	}

	mudaEstabelecimentos() {
		this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
		this.filters.estabelecimento = '';
		this.filtraEmpresaEstabelecimento();
	}

	filtraEmpresaEstabelecimento() {
        delete this.filters.lotacao;
        this.$rootScope.$broadcast('lotacao_refresh', this.constructorEmpresaEstabelecimento());
		this.filterReload();
	}

    constructorEmpresaEstabelecimento() {
        return {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento
        };
    }

	search(): Array<IFerias> {
		if (this.entityService.filter !== '') {
			this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.entityService.filter }));
		} else {
			this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters));
		}

		const filter = {
            search: this.entityService.filter,
            filters: angular.copy(this.entityService.filters)
        };
		return this.entityService.search(filter);
	}

	loadMore(): void {
		this.entityService.loadMore();
	}

	finished(): boolean {
		return this.entityService.loadParams.finished;
	}

	generateRoute(route: string, params: any): string {
		return this.nsjRouting.generate(route, params, true);
	}

	isBusy(): boolean {
		return this.entityService.loadParams.busy;
	}

    setConstructor() {
        let constructor = {
            ...this.filters,
            ...(this.filters.lotacao && { lotacao: this.filters.lotacao.lotacao }),
            ...this.setDataFilter()
        };
        if (!constructor.situacao) {
            delete constructor.situacao;
        }
        return constructor;
    }

	filterReload() {
		this.entityService.constructors = this.setConstructor();
		return this.entityService.reload();
	}

	setDataFilter() {
		if (
            this.dataInicial !== null && this.dataFinal !== null &&
            this.dataInicial !== '' && this.dataFinal !== '' &&
            this.dataInicial > this.dataFinal
        ) {
			return {};
		}

        let datainiciogozo: { condition: string, value: string }[] = [];

        if (this.dataInicial === undefined || (this.dataInicial !== null && this.dataInicial.length === 0)) {
            this.dataInicial = null;
        }

        if (this.dataInicial !== null) {
            datainiciogozo.push({
                condition: 'gte',
                value: this.dataInicial
            });
        }

        if (this.dataFinal === undefined || (this.dataFinal !== null && this.dataFinal.length === 0)) {
            this.dataFinal = null;
        }

        if (this.dataFinal !== null) {
            datainiciogozo.push({
                condition: 'lte',
                value: this.dataFinal
            });
        }

        return { datainiciogozo };
	}

	private tratarEntities(): void {
		this.entities.forEach((entity: IFerias) => {
			entity.datafimgozo = conversaoFormatoData(entity.datafimgozo);
			entity.datainiciogozo = conversaoFormatoData(entity.datainiciogozo);
			entity.created_at = conversaoFormatoPeriodo(entity.created_at);
		});
	}
}
