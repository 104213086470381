import angular from 'angular';
import { IBeneficioHistorico, IBeneficioTrabalhador } from './model/beneficios.model';
import { IInclusaoBeneficio, IInclusaoBeneficioGet, IInclusaoBeneficioSave } from './model/inclusao.model';
import { ICancelamentoBeneficio, ICancelamentoBeneficioGet, ICancelamentoBeneficioSave } from './model/cancelamento.model';
import { TipoBeneficiosEnum } from './beneficios.enum';

export class MeurhBeneficiosService {
    static $inject = ['$http', '$rootScope', '$q', 'nsjRouting'];

    public loading_deferred: angular.IDeferred<unknown>;

    constructor(
        public $http: angular.IHttpService,
        public $rootScope: angular.IScope,
        public $q: angular.IQService,
        public nsjRouting: any,
    ) { }

    getTiposBeneficios(empresa: string) {
        this.loading_deferred = this.$q.defer();
        return this.$q((resolve: angular.IQResolveReject<TipoBeneficiosEnum[]>, reject) => {
            this.$http.get<TipoBeneficiosEnum[]>(
                this.nsjRouting.generate('persona_beneficios_buscatiposbeneficiosdistintos', {
                    empresa
                }, true),
                { timeout: this.loading_deferred.promise }
            )
            .then((response) => {
                this.$rootScope.$broadcast('persona_beneficios_buscatiposbeneficiosdistintos_loaded', response.data);
                resolve(response.data);
            })
            .catch((response) => {
                reject(response);
            });
        });
    }

    getBeneficiosTrabalhador(trabalhador: string, agrupador?: number) {
        this.loading_deferred = this.$q.defer();
        return this.$q((resolve: angular.IQResolveReject<IBeneficioTrabalhador[]>, reject) => {
            this.$http.get<IBeneficioTrabalhador[]>(
                this.nsjRouting.generate('beneficiostrabalhadores_index', {
                    trabalhador,
                    ...(agrupador !== undefined && { agrupador })
                }, true),
                { timeout: this.loading_deferred.promise }
            )
            .then((response) => {
                this.$rootScope.$broadcast('beneficiostrabalhadores_index_loaded', response.data);
                resolve(response.data);
            })
            .catch((response) => {
                reject(response);
            });
        });
    }

    getHistoricoBeneficiosTrabalhador(id: string) {
        this.loading_deferred = this.$q.defer();
        return this.$q((resolve: angular.IQResolveReject<IBeneficioHistorico[]>, reject) => {
            this.$http.get<IBeneficioHistorico[]>(
                this.nsjRouting.generate('beneficiostrabalhadoresadesoes_historico', {
                    id
                }, true),
                { timeout: this.loading_deferred.promise }
            )
            .then((response) => {
                this.$rootScope.$broadcast('beneficiostrabalhadoresadesoes_historico_loaded', response.data);
                resolve(response.data);
            })
            .catch((response) => {
                reject(response);
            });
        });
    }

    getInclusoesBeneficio(trabalhador: string, situacao?: number, agrupador?: number) {
        this.loading_deferred = this.$q.defer();
        return this.$q((resolve: angular.IQResolveReject<IInclusaoBeneficio[]>, reject) => {
            this.$http.get<IInclusaoBeneficio[]>(
                this.nsjRouting.generate('meurh_solicitacoesinclusaobeneficios_index', {
                    trabalhador,
                    ...(situacao !== undefined && { situacao }),
                    ...(agrupador !== undefined && { agrupador })
                }, true),
                { timeout: this.loading_deferred.promise }
            )
            .then((response) => {
                this.$rootScope.$broadcast('meurh_solicitacoesinclusaobeneficios_index_loaded', response.data);
                resolve(response.data);
            })
            .catch((response) => {
                reject(response);
            });
        });
    }

    getInclusaoBeneficio(id: string) {
        this.loading_deferred = this.$q.defer();
        return this.$q((resolve: angular.IQResolveReject<IInclusaoBeneficioGet>, reject) => {
            this.$http.get<IInclusaoBeneficioGet>(
                this.nsjRouting.generate('meurh_solicitacoesinclusaobeneficios_get', {
                    id
                }, true),
                { timeout: this.loading_deferred.promise }
            )
            .then((response) => {
                this.$rootScope.$broadcast('meurh_solicitacoesinclusaobeneficios_get_loaded', response.data);
                resolve(response.data);
            })
            .catch((response) => {
                reject(response);
            });
        });
    }

    saveInclusaoBeneficio(entity: IInclusaoBeneficioSave) {
        this.loading_deferred = this.$q.defer();
		return this.$q((resolve: angular.IQResolveReject<IInclusaoBeneficioGet>, reject) => {
			this.$http.post<IInclusaoBeneficioGet>(
				this.nsjRouting.generate('meurh_solicitacoesinclusaobeneficios_create', {}, true), entity, {
				timeout: this.loading_deferred.promise
			})
			.then((response) => {
				this.$rootScope.$broadcast('meurh_solicitacoesinclusaobeneficios_create_loaded', response.data);
				resolve(response.data);
			})
			.catch((response) => {
				reject(response);
			});
		});
    }

    getCancelamentosBeneficio(trabalhador: string, situacao?: number) {
        this.loading_deferred = this.$q.defer();
        return this.$q((resolve: angular.IQResolveReject<ICancelamentoBeneficio[]>, reject) => {
            this.$http.get<ICancelamentoBeneficio[]>(
                this.nsjRouting.generate('meurh_solicitacoescancelamentobeneficios_index', {
                    trabalhador,
                    ...(situacao !== undefined && { situacao })
                }, true),
                { timeout: this.loading_deferred.promise }
            )
            .then((response) => {
                this.$rootScope.$broadcast('meurh_solicitacoescancelamentobeneficios_index_loaded', response.data);
                resolve(response.data);
            })
            .catch((response) => {
                reject(response);
            });
        });
    }

    getCancelamentoBeneficio(id: string) {
        this.loading_deferred = this.$q.defer();
        return this.$q((resolve: angular.IQResolveReject<ICancelamentoBeneficioGet>, reject) => {
            this.$http.get<ICancelamentoBeneficioGet>(
                this.nsjRouting.generate('meurh_solicitacoescancelamentobeneficios_get', {
                    id
                }, true),
                { timeout: this.loading_deferred.promise }
            )
            .then((response) => {
                this.$rootScope.$broadcast('meurh_solicitacoescancelamentobeneficios_get_loaded', response.data);
                resolve(response.data);
            })
            .catch((response) => {
                reject(response);
            });
        });
    }

    saveCancelamentoBeneficio(entity: ICancelamentoBeneficioSave) {
        this.loading_deferred = this.$q.defer();
		return this.$q((resolve: angular.IQResolveReject<ICancelamentoBeneficioGet>, reject) => {
			this.$http.post<ICancelamentoBeneficioGet>(
				this.nsjRouting.generate('meurh_solicitacoescancelamentobeneficios_create', {}, true), entity, {
				timeout: this.loading_deferred.promise
			})
			.then((response) => {
				this.$rootScope.$broadcast('meurh_solicitacoescancelamentobeneficios_create_loaded', response.data);
				resolve(response.data);
			})
			.catch((response) => {
				reject(response);
			});
		});
    }
}
