import angular from 'angular';
import { MeurhBeneficiosInclusaoFormController } from './inclusao.form.controller';

export class MeurhBeneficiosInclusaoFormComponent implements angular.IComponentOptions {
	static selector = 'meurhBeneficiosInclusaoFormComponent';
	static controller = MeurhBeneficiosInclusaoFormController;
	static template = require('!!html-loader!./inclusao.form.html');
	static transclude = true;
	static bindings = {
        colaborador: '<',
        tipoBeneficio: '<',
        entity: '=',
        form: '<?',
        editable: '<'
	};
}
