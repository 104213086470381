import angular from 'angular';
import { ITransferenciaColaboradores } from '../../models/transferenciacolaboradores.model';
export class MeurhTransferenciaColaboradoresLoteFormController {

  static $inject = ['$scope', '$http', 'nsjRouting', '$stateParams', '$rootScope', '$timeout'];

  public entity: ITransferenciaColaboradores;
  public form: angular.IFormController;

  constructor(
    public $scope: angular.IScope & { dropzoneConfig: any, dropzoneMethods: any },
    public $timeout: angular.ITimeoutService,
    public $http: angular.IHttpService,
    public nsjRouting: any,
    public $stateParams: angular.ui.IStateParamsService,
    public $rootScope: angular.IRootScopeService & { temPermissao: (arg: string) => boolean, modoGestorPermissao: (arg: string) => boolean, session: any, nsjGlobals: any, filterData: any },
    ) {

  }

  formatBytes(bytes: number, decimals: number = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
