declare var nsj;

export class ColaboradorController {

  static $inject = ['$scope', 'TrabalhadoresService', '$state', '$q', '$http', 'nsjRouting', '$rootScope'];

  public entityTrabalhador: any;
  public dadosBasicosTrabalhador: any = {};
  public trabalhadorFull: any = {};
  public identifier: any;
  public tipo: string;
  public showTipoData: any;
  public loading_deferred: any = null;
  public buscouDados: boolean;
  public buscouFoto: boolean;
  public foiTruncado: boolean = false;
  public cargonivel: string;
  public fotoColaborador: string;
  public carregouDadosTrabalhador: boolean = false;

  /**
   * @param $scope
   * @param TrabalhadoresService
   */
  constructor(
    public $scope: any,
    public TrabalhadoresService: any,
    public $state: any,
    public $q: any,
    public $http: any,
    public nsjRouting: any,
    public $rootScope: angular.IRootScopeService & { temPermissao: (arg: string) => boolean }
  ) {
    $scope.$on('persona_trabalhadores_get_resumo_loaded', () => {
      this.cargonivel = this.verificaTruncar(this.dadosBasicosTrabalhador.cargo, this.dadosBasicosTrabalhador.nivel);
    });

		this.$scope.$watch('$ctrl.identifier', (newValue: any, oldValue: any): void => {
			if ((newValue !== oldValue) ) {
        if (this.identifier) {
          this.buscouDados = false;
          this.buscouFoto = false;
          this.getTrabalhador(this.identifier);
        } else {
          this.fotoColaborador = undefined;
          this.dadosBasicosTrabalhador = undefined;
        }
			}
		}, true);
  }

  $onInit() {
    this.buscouDados = false;
    this.buscouFoto = false;

    if (this.showTipoData !== false) {
      this.showTipoData = true;
    }
    if (this.identifier) {
      this.getTrabalhador(this.identifier);
    } else {
      // não necessita buscar dados inexistentes.
      this.buscouDados = true;
      this.buscouFoto = true;
    }
  }

  verificaTruncar(cargo: string, nivel: string): string {
    if (cargo && nivel) {
      if ((`${cargo} - ${nivel}`).length > 20) {
        this.cargonivel = cargo + ' - ' + nivel;
        this.foiTruncado = true;
        return this.cargonivel.substring(0, 20);
      } else {
        return cargo + ' - ' + nivel;
      }
    }
    return '';
  }

  toggleMessage() {
    if (this.foiTruncado) {
      this.foiTruncado = false;
      this.cargonivel = `${this.dadosBasicosTrabalhador.cargo} - ${this.dadosBasicosTrabalhador.nivel}`;
    } else {
      this.foiTruncado = true;
      this.cargonivel = this.cargonivel.substring(0, 20);
    }
  }

  nameTipo() {
    if (this.dadosBasicosTrabalhador.tipo === tipoEnum.func) {
      this.tipo = 'Funcionário';
    } else if (this.dadosBasicosTrabalhador.tipo === tipoEnum.cont) {
      this.tipo = 'Contribuinte';
    } else if (this.dadosBasicosTrabalhador.tipo === tipoEnum.estag) {
      this.tipo = 'Estagiário';
    }
  }

  setSalario() {
    this.dadosBasicosTrabalhador.salariofixo = this.trabalhadorFull.salariofixo;
  }

  verFichaTrabalhador() {
    this.$state.go('trabalhadores_show', { 'trabalhador': this.identifier });
  }

  getTrabalhadoresDadosBasicos(identifier: string) {
    this.loading_deferred = this.$q.defer();

    return this.$q((resolve: any, reject: any) => {
      this.$http
        .get(this.nsjRouting.generate('persona_trabalhadores_get_resumo', { 'id': identifier, 'grupoempresarial': nsj.globals.getInstance().get('grupoempresarial'), 'tenant': nsj.globals.getInstance().get('tenant') }, true),
          { timeout: this.loading_deferred.promise })
        .then((response: any) => {
          this.dadosBasicosTrabalhador = response.data;
          if (this.trabalhadorFull.trabalhador !== undefined) {
            this.setSalario();
          }

          this.buscouDados = true;
          this.$rootScope.$broadcast('persona_trabalhadores_get_resumo_loaded', {
            response: response.data,
          });

          this.nameTipo();
        })
        .catch((response: any) => {
          this.buscouDados = true;
          console.log(response);
        });
    });
  }

  async getTrabalhador(identifier: string) {

    await this.TrabalhadoresService.get(identifier)
    .then((dadosTrabalhador) => {

      this.carregouDadosTrabalhador = true;
      this.trabalhadorFull = dadosTrabalhador;

      this.getTrabalhadoresDadosBasicos(identifier);
      this.getTrabalhadoresFoto(identifier);
      if (this.dadosBasicosTrabalhador.trabalhador !== undefined) {
        this.setSalario();
      }

    }).catch((error) => {
      this.carregouDadosTrabalhador = false;
    });
  }

  getTrabalhadoresFoto(identifier: string) {
    this.loading_deferred = this.$q.defer();

    return this.$q((resolve: any, reject: any) => {
      this.$http
        .get(this.nsjRouting.generate('persona_trabalhadores_foto', { 'id': identifier }, true), { responseType: 'arraybuffer' })
        .then((response: any) => {
          let mimeType = response.headers('Content-Type');
          let file = new Blob([response.data], { type: mimeType });
          let fileUrl = URL.createObjectURL(file);
          this.buscouFoto = true;
          resolve(response.data);
          this.fotoColaborador = fileUrl;
        })
        .catch((response: any) => {
          this.buscouFoto = true;
          reject(response);
        });
    });
  }

  isBusy(): boolean {
    return !(this.buscouDados && this.buscouFoto);
  }

}

export enum tipoEnum {
  func,
  cont,
  estag,
}
