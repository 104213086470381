import ng from 'angular';
import angular from 'angular';
import { MeurhTipossolicitacoesgenericasService } from '../../tipossolicitacoesgenericas.service';

export class MeurhSolicitacoesgenericasFormController implements ng.IController {
    static $inject = [
        '$scope',
        '$rootScope',
        '$stateParams',
        'NewToaster',
        'MeurhTipossolicitacoesgenericasService',
    ];

    public entity: any;
    public form: angular.IFormController;
    public action: string;
    public busy: boolean = false;
    public ckEditorConfig = {
        removePlugins: 'about, youtube'
    };

    // informações da competência
    public competenciasPossiveis: { month: number, year: number, display: string }[] = [];
    public competenciaSelecionada: { month: number, year: number, display: string } | null;

    public multiplosTrabalhadores: boolean = false;
    public limiteTrabalhadores: number = 20;

    public listaTipos: Array<{ tiposolicitacaogenerica: string, codigo: string, nome: string }>;

    constructor(
        private $scope: angular.IScope,
        private $rootScope: angular.IRootScopeService & {
            modoGestorPermissao: (arg: string) => boolean,
            session: any, configuracoes: any
        },
        private $stateParams: angular.ui.IStateParamsService,
        private NewToaster: any,
        private MeurhTipossolicitacoesgenericasService: MeurhTipossolicitacoesgenericasService,
    ) {
    }

    async $onInit(): Promise<void> {

        // preenchendo o select de tipos
        this.busy = true;

        await this.MeurhTipossolicitacoesgenericasService._load({}, {}, '')
        .then((tiposRetornados: any) => {
            this.listaTipos = tiposRetornados;
        })
        .catch((error) => {
            this.NewToaster.pop({
                type: 'error',
                title: 'Não foi possível buscar os tipos de solicitação extraordinária',
                body: error.data?.message ?? error,
            });
        })
        .finally(() => this.busy = false);

        if (this.action === 'insert') {

            this.entity.listaTrabalhadores = [];

        } else if (this.action === 'show') {

            this.busy = true;
            await this.MeurhTipossolicitacoesgenericasService.get(this.entity.tiposolicitacaogenerica.tiposolicitacaogenerica)
            .then((tipoRetornado) => {
                this.entity.dadosTipoSelecionado = tipoRetornado;
                this.multiplosTrabalhadores = this.entity.dadosTipoSelecionado.campos.some((campo) => {
                    return campo.codigo === 'trabalhador' && campo.multiplo === true;
                });
            })
            .catch((error) => {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não foi possível buscar os dados do tipo de solicitação extraordinária',
                    body: error.data?.message ?? error,
                });
            })
            .finally(() => this.busy = false);

            this.popularCompetenciaSelecionada(this.entity.mes, this.entity.ano);
            this.$scope.$applyAsync();

        }
    }

    private async onChangeTipo(): Promise<void> {
        if (this.entity.tiposolicitacaogenerica) {
            this.busy = true;

            this.MeurhTipossolicitacoesgenericasService.get(this.entity.tiposolicitacaogenerica)
            .then((tipoRetornado) => {

                this.entity.dadosTipoSelecionado = tipoRetornado;
                this.entity.exigeTrabalhador = this.verificaObrigatoriedadeCamposTipo('trabalhador');
                this.multiplosTrabalhadores = this.entity.dadosTipoSelecionado.campos.some((campo) => {
                    return campo.codigo === 'trabalhador' && campo.multiplo === true;
                });

                // se há mais de um trabalhador selecionado e o tipo não permite múltiplos trabalhadores
                if (this.entity.listaTrabalhadores?.length > 1 && !this.multiplosTrabalhadores) {
                    this.entity.trabalhador = null;
                    this.entity.listaTrabalhadores = [];

                } else if ((this.entity.trabalhador || this.entity.listaTrabalhadores?.length === 1) && !this.multiplosTrabalhadores) {

                    // se tem um trabalhador selecionado e o tipo não permite múltiplos trabalhadores
                    this.entity.trabalhador = this.entity.trabalhador ?? this.entity.listaTrabalhadores[0];
                    this.entity.listaTrabalhadores = [this.entity.trabalhador];

                } else if ((this.entity.trabalhador || this.entity.listaTrabalhadores?.length > 0) && this.multiplosTrabalhadores) {

                    // se tem um trabalhador selecionado e o tipo permite múltiplos trabalhadores
                    this.entity.listaTrabalhadores = this.entity.listaTrabalhadores ?? [this.entity.trabalhador];

                    setTimeout(() => {
                        this.entity.trabalhador = null;
                        this.$scope.$applyAsync();
                    }, 0);

                }

                this.competenciaSelecionada = null;
                this.entity.ano = null;
                this.entity.mes = null;

                // se tipo exibir a competência, criar select baseado nos seus valores de configuração
                if (this.verificaExibicaoCamposTipo('competencia')) {
                    let configuracaoCompetencia = this.entity.dadosTipoSelecionado.campos.filter((campo) => {
                        return campo.codigo === 'competencia';
                    })[0];

                    this.popularCompetencias(
                        configuracaoCompetencia.configuracao.meses.futuro,
                        configuracaoCompetencia.configuracao.meses.passado
                    );

                }

            })
            .catch((error) => {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não foi possível buscar os tipos de solicitação extraordinária',
                    body: error.data?.message ?? error,
                });
            })
            .finally(() => this.busy = false);

        } else {
            this.entity.dadosTipoSelecionado = null;
            this.competenciaSelecionada = null;
            this.entity.trabalhador = null;
            this.entity.ano = null;
            this.entity.mes = null;
            this.multiplosTrabalhadores = false;
            this.entity.exigeTrabalhador = false;
            this.entity.listaTrabalhadores = [];
        }

    }

    private verificaExibicaoCamposTipo(codigo: 'competencia' | 'trabalhador') {
        let exibeCampo: boolean = false;

        if (this.entity.dadosTipoSelecionado && this.entity.dadosTipoSelecionado.campos.length > 0) {

            exibeCampo = this.entity.dadosTipoSelecionado.campos.some((campo) => {
                return campo.codigo === codigo && campo.habilitado;
            });

        } else {
            exibeCampo = false;
        }

        return exibeCampo;

    }

    private verificaObrigatoriedadeCamposTipo(codigo: 'competencia' | 'trabalhador') {
        let obrigatorio: boolean = false;

        if (this.entity.dadosTipoSelecionado) {

            obrigatorio = this.entity.dadosTipoSelecionado.campos.some((campo) => {
                return campo.codigo === codigo && campo.obrigatorio;
            });

        } else {
            obrigatorio = false;
        }

        return obrigatorio;

    }

    private onChangeTrabalhador(trabalhadorSelecionado: any) {

        // se existe um trabalhador selecionado
        if (trabalhadorSelecionado) {

            // se múltiplos trabalhadores são permitidos
            if (this.multiplosTrabalhadores) {

                // verificando se o trabalhador selecionado já está na lista, para não adicionar novamente
                let trabalhadorDuplicado = this.entity.listaTrabalhadores.some ((trabalhadorLista) => trabalhadorLista.trabalhador === trabalhadorSelecionado.trabalhador);

                // verificando se o limite de trabalhadores na lista já foi atingido
                let limiteTrabalhadoresAtingido = this.entity.listaTrabalhadores.length === this.limiteTrabalhadores ? true : false;

                if (!trabalhadorDuplicado && !limiteTrabalhadoresAtingido) {
                    // adiciona o trabalhador selecionado à lista
                    this.entity.listaTrabalhadores.push(trabalhadorSelecionado);
                }

                // limpa o campo de seleção sem disparar ng-change novamente
                setTimeout(() => {
                    this.entity.trabalhador = null;
                }, 0);

            } else {
                // substitui a lista com o trabalhador selecionado, removendo o selecionado anteriormente
                this.entity.listaTrabalhadores = [trabalhadorSelecionado];
            }
        }
    }

    private removerTrabalhadorLista(trabalhadorRemovido: any) {

        // buscando o trabalhador na lista de trabalhadores
        const index = this.entity.listaTrabalhadores.findIndex(trabalhadorLista => trabalhadorLista.trabalhador === trabalhadorRemovido.trabalhador);

        // se o trabalhador foi encontrado, o remove da lista
        if (index >= 0) {
            this.entity.listaTrabalhadores.splice(index, 1);
        }

    }

    // alimenta as competências baseado nas quantidades de meses recebidas
    private popularCompetencias(qtdMesesFuturo: number, qtdMesesPassado: number) {
        // limpa o array antes de popular para evitar duplicidade
        this.competenciasPossiveis = [];

        let dataAtual = new Date();

        // adiciona meses passados
        if (qtdMesesPassado > 0) {
            for (let i = qtdMesesPassado; i > 0; i--) {
                let mesAnterior = new Date(dataAtual.getFullYear(), dataAtual.getMonth() - i, 1);
                let mes = mesAnterior.getMonth() + 1;
                let ano = mesAnterior.getFullYear();
                let mesAno = this.getNomeMes(mes) + '/' + ano;

                this.competenciasPossiveis.push({ month: mes, year: ano, display: mesAno });
            }
        }

        // adiciona o mês atual
        let mesAtual = dataAtual.getMonth() + 1;
        let anoAtual = dataAtual.getFullYear();
        let mesAnoAtual = this.getNomeMes(mesAtual) + '/' + anoAtual;
        this.competenciasPossiveis.push({ month: mesAtual, year: anoAtual, display: mesAnoAtual });

        // adiciona meses futuros
        if (qtdMesesFuturo > 0) {
            for (let i = 1; i <= qtdMesesFuturo; i++) {
                let mesSeguinte = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + i, 1);
                let mes = mesSeguinte.getMonth() + 1;
                let ano = mesSeguinte.getFullYear();
                let mesAno = this.getNomeMes(mes) + '/' + ano;

                this.competenciasPossiveis.push({ month: mes, year: ano, display: mesAno });
            }
        }
    }

    // método para converter o número do mês para o nome correspondente
    private getNomeMes (numeroMes: number) {
        var nomesMeses = [
            'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
            'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
        ];
        return nomesMeses[numeroMes - 1];
    }

    // método chamado ao mudar a competência
    private onChangeCompetencia (valorSelecionado: any) {
        if (valorSelecionado) {
            this.entity.mes = valorSelecionado.month;
            this.entity.ano = valorSelecionado.year;
        }
    }

    // método para preencher o select baseado em "mes" e "ano" para "show"
    private popularCompetenciaSelecionada (mes: number, ano: number) {
        var display = this.getNomeMes(mes) + '/' + ano;
        this.competenciasPossiveis.push({ month: mes, year: ano, display: display });
        this.competenciaSelecionada = this.competenciasPossiveis[0]; // exibir o valor recebido da API

        if (!this.competenciaSelecionada.month || !this.competenciaSelecionada.year) {
            this.competenciaSelecionada.display = 'Não informada';
        }

    }

}
