import * as angular from 'angular';
import { MeurhAfastamentosService } from './afastamentos.service';

export const MeurhAfastamentosListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {

    let resolve = {
        'entity': [
            'MeurhAfastamentosService',
            '$stateParams',
            '$state',
            '$q',
            function (
                entityService: MeurhAfastamentosService,
                $stateParams: angular.ui.IStateParamsService,
                $state: angular.ui.IStateService,
                $q: angular.IQService
            ): angular.ui.IStateParamsService | angular.IQService | {} {

                if ($stateParams['entity']) {
                    return $stateParams['entity'];
                } else {
                    if ($stateParams['solicitacao']) {
                        return $q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
                            entityService.get($stateParams['solicitacao'])
                                .then((data: any) => {
                                    resolve(data);
                                })
                                .catch((error: any) => {
                                    if (error.status === 404) {
                                        if ($state.href('meurh_afastamentos_not_found', $stateParams)) {
                                            $state.go('meurh_afastamentos_not_found', $stateParams);
                                        } else {
                                            $state.go('not_found', $stateParams);
                                        }
                                    }
                                });
                        });
                    } else {
                        return {};
                    }
                }
            }
        ]
    };

    $stateProvider
        .state('meurh_afastamentos', {
            url: '/solicitacoes/afastamentos?q',
            template: require('html-loader!./index/afastamentos.index.html'),
            controller: 'Meurh\AfastamentosIndexController',
            controllerAs: 'mrh_afstmts_lst_cntrllr',
            reloadOnSearch: false
        })
        .state('meurh_afastamentos_new', {
            parent: 'meurh_afastamentos',
            url: '/new',
            resolve: resolve,
            template: require('html-loader!./new/afastamentos.new.html'),
            controller: 'MeurhAfastamentosNewController',
            controllerAs: 'mrh_afstmts_frm_nw_cntrllr'
        })
        .state('meurh_afastamentos_show', {
            parent: 'meurh_afastamentos',
            url: '/show/:solicitacao?path',
            resolve: resolve,
            template: require('html-loader!./show/afastamentos.show.html'),
            controller: 'MeurhAfastamentosShowController',
            controllerAs: 'mrh_afstmts_frm_shw_cntrllr'
        })
        .state('meurh_afastamentos_edit', {
            parent: 'meurh_afastamentos',
            url: '/edit/:solicitacao',
            resolve: resolve,
            template: require('html-loader!./edit/afastamentos.edit.html'),
            controller: 'Meurh\AfastamentosEditController',
            controllerAs: 'mrh_afstmts_frm_edit_cntrllr'
        });
    }
];
