import angular from 'angular';
import { MeurhBeneficiosHistoricoController } from './beneficios.historico.controller';

export class MeurhBeneficiosHistoricoComponent implements angular.IComponentOptions {
    static selector = 'meurhBeneficiosHistoricoComponent';
    static controller = MeurhBeneficiosHistoricoController;
    static template = require('!!html-loader!./beneficios.historico.html');
    static transclude = true;
    static bindings = {};
}
