import angular from 'angular';
import moment from 'moment';
import { MeurhInclusaodependenteService } from '../inclusaodependente.service';
import { TrabalhadoresService } from '../../../Trabalhadores/trabalhadores.service';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { ITrabalhador } from '../../../Trabalhadores/models/trabalhador.model';
import { IInclusaodependente } from '../models/inclusaodependente.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

type IMeurhInclusaodependenteIndexFilter = {
    empresa?: string;
    estabelecimento?: string;
    situacao?: string;
    datainclusao?: string;
    lotacao?: {
        lotacao: string;
    } | null;
};

export class MeurhInclusaodependenteIndexController implements angular.IController {
    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        '$location',
        'MeurhInclusaodependenteService',
        'TrabalhadoresService',
        'BuscaAvancadaService',
        'ListagemService'
    ];

    public entities: Array<IInclusaodependente>;
    public filters: IMeurhInclusaodependenteIndexFilter = {};
    public tipo: string;
    public dataAtual = moment();
    public busy: boolean = false;

    // busca avançada - variáveis
    public trabalhadorInput: string = '';
    public entitiesTrabalhadores: Array<ITrabalhador>;

    public entity: any = { trabalhador: null };

    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['visualizar_dependente'];
    public permissaoGrupoEmpresarial = false;

    constructor(
        public $rootScope: angular.IRootScopeService & {
            temPermissaoGrupoEmpresarial: (permissoes: string[], grupo: string) => boolean,
            temPermissao: (arg: string) => boolean,
            modoGestorPermissao: (arg: string) => boolean,
            session: any,
            nsjGlobals: any,
            tipo: any
        },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
        public entityService: MeurhInclusaodependenteService,
        public TrabalhadoresService: TrabalhadoresService,
        public BuscaAvancadaService: any,
        public ListagemService: ListagemService,
    ) {
        entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
        entityService.filters = {};
        entityService.constructors = {};

        this.tipo = $stateParams['tipo'] ? $stateParams['tipo'] : '-1';

        this.permissaoGrupoEmpresarial = $rootScope.temPermissaoGrupoEmpresarial(
            this.listagemPermissao,
            $rootScope.nsjGlobals.a.grupoempresarial
        );

        this.filters = {
            ...this.filters,
            empresa: $rootScope.session.entities.empresa.empresa,
            estabelecimento: $rootScope.session.entities.estabelecimento.estabelecimento,
        };

        this.montaListagemVisibilidade();

        this.entities = this.filterReload();
        this.carregaTrabalhadores();

        $rootScope.$on('meurh_solicitacoesinclusaodependentes_list_finished', () => {
            this.busy = false;
        });
        $rootScope.$on('meurh_solicitacoesinclusaodependentes_loaded', () => {
            this.busy = false;
        });
        $scope.$on('meurh_solicitacoesinclusaodependentes_submitted', () => {
            this.busy = true;
            this.entities = [];
            this.entities = this.filterReload();
        });
        $scope.$on('meurh_solicitacoesinclusaodependentes_deleted', () => {
            this.busy = true;
            this.entities = [];
            this.entities = this.filterReload();
        });
        $scope.$on('meurh_solicitacoes_cancelada', () => {
            this.busy = true;
            this.entities = [];
            this.entities = this.filterReload();
        });
        $rootScope.$on('meurh_solicitacoesinclusaodependentes_get_loaded', () => {
            this.busy = false;
        });
        // busca avançada \/
        $rootScope.$on('persona_trabalhadores_list_finished', (event: angular.IAngularEvent, args: any) => {
            this.entitiesTrabalhadores = [...args];
        });
    }

    montaListagemVisibilidade() {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa!, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos() {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
        delete this.filters.lotacao;
        this.$rootScope.$broadcast('lotacao_refresh', this.constructorEmpresaEstabelecimento());
        this.limpaNsjSearch('colaboradorSearchFilter');
        this.carregaTrabalhadores();
        this.filterReload();
    }

    constructorEmpresaEstabelecimento() {
        return {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento
        };
    }

    carregaTrabalhadores(): void {
        let constructor = {
            ...this.constructorEmpresaEstabelecimento,
            'status': '00',
            ...(this.tipo && { 'tipo': this.tipo }),
        };
        this.TrabalhadoresService.constructors = constructor;
        this.TrabalhadoresService.filter = '';
        this.TrabalhadoresService.reload();
    }

    search(): Array<IInclusaodependente> {
        if (this.entityService.filter !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.entityService.filter }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters));
        }

        const filter = {
            search: this.entityService.filter,
            filters: angular.copy(this.entityService.filters)
        };
        return this.entityService.search(filter);
    }

    loadMore(): void {
        this.entityService.loadMore();
    }

    limparFiltros(): void {
        this.filters = this.constructorEmpresaEstabelecimento();
        this.entityService.constructors = this.filters;
        this.limpaTrabalhador();
    }

    filterReload(): Array<IInclusaodependente> {
        const constructors: any = { ...this.filters };

        delete constructors.created_at;
        delete constructors.solicitacao;

        if (!this.valorExiste(constructors.lotacao)) {
            delete constructors.lotacao;
        } else {
            constructors.lotacao = constructors.lotacao.lotacao;
        }

        if (!this.valorExiste(constructors.situacao)) {
            delete constructors.situacao;
        }

        delete constructors.trabalhador;
        if (this.valorExiste(this.entity) && this.valorExiste(this.entity.trabalhador)) {
            constructors.trabalhador = angular.copy(this.entity.trabalhador.trabalhador);
        }

        delete constructors.datainclusao;
        if (this.valorExiste(this.filters.datainclusao)) {
            constructors.datainclusao = this.filters.datainclusao + ' 00:00:00';
        }

        this.entityService.constructors = constructors;
        return this.entityService.reload();
    }

    isBusy(): boolean {
        return this.entityService.loadParams.busy;
    }

    goToShow(entity: IInclusaodependente): void {
        this.busy = true;
        this.$state.go('meurh_inclusaodependente_show', angular.extend({}, {
            'solicitacao': entity.solicitacao,
        })).then(() => this.busy = false);
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    // inicio - BUSCA AVANÇADA
    buscaAvancada(): void {
        if (this.tipo) {
            this.TrabalhadoresService.constructors.tipo = this.tipo;
        }
        delete this.TrabalhadoresService.constructors.trabalhador;

        let columns = ['Nome', 'Tipo'];
        let values = ['nome', 'tipo'];
        let enumValues = [];
        enumValues['nome'] = '';
        enumValues['tipo'] = 'lookupTipoTrabalhador';
        this.TrabalhadoresService.constructors['status'] = '00';
        let modal = this.BuscaAvancadaService.open(this, this.entity, this.TrabalhadoresService, columns, values, 'Colaboradores', enumValues, 'trabalhador', false, true, true, () => this.filterReload());
        modal.result.then(() => {/**/ }).catch(() => {/**/ });
    }

    evento(event: angular.IAngularEvent & { detail: any }): void {
        let constructor = {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento,
            'status': '00',
            'forcagestor': this.$rootScope.modoGestorPermissao('visualizar_dependente'),
            ...(this.tipo && { 'tipo': this.tipo }),
        };
        let filter: { search?: string } = {};
        filter.search = event.detail.value;
        this.TrabalhadoresService.constructors = constructor;
        this.TrabalhadoresService.filter = event.detail;
        this.TrabalhadoresService.search(filter);
    }

    retornaListagemCombo(): Array<ITrabalhador> {
        return this.entitiesTrabalhadores;
    }

    comboBusy(): boolean {
        return this.TrabalhadoresService.loadParams.busy;
    }

    selecionaTrabalhador(event: any): void {
        if (event) {
            this.entity.trabalhador = event.detail.value;
            this.filterReload();
        }
    }

    limpaTrabalhador(): void {
        this.entity = { trabalhador: null };
        this.filterReload();
    }

    limpaNsjSearch(id: string): void {
        document.querySelectorAll(`#${id} .componentPosition .customIcon`)[0]?.dispatchEvent(new Event('click'));
        document.querySelectorAll(`#${id} .componentPosition .customIconMobile`)[0]?.dispatchEvent(new Event('click'));
    }
    // fim - BUSCA AVANÇADA
}
