import ng from 'angular';
import angular from 'angular';
import { MeurhSolicitacoes } from '../../Solicitacoes/services/solicitacoes.service';
import { WorkflowService } from '../../../Workflow/workflow.service';
import { MeurhSolicitacoesgenericasService } from '../solicitacoesgenericas.service';
import { ISolicitacao } from '../../Solicitacoes/models/solicitacao.model';
import { ISolicitacoesGenericasShow } from '../models/solicitacaogenerica.model';
import { IAnexosSolicitacoesGenericasShow } from '../models/solicitacaogenerica.model';
import { MeurhVisualizarmodalService } from '../../Anexos/visualizarmodal/anexos.visualizarmodal.service';

export class MeurhSolicitacoesgenericasShowController implements ng.IController {
    static $inject = [
        'entity',
        '$scope',
        '$stateParams',
        '$state',
        'MeurhSolicitacoesgenericasService',
        'NewToaster',
        'WorkflowService',
        'MeurhSolicitacoes',
        '$rootScope',
        'ModalConfirmService',
        'MeurhVisualizarmodalService',
    ];

    public action: string = 'show';
    public dadosGeraisTitle: string;
    public busy: boolean = false;
    public form: angular.IFormController;

    // documentos anexos
    public listaAnexos: Array<IAnexosSolicitacoesGenericasShow> | null;
    public exibeAnexo: boolean = false;

    constructor(
        public entity: ISolicitacoesGenericasShow,
        private $scope: angular.IScope,
        private $stateParams: angular.ui.IStateParamsService,
        private $state: angular.ui.IStateService,
        private entityService: MeurhSolicitacoesgenericasService,
        private NewToaster: any,
        private WorkflowService: WorkflowService,
        private MeurhSolicitacoes: MeurhSolicitacoes,
        private $rootScope: angular.IRootScopeService & {
            usuario: any,
            temPermissao(permissao: string): any
        },
        private ModalConfirmService: any,
        private MeurhVisualizarmodalService: MeurhVisualizarmodalService,
    ) {
    }

    public async $onInit(): Promise<void> {
        this.$scope.$on('workflow_reloaded_after', (event: angular.IAngularEvent, args: any): void => {
            this.$state.reload();
        });

        this.WorkflowService.config(this.entity.solicitacao, 'meurh_solicitacoesgenericas_');

        this.listaAnexos = this.entity.anexo;

        // verificando se exibe parte de anexos
        this.exibeAnexo = this.listaAnexos.length > 0;

    }

    /**
     * Faz o download do anexo ou apenas abre uma modal para visualização, dependendo do valor do parâmetro "baixar"
     * @param anexo O arquivo selecionado
     * @param baixar Informa se irá fazer o download do arquivo
     */
    public async baixarArquivo(anexo: IAnexosSolicitacoesGenericasShow, baixar: boolean = true) {

        this.busy = true;

        await this.entityService.downloadAnexo(this.entity.solicitacao, anexo.anexo)
        .then((blob: any) => {
            baixar ? this.baixarArquivoBlob(blob, anexo.nome) : this.visualizarArquivo(blob);
        })
        .catch((error) => {
            this.NewToaster.pop({
                type: 'error',
                title: 'Download não concluído',
                body: 'Não foi possível fazer o download do arquivo.',
            });
        })
        .finally(() => {
            this.busy = false;
        });
    }

    /**
     * Recebe o blob do arquivo a ser visualizado e o exibe numa modal
     * @param blob blob do arquivo a ser exibido na modal
     */
    public visualizarArquivo(blob: Blob) {

        this.blobToBase64(blob)
        .then((base64String) => {

            let entityModal: any = { base64: base64String };
            let modal = this.MeurhVisualizarmodalService.open(entityModal);

            // tslint:disable-next-line:no-empty
            modal.result.then((): void => { }).catch((): void => { });

        })
        .catch((error) => {
            this.NewToaster.pop({
                type: 'error',
                title: 'Arquivo não visualizado',
                body: 'Não foi possível visualizar o arquivo.',
            });
        });
    }

    // início - ações do formulário
    public cancelar(entity: ISolicitacoesGenericasShow & ISolicitacao): void {

        let customText = {
            title: 'Cancelar solicitação extraordinária',
            subtitle: 'Após cancelar a solicitação, não será possível desfazer essa ação.',
            confirmText: 'Tem certeza que deseja cancelar a solicitação extraordinária?',
            closeButton: 'Voltar',
            confirmButton: 'Cancelar solicitação',
        };

        const confirm = this.ModalConfirmService.openCancelar(entity, 'solicitação extraordinária', customText);

        confirm.result
            .then((entity: ISolicitacoesGenericasShow & ISolicitacao) => {
                this.busy = true;
                this.MeurhSolicitacoes.cancelar(entity)
                    .then((response: any) => {
                        this.NewToaster.pop({
                            type: 'success',
                            title: 'Sucesso',
                            body: 'A solicitação extraordinária foi cancelada com sucesso'
                        });

                        this.$state.go('meurh_solicitacoesgenericas', angular.extend(this.entityService.constructors));

                    }).catch((response: any) => {
                        if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                            this.NewToaster.pop(
                                {
                                    type: 'error',
                                    title: 'Não foi possível cancelar a solicitação extraordinária',
                                    body: response.data.message,
                                });
                        } else {
                            this.NewToaster.pop({
                                type: 'error',
                                title: 'Não foi possível cancelar a solicitação extraordinária',
                                body: response,
                            });
                        }
                    }).finally(() => {
                        this.busy = false;
                    });
            })
            .catch((error: any) => {
                if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            });
    }

    // fim - ações do formulário

    // início - validações
    public podeCancelar(): boolean {
        return this.entity._podecancelar;
    }
    // fim - validações

    /**
     * Recebe uma quantidade em bytes e converte para MB ou KB, caso seja uma quantidade muito baixa em MB
     * @param bytes a quantidade de bytes a se converter
     */
    public converterParaMB(bytes: number): string {
        if (!bytes) {
            return '0 MB';
        }

        const megabytes = bytes / (1024 * 1024);

        // se for menor que 0.01 MB, exibe em KB
        if (megabytes < 0.01) {
            const kilobytes = bytes / 1024;
            return `${kilobytes.toFixed(2)} KB`;
        }

        return `${megabytes.toFixed(2)} MB`;
    }

    /**
     * Recebe um blob e retorna a string base64 do blob recebido
     * @param blob o blob que se deseja obter a string base64
     */
    private blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // evento que será acionado quando a leitura for concluída
            reader.onloadend = () => {
                if (reader.result) {
                    resolve(reader.result.toString());
                } else {
                    reject(new Error('Falha ao converter Blob para Base64'));
                }
            };

            // evento que será acionado em caso de erro
            reader.onerror = () => {
                reject(new Error('Erro ao ler o Blob'));
            };

            // inicia a leitura do Blob como Base64
            reader.readAsDataURL(blob);
        });
    }

    /**
     * Faz o download de um arquivo a partir do blob recebido
     * @param blob o blob do arquivo
     * @param nomeArquivo o nome do arquivo
     */
    private baixarArquivoBlob(blob: Blob, nomeArquivo: string) {

        // cria uma URL temporária para o Blob
        const url = URL.createObjectURL(blob);

        // cria o elemento de link <a> para baixar o arquivo
        const link = document.createElement('a');
        link.href = url;
        link.download = nomeArquivo; // nome do arquivo
        link.click(); // simula o clique no link

        // libera a URL temporária para evitar vazamento de memória
        URL.revokeObjectURL(url);
    }
    // fim - tratamentos de dados
}
