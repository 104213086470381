import angular from 'angular';
import { MeurhSolicitacoes } from '../../../../Meurh/Solicitacoes/services/solicitacoes.service';
import { WorkflowService } from '../../../../Workflow/workflow.service';
import { MeurhBeneficiosService } from '../beneficios.service';
import { TiposDocumentosRequeridosService } from '../../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { ModalConfirmService } from '../../../../../shared/components/modal-confirm/modal.confirm.service';
import { IAnexo } from '../../../../Meurh/Anexos/models/anexo.model';
import { IInclusaoBeneficioGet } from '../model/inclusao.model';
import { ISolicitacao } from '../../../../Meurh/Solicitacoes/models/solicitacao.model';
import { ICancelamentoBeneficioGet } from '../model/cancelamento.model';
import { TipoSolicitacaoEnum } from '../../../../../shared/enums/TipoSolicitacaoEnum';
import { TipoBeneficios } from '../beneficios.enum';

export class MeurhBeneficiosShowController {

    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'entity',
        'MeurhSolicitacoes',
        'WorkflowService',
        'MeurhBeneficiosService',
        'TiposDocumentosRequeridosService',
        'ModalConfirmService',
        'NewToaster'
    ];

    public busy = false;
    public nomeSolicitacao = '';
    public tipoSolicitacaoEnum = TipoSolicitacaoEnum;

    public formAnexo: angular.IFormController;
    public entityAnexo: IAnexo = {
		casodeuso: 'INCLUSAOBENEFICIO'
	};
    public tiposdocumentosrequeridos: any[];
    public busyTipoDocumentos = false;

    constructor(
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public entity: IInclusaoBeneficioGet | ICancelamentoBeneficioGet,
        public MeurhSolicitacoes: MeurhSolicitacoes,
        public WorkflowService: WorkflowService,
        public MeurhBeneficiosService: MeurhBeneficiosService,
        public TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        public ModalConfirmService: ModalConfirmService,
        public NewToaster: { pop: Function }
    ) {
        this.documentosAnexos();
        this.nomeSolicitacao = this.setNomeSolicitacao(this.entity);
        this.WorkflowService.config(
            this.entity.solicitacao,
            this.getRotaSolicitacao(this.entity.tiposolicitacao)
        );

        $scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
			this.busyTipoDocumentos = false;
			this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
				tipodocumentorequerido.validateResult = false;
				tipodocumentorequerido.fileSelecionado = false;
				tipodocumentorequerido.file = '';
				return tipodocumentorequerido;
			});
		});

        $scope.$on('workflow_reloaded_after', () => {
            this.$state.reload();
        });
    }

    modalCancelar(entity: (IInclusaoBeneficioGet | ICancelamentoBeneficioGet) & ISolicitacao) {
        const nomeSolicitacao = this.nomeSolicitacao.toLowerCase();
        const customText = {
            title: 'Cancelar',
            subtitle: `Você está prestes a cancelar a solicitação de ${nomeSolicitacao}.`,
            confirmText: 'Após o cancelamento, não será possível desfazer. Deseja continuar?',
            closeButton: 'Voltar',
            confirmButton: 'Cancelar solicitação',
        };
        const confirm = this.ModalConfirmService
            .openCancelar(entity, `Solicitação de ${nomeSolicitacao}`, customText);
        confirm.result.then(
            (entity: (IInclusaoBeneficioGet | ICancelamentoBeneficioGet) & ISolicitacao) => {
                this.cancelar(entity);
            }).catch(() => {/**/});
    }

    cancelar(entity: (IInclusaoBeneficioGet | ICancelamentoBeneficioGet) & ISolicitacao) {
        this.busy = true;
        const nomeSolicitacao = this.nomeSolicitacao.toLowerCase();
        this.MeurhSolicitacoes.cancelar(entity).then(() => {
            this.NewToaster.pop({
                type: 'success',
                title: `A Solicitação de ${nomeSolicitacao} foi cancelada com sucesso.`
            });
            this.$state.go('trabalhadores_show', {
                trabalhador: entity.trabalhador.trabalhador,
                tab: 10
            });
        }).catch((response) => {
            let error: string;
            if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                error = response.data.message;
            } else {
                error = `Ocorreu um erro ao cancelar solicitação de ${nomeSolicitacao}.`;
            }
            this.NewToaster.pop({
                type: 'error',
                title: error
            });
        }).finally(() => {
            this.busy = false;
        });
    }

    setNomeSolicitacao(entity: IInclusaoBeneficioGet | ICancelamentoBeneficioGet) {
        const nomeTipoBeneficio = TipoBeneficios[entity.agrupador].nome.toLowerCase();
        switch (entity.tiposolicitacao) {
            case TipoSolicitacaoEnum.INCLUSAO_BENEFICIO:
                return 'Inclusão de ' + nomeTipoBeneficio;
            case TipoSolicitacaoEnum.CANCELAMENTO_BENEFICIO:
                return 'Cancelamento de ' + nomeTipoBeneficio;
            case TipoSolicitacaoEnum.SUSPENSAO_BENEFICIO:
                return 'Suspensão de ' + nomeTipoBeneficio;
            default:
                return '';
        }
    }

    getRotaSolicitacao(tiposolicitacao: number) {
        switch (tiposolicitacao) {
            case TipoSolicitacaoEnum.INCLUSAO_BENEFICIO:
                return 'meurh_solicitacoesinclusaobeneficios_';
            case TipoSolicitacaoEnum.CANCELAMENTO_BENEFICIO:
                return 'meurh_solicitacoescancelamentobeneficios_';
            case TipoSolicitacaoEnum.SUSPENSAO_BENEFICIO:
                return 'meurh_solicitacoessuspensaobeneficios_';
            default:
                return '';
        }
    }

    documentosAnexos() {
        const estabelecimento = this.entity.estabelecimento.estabelecimento;
        const casodeuso = this.getCasoDeUsoAnexo(this.entity.tiposolicitacao);
        this.setEntityAnexo({
            solicitacao: this.entity.solicitacao,
            casodeuso,
            estabelecimento
        });
        this.carregaTiposDocumentosRequeridos();
    }

    carregaTiposDocumentosRequeridos() {
        this.busyTipoDocumentos = true;
        this.resetTiposDocumentosRequeridosService();
        this.TiposDocumentosRequeridosService.constructors = {
            tiposolicitacao: this.entity.tiposolicitacao
        };
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();
    }

    resetTiposDocumentosRequeridosService() {
		this.TiposDocumentosRequeridosService.entities = [];
		this.TiposDocumentosRequeridosService.loadParams = {
			to_load: 3,
			busy: false,
			finished: false
		};
		this.TiposDocumentosRequeridosService.after = {};
	}

	setEntityAnexo(params: { solicitacao: string, casodeuso: string, estabelecimento: string }) {
        this.entityAnexo['estabelecimento'] = params.estabelecimento;
        this.entityAnexo['solicitacao'] = params.solicitacao;
        this.entityAnexo['casodeuso'] = params.casodeuso;
    }

    getCasoDeUsoAnexo(tiposolicitacao: number) {
        switch (tiposolicitacao) {
            case TipoSolicitacaoEnum.CANCELAMENTO_BENEFICIO:
                return 'CANCELAMENTOBENEFICIO';
            case TipoSolicitacaoEnum.SUSPENSAO_BENEFICIO:
                return 'SUSPENSAOBENEFICIO';
            case TipoSolicitacaoEnum.INCLUSAO_BENEFICIO:
            default:
                return 'INCLUSAOBENEFICIO';
        }
    }
}
