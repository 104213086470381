import * as angular from 'angular';
import { MeurhTransferenciaColaboradoresService } from './transferenciacolaboradores.service';

export const MeurhTransferenciaColaboradoresListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {

    let resolve = {
        'entity': [
            'MeurhTransferenciaColaboradoresService',
            '$stateParams',
            '$state',
            '$rootScope',
            '$q',
            function (
                entityService: MeurhTransferenciaColaboradoresService,
                $stateParams: angular.ui.IStateParamsService,
                $state: angular.ui.IStateService,
                $rootScope: angular.IRootScopeService,
                $q: angular.IQService
            ): angular.ui.IStateParamsService | angular.IQService | {} {

                if ($stateParams['entity']) {
                    return $stateParams['entity'];
                } else {
                    if ($stateParams['solicitacao']) {
                        return $q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
                            $rootScope.$broadcast('meurh_solicitacoestransferenciasvagas_get_loading', {});
                            entityService.get($stateParams['solicitacao'])
                                .then((data: any) => {
                                    resolve(data);
                                })
                                .catch((error: any) => {
                                    if (error.status === 404) {
                                        if ($state.href('meurh_solicitacoestransferenciasvagas_not_found', $stateParams)) {
                                            $state.go('meurh_solicitacoestransferenciasvagas_not_found', $stateParams);
                                        } else {
                                            $state.go('not_found', $stateParams);
                                        }
                                    }
                                });
                        });
                    } else {
                        return {};
                    }
                }
            }
        ]
    };

    $stateProvider
        .state('meurh_solicitacoestransferenciasvagas', {
            url: '/transferenciacolaboradores?q',
            template: require('html-loader!./index/transferenciacolaboradores.index.html'),
            controller: 'Meurh\TransferenciaColaboradoresIndexController',
            controllerAs: 'mrh_trnsfrncclbrdrs_lst_cntrllr',
            reloadOnSearch: false
        })
        .state('meurh_solicitacoestransferenciasvagas_new', {
            parent: 'meurh_solicitacoestransferenciasvagas',
            url: '/new',
            params: {
                tipo: 'individual',
            },
            resolve: resolve,
            template: require('html-loader!./new/transferenciacolaboradores.new.html'),
            controller: 'MeurhTransferenciaColaboradoresNewController',
            controllerAs: 'mrh_trnsfrncclbrdrs_frm_nw_cntrllr'
        })
        .state('meurh_solicitacoestransferenciasvagas_show', {
            parent: 'meurh_solicitacoestransferenciasvagas',
            url: '/show/:solicitacao?path',
            resolve: resolve,
            template: require('html-loader!./show/transferenciacolaboradores.show.html'),
            controller: 'MeurhTransferenciaColaboradoresShowController',
            controllerAs: 'mrh_trnsfrncclbrdrs_frm_shw_cntrllr'
        })
        .state('meurh_solicitacoestransferenciasvagas_edit', {
            parent: 'meurh_solicitacoestransferenciasvagas',
            url: '/edit/:solicitacao?path',
            resolve: resolve,
            template: require('html-loader!./edit/transferenciacolaboradores.edit.html'),
            controller: 'MeurhTransferenciaColaboradoresEditController',
            controllerAs: 'mrh_trnsfrncclbrdrs_frm_edt_cntrllr'
        });
    }
];
