import * as angular from 'angular';
import { MeurhSolicitacoesvtsadicionaisService } from './solicitacoesvtsadicionais.service';

export const MeurhSolicitacoesvtsadicionaisListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {

    let resolve = {
        'entity': [
            'MeurhSolicitacoesvtsadicionaisService',
            '$stateParams',
            '$state',
            '$q',
            function (
                entityService: MeurhSolicitacoesvtsadicionaisService,
                $stateParams: angular.ui.IStateParamsService,
                $state: angular.ui.IStateService,
                $q: angular.IQService
            ): angular.ui.IStateParamsService | angular.IQService | {} {

                if ($stateParams['entity']) {
                    return $stateParams['entity'];
                } else {
                    if ($stateParams['solicitacao']) {
                        return $q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
                            entityService.get($stateParams['solicitacao'])
                                .then((data: any) => {
                                    resolve(data);
                                })
                                .catch((error: any) => {
                                    if (error.status === 404) {
                                        if ($state.href('meurh_solicitacoesvtsadicionais_not_found', $stateParams)) {
                                            $state.go('meurh_solicitacoesvtsadicionais_not_found', $stateParams);
                                        } else {
                                            $state.go('not_found', $stateParams);
                                        }
                                    }
                                });
                        });
                    } else {
                        return {};
                    }
                }
            }
        ]
    };

    $stateProvider
        .state('meurh_solicitacoesvtsadicionais', {
            url: '/solicitacoes/vtsadicionais?q?tipo',
            template: require('html-loader!./index/solicitacoesvtsadicionais.index.html'),
            controller: 'Meurh\SolicitacoesvtsadicionaisIndexController',
            controllerAs: 'mrh_slctcsvtsdcns_lst_cntrllr',
            reloadOnSearch: false
        })
        .state('meurh_solicitacoesvtsadicionais_new', {
            parent: 'meurh_solicitacoesvtsadicionais',
            url: '/new',
            resolve: resolve,
            template: require('html-loader!./new/solicitacoesvtsadicionais.new.html'),
            controller: 'MeurhSolicitacoesvtsadicionaisNewController',
            controllerAs: 'mrh_slctcsvtsdcns_frm_nw_cntrllr'
        })
        .state('meurh_solicitacoesvtsadicionais_show', {
            parent: 'meurh_solicitacoesvtsadicionais',
            url: '/:solicitacao?path',
            resolve: resolve,
            template: require('html-loader!./show/solicitacoesvtsadicionais.show.html'),
            controller: 'MeurhSolicitacoesvtsadicionaisShowController',
            controllerAs: 'mrh_slctcsvtsdcns_frm_shw_cntrllr'
        })
        .state('meurh_solicitacoesvtsadicionais_edit', {
            parent: 'meurh_solicitacoesvtsadicionais',
            url: '/:solicitacao/edit',
            resolve: resolve,
            template: require('html-loader!./edit/solicitacoesvtsadicionais.edit.html'),
            controller: 'Meurh\SolicitacoesvtsadicionaisEditController',
            controllerAs: 'mrh_slctcsvtsdcns_frm_edit_cntrllr'
        });
    }
];
