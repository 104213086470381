import angular from 'angular';
import { CalendarOption } from '../../../../shared/components/nsj-calendar-options/calendaroption';
import { ICalendarioFerias } from './models/calendarioferias.model';
import { IFerias } from '../models/ferias.model';
import { ITrabalhador } from '../../../Trabalhadores/models/trabalhador.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';
import { MeurhCalendarioFeriasService } from './calendarioferias.service';
import { ColaboradoresmodalService } from './calendarioferiasmobile/calendarioferiasmobile.modal';
import { ListagemService } from '../../../../shared/services/listagem.service';

type eventoObject = {
	bgColor: string,
	object: number,
	trabalhador: ITrabalhador,
	label: string,
	startDate: Date,
	endDate: Date,
};

type IMeurhCalendarioFeriasFilter = {
    empresa?: string;
    estabelecimento?: string;
    lotacao?: {
        lotacao: string;
    } | null;
};

export class MeurhCalendarioFeriasController implements angular.IController {
	static $inject = [
        '$rootScope',
        '$scope',
        '$stateParams',
        '$location',
        '$http',
        'MeurhCalendarioFeriasService',
        'ColaboradoresmodalService',
        'ListagemService'
    ];

	public entities: Array<ICalendarioFerias>;
	public mesAtual: Date = new Date();
	public checkboxTodos: boolean = false;
	public carregouParte: boolean = false;
	public carregouTudo: boolean = false;
	public busy: boolean = false;
	public calendarOptions: CalendarOption = new CalendarOption();
	public currentColorObject: number = 0;
	public calendarView: string;
	public filters: IMeurhCalendarioFeriasFilter = {};
	public listagemPermissao: Array<string> = ['visualizar_ferias'];
	public permissaoGrupoEmpresarial = false;
	public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
	public calendarEvents: Array<any> = [];

	constructor(
		public $rootScope: angular.IRootScopeService & {
            session: any,
            nsjGlobals: any,
            temPermissaoGrupoEmpresarial: (permissoes: string[], grupo: string) => boolean
        },
		public $scope: angular.IScope & { cores: any },
		public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
		public $http: angular.IHttpService,
        public entityService: MeurhCalendarioFeriasService,
		public ColaboradoresmodalService: ColaboradoresmodalService,
        public ListagemService: ListagemService,
	) {
		this.entityService.filter = '';

        this.permissaoGrupoEmpresarial = $rootScope.temPermissaoGrupoEmpresarial(
            this.listagemPermissao,
            $rootScope.nsjGlobals.a.grupoempresarial
        );

        this.filters = {
            ...this.filters,
            empresa: this.$rootScope.session.entities.empresa.empresa,
            estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento
        };

		this.montaListagemVisibilidade();

		this.entities = this.filterReload();
		this.busy = true;

		this.$scope.cores = [];

		$scope.$on('meurh_solicitacoesferias_calendario_list_finished', (event: angular.IAngularEvent, args: any): void => {
			this.entities = args.filter((entity: ICalendarioFerias) => entity.ferias.length > 0);
			this.getFotos();
			this.busy = false;
			this.carregouTudo = true;

			this.checkboxTodos = this.entities.length ? true : false;

			this.checkboxEntidades();
		});

		$scope.$watch('mrh_clndr_frs_cntrllr.filters.empresa', (newValue: any, oldValue: any) => {
            if (newValue !== oldValue) {
                this.mudaEstabelecimentos();
            }
        }, true);
	}

	montaListagemVisibilidade() {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa!, this.empresas, this.listagemPermissao);
    }

	mudaEstabelecimentos() {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
		this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
        delete this.filters.lotacao;
        this.$rootScope.$broadcast('lotacao_refresh', this.constructorEmpresaEstabelecimento());
		this.entities = this.filterReload();
    }

    constructorEmpresaEstabelecimento() {
        return {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento
        };
    }

    setConstructor() {
        return {
            ...this.filters,
            ...(this.filters.lotacao && { lotacao: this.filters.lotacao.lotacao }),
        };
    }

	getFotos(): void {
		this.entities.forEach((entity: ICalendarioFerias): void => {
			if (entity.foto) {
				this.$http.get(
					entity.foto,
					{ responseType: 'arraybuffer' }
				).then((response: any) => {
					let mimeType = response.headers('Content-Type');
					var file = new Blob([response.data], { type: mimeType });
					var fileUrl = URL.createObjectURL(file);

					entity.foto_url = fileUrl;
					entity.semFoto = false;
				});
			} else {
				entity.semFoto = true;
			}
		});
	}

	atualizaEventosCalendario(entity: ICalendarioFerias, index: number, modal: boolean = false): void {
		if (!entity.selecionado) {
			this.calendarEvents = this.calendarEvents.filter((obj: any) => obj.object !== index);
			this.$scope.cores[index] = { 'background-color': 'white' };
			this.checkboxTodos = false;

			return;
		}

		this.adicionaEventoCalendario(entity, index, modal);
	}

	adicionaEventoCalendario(entity: ICalendarioFerias, index: number, modal: boolean = false): void {
		let trabalhador: any = this.calendarEvents.filter((event: any) => event.trabalhador === entity.trabalhador);

		if (trabalhador.length) {
			entity.selecionado = true;
			return;
		}

		let label = '';
		let startDate = '';
		let endDate = '';
		trabalhador = '';

		// ao chegar no final do array de cores, a cor atual é resetada para a primeira cor do array
		if (this.currentColorObject < (this.calendarOptions.colors.length - 1)) {
			this.currentColorObject++;
		} else {
			this.currentColorObject = 0;
		}

		// verificar se array de cores possui apenas uma cor (#30C01E - devido ao modo ano)
		// para assim ser possível determinar se ao exibir o modo mês
		// irá pegar as cores vindas da modal, ou pegar as cores vindas
		// do array do calendarOptions.
		let coresIguais = this.$scope.cores.filter((entity) => entity['background-color'] === '#30C01E');

		entity.ferias.forEach((ferias: Partial<IFerias>): void => {
			trabalhador = entity.trabalhador;
			label = entity.nome;
			startDate = ferias.datainiciogozo!.replace(/-/g, '/');
			endDate = ferias.datafimgozo!.replace(/-/g, '/');

			let evento: eventoObject;
			if (modal === true && (coresIguais.length !== this.$scope.cores.length)) {
				evento = {
					object: index,
					trabalhador: trabalhador,
					bgColor: this.$scope.cores[index]['background-color'],
					label: label,
					startDate: new Date(startDate),
					endDate: new Date(endDate),
				};
			} else {
				evento = {
					object: index,
					trabalhador: trabalhador,
					bgColor: this.calendarOptions.colors[this.currentColorObject],
					label: label,
					startDate: new Date(startDate),
					endDate: new Date(endDate),
				};
			}

			this.$scope.cores[index] = {
				'background-color': this.calendarView === 'mes' ? evento.bgColor : '#30C01E',
				'border-color': this.calendarView === 'mes' ? evento.bgColor : '#30C01E',
			};

			this.calendarEvents = [...this.calendarEvents, evento];
		});

		if (!this.checkboxTodos) {
			let checkboxDesmarcados = this.entities.filter((entity) => entity.selecionado === false);
			this.checkboxTodos = !checkboxDesmarcados.length;
		}
	}

	removeEventoCalendario(index: number): void {
		this.calendarEvents = this.calendarEvents.filter((obj: any) => obj.object !== index);
		this.$scope.cores[index] = { 'background-color': 'white' };
	}

	removeDuplicados(array: Array<any>): Array<any> {
		let arr = array.reduce((r, i) =>
			!r.some((j: any) => JSON.stringify(i) === JSON.stringify(j)) ? [...r, i] : r, []);
		return arr;
	}

	returnCalendarEvents(): Array<any> {
		return this.calendarEvents;
	}

	checkboxEntidades(acao: boolean | null = null): void {
		this.calendarEvents = [];

		angular.forEach(this.entities, (entity, index): void => {
			if (acao == null) {
				entity.selecionado = this.checkboxTodos ? true : false;
				entity.selecionado ? this.atualizaEventosCalendario(entity, index) : this.removeEventoCalendario(index);
			} else {
				entity.selecionado = acao;
			}
		});

		if (acao != null) {
			this.checkboxTodos = acao ? true : false;
		}
	}

	search():  Array<ICalendarioFerias> {
		if (this.entityService.filter !== '') {
			this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.entityService.filter }));
		} else {
			this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters));
		}

		let filter = {
			search: this.entityService.filter,
			filters: angular.copy(this.entityService.filters)
		};

		this.busy = true;
		this.entities = [];

		return this.entityService.search(filter);
	}

	filterReload() {
        this.entityService.constructors = this.setConstructor();
		return this.search();
	}

	loadMore(): void {
		this.busy = true;
		this.entityService.loadMore();
	}

	calendarViewChanged($event: any): void {
		this.calendarView = $event.detail.view;
		this.$scope.cores.forEach((cor: any, index: any, arr: any) => {
			let evento = this.calendarEvents.find(ev => ev.object === index);

			if (evento) {
				if (this.calendarView === 'mes') {
					this.$scope.cores[index] = {
						'background-color': evento.bgColor,
						'border-color': evento.bgColor,
					};
				} else {
					this.$scope.cores[index] = {
						'background-color': '#30C01E',
						'border-color': '#30C01E',
					};
				}
			} else {
				this.$scope.cores[index] = {
					'background-color': '#FFF',
					'border-color': '#C0C0C0',
				};
			}
		});
	}

	abrirModal(): void {
		let objeto = {
			'entities': this.entities,
			'filters': this.filters,
			'cores': this.$scope.cores,
			'currentColorObject': this.currentColorObject,
			'calendarView': this.calendarView,
			'calendarOptions': this.calendarOptions,
			'listagemFiltros': {
				empresas: this.empresas,
				estabelecimentos: this.estabelecimentos,
				listagemPermissao: this.listagemPermissao,
				permissaoGrupoEmpresarial: this.permissaoGrupoEmpresarial
			},
		};

		var modal = this.ColaboradoresmodalService.open(objeto);

		modal.result.then((response: any) => {
			this.$scope.cores = response.cores;
			this.currentColorObject = response.currentColorObject;
			this.atualizaSelecionados(response.entities);
		}).catch((e: any) => { /* */ });
	}

	atualizaSelecionados(arrayEventosSelecionados: Array<ICalendarioFerias>): void {
		arrayEventosSelecionados.forEach((element: ICalendarioFerias, $index: number) => {
			element.selecionado ? this.atualizaEventosCalendario(element, $index, true) : this.removeEventoCalendario($index);
		});
	}
}
