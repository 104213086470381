import angular from 'angular';

export const MeurhPromocoesListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {
        $stateProvider
            .state('promocoes', {
                url: '/promocoes/{passozeroativo}/?trabalhador?solicitacao&q',
                template: require('html-loader!./wizard/promocoes.wizard.html'),
                controller: 'Meurh\PromocoesWizardController',
                controllerAs: 'mrh_prmcs_wzrd_cntrllr',
                reloadOnSearch: false
            })
            .state('promocoes_index', {
                url: '/promocoes?q',
                template: require('html-loader!./index/promocoes.index.html'),
                controller: 'Meurh\PromocoesListController',
                controllerAs: 'mrh_prmcs_lst_cntrllr',
                reloadOnSearch: false
            })
            .state('promocoes_showbuiltin', {
                parent: 'promocoes_index',
                url: '/showbuiltin/:solicitacao',
                params: {
                    showMode: 'builtin'
                },
                template: require('html-loader!./show/promocoes.showbuiltin.html'),
                controller: 'MeurhPromocoesShowController',
                controllerAs: 'mrh_prmcs_shw_cntrllr',
            })
            .state('promocoes_show', {
                url: '/promocoes/show/:solicitacao?path',
                params: {
                    showMode: 'full'
                },
                template: require('html-loader!./show/promocoes.show.html'),
                controller: 'MeurhPromocoesShowController',
                controllerAs: 'mrh_prmcs_shw_cntrllr',
                reloadOnSearch: false
            });
    }
];
