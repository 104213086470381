import angular from 'angular';
import { CargosSalariosService } from '../cargossalarios.service';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

type ICargosSalariosIndexFilter = {
    empresa?: string;
    estabelecimento?: string;
    situacao?: string;
    tipo?: string;
    trabalhador?: {
        trabalhador: string;
    } | null;
    lotacao?: {
        lotacao: string;
    } | null;
};

export class CargosSalariosIndexController {
    static $inject = [
        '$rootScope',
        'NewToaster',
        'CargosSalariosService',
        'RelatoriosService',
        'ListagemService',
    ];

    public trabalhadores: any = [];
    public lookupTrabalhadores = false;
    public primeiroLookupTrabalhadoresCarregado = false;
    public busy: boolean;
    public carregandoRelatorio: boolean;
    public todosSelecionados: boolean;
    public checkboxTodos: boolean;
    public listagemPermissao: Array<string> = ['relatorios_cargossalarios'];
    public permissaoGrupoEmpresarial = false;
    public estabelecimentos: Array<Estabelecimento>;
    public empresas: Array<Empresa>;
    public filters: ICargosSalariosIndexFilter = {
        tipo: '-1',
        situacao: '00',
    };
    public tipoTrabalhador: any = [
        'Funcionário',
        'Contribuinte',
        'Estagiário'
    ];

    constructor(
        public $rootScope: angular.IRootScopeService & {
            modoGestorPermissao: (arg: string) => boolean,
            temPermissaoGrupoEmpresarial: (permissoes: string[], grupo: string) => boolean,
            session: any,
            nsjGlobals: any,
        },
        public NewToaster: { pop: Function },
        public CargosSalariosService: CargosSalariosService,
        public RelatoriosService: any,
        public ListagemService: ListagemService,
    ) {
        this.filters = {
            ...this.filters,
            empresa: $rootScope.session.entities.empresa.empresa,
            estabelecimento: $rootScope.session.entities.estabelecimento.estabelecimento,
        };

        this.permissaoGrupoEmpresarial = $rootScope.temPermissaoGrupoEmpresarial(
            this.listagemPermissao,
            $rootScope.nsjGlobals.a.grupoempresarial
        );

        this.montaListagemVisibilidade();

        $rootScope.$on('Trabalhadores_loading', () => {
            if (!this.primeiroLookupTrabalhadoresCarregado) {
                this.lookupTrabalhadores = true;
                this.primeiroLookupTrabalhadoresCarregado = true;
            }
        });

        $rootScope.$on('Trabalhadores_loaded', () => {
            this.lookupTrabalhadores = false;
        });
    }

    montaListagemVisibilidade() {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa!, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos() {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
        delete this.filters.trabalhador;
        delete this.filters.lotacao;
        this.$rootScope.$broadcast('trabalhador_refresh', this.constructorColaboradores());
        this.$rootScope.$broadcast('lotacao_refresh', this.constructorEmpresaEstabelecimento());
        this.carregarRelatorio();
    }

    carregandoLookups() {
        return this.lookupTrabalhadores;
    }

    carregarRelatorio() {
        this.setTodosSelects(false);
        const construtor = this.setConstructor();
        this.CargosSalariosService.constructors = construtor;
        this.busy = true;
        this.trabalhadores = this.CargosSalariosService.reload();
        this.busy = false;
    }

    getTipoTrabalhador(tipo: any) {
        return !isNaN(tipo) ? this.tipoTrabalhador[parseInt(tipo, 10)] : tipo;
    }

    setConstructor(relatorio: boolean = false, usarSelects: boolean = false) {
        const trabalhadores = {
            ...(usarSelects && { entities: this.montarArrayTrabalhadoresSelecionados() }),
            ...((!usarSelects && this.filters.trabalhador) && { trabalhador: this.filters.trabalhador.trabalhador }),
        };
        return {
            ...this.filters,
            ...trabalhadores,
            ...(this.filters.lotacao && { lotacao: this.filters.lotacao.lotacao }),
            relatorio
        };
    }

    abrirRelatorio() {
        this.carregandoRelatorio = true;
        let callback = () => {
            this.carregandoRelatorio = false;
        };
        let config = {
            constructors: this.setConstructor(true, this.peloMenosUmSelecionado()),
            fileName: 'cargossalarios.pdf',
            useNewToaster: true
        };
        this.RelatoriosService.baixarRelatorioFopPost('relatoriosliberados_cargossalariosfop', config, callback);
    }

    constructorColaboradores() {
        return {
            'status': '01',
            'tipo_excluir': 1,
            'tipo': this.filters.tipo,
            'forcagestor': this.$rootScope.modoGestorPermissao('relatorios_cargossalarios'),
            ...this.constructorEmpresaEstabelecimento()
        };
    }

    constructorEmpresaEstabelecimento() {
        return {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento
        };
    }

    montarArrayTrabalhadoresSelecionados() {
        let arr: any = [];
        for (let i = 0; i < this.trabalhadores.length; i++) {
            if (this.trabalhadores[i].selected) {
                arr.push(this.trabalhadores[i].trabalhador);
            }
        }
        return arr;
    }

    peloMenosUmSelecionado() {
        return this.trabalhadores.some(x => x.selected);
    }

    setTodosSelects(to: boolean) {
        this.todosSelecionados = to;
        for (let i = 0; i < this.trabalhadores.length; i++) {
            this.trabalhadores[i].selected = to;
        }
    }

    atualizarSelect() {
        let todosSelecionados = true;
        for (let i = 0; i < this.trabalhadores.length; i++) {
            if (!this.trabalhadores[i].selected) {
                todosSelecionados = false;
            }
        }
        this.todosSelecionados = todosSelecionados;
    }

    isBusy() {
        return this.CargosSalariosService.loadParams.busy;
    }

    finished() {
        return this.CargosSalariosService.loadParams.finished;
    }

    checkboxEntidades(acao: any = null) {
        angular.forEach(this.trabalhadores, (entity, index) => {
            if (acao === null) {
                entity.check = this.checkboxTodos ? true : false;
            } else {
                entity.check = acao;
            }
        });
        if (acao !== null) {
            this.checkboxTodos = acao ? true : false;
        }
    }

    quantidadeFuncionariosSelecionados() {
        let qtd = 0;
        angular.forEach(this.trabalhadores, (entity, index) => {
            if (entity.check) {
                qtd++;
            }
        });
        return qtd;
    }


    todosFuncionariosSelecionados() {
        let selecionado = true;
        if (this.trabalhadores.length === 0) {
            selecionado = false;
        }
        angular.forEach(this.trabalhadores, (entity, index) => {
            if (entity.check === null || entity.check === false) {
                selecionado = false;
            }
        });
        return selecionado;
    }

    nenhumFuncionarioSelecionado() {
        let nenhumSelecionado = true;
        angular.forEach(this.trabalhadores, (entity, index) => {
            if (entity.check) {
                nenhumSelecionado = false;
            }
        });
        return nenhumSelecionado;
    }

    loadMore() {
        this.CargosSalariosService.loadMore();
    }
}
