declare var nsjGlobals: any;
import angular = require('angular');
import moment = require('moment');

export class MeurhCreditosdescontosAdicionarmodalService {
  static $inject = ['$uibModal'];

  constructor(public $uibModal: any) { }

  open(modalEntityCreditosDescontos: any, entitiesCreditosDescontosALL: any, tipo: any) {

    return this.$uibModal.open({
      template: require('html-loader!./creditosdescontos.adicionarmodal.html'),
      controller: ['entity', '$uibModalInstance', '$sce', '$compile', '$scope', 'MeurhCreditosdescontosAlertaduplicidadeService', 'TrabalhadoresService', '$rootScope', function MeurhCreditosdescontosAdicionarModal(entity: any, $uibModalInstance: any, $sce: any, $compile: any, $scope: any, MeurhCreditosdescontosAlertaduplicidadeService: any, TrabalhadoresService: any, $rootScope: angular.IRootScopeService & { nsjGlobals: any }, ) {
        var selfModal = this;
        selfModal.MeurhCreditosdescontosAlertaduplicidadeService = MeurhCreditosdescontosAlertaduplicidadeService;
        selfModal.TrabalhadoresService = TrabalhadoresService;
        selfModal.buscandoSalario = false;
        selfModal.anosSelect = [];
        selfModal.mesesSelect = [];
        selfModal.dataAtual = new Date();
        selfModal.mesAtual = '';
        selfModal.anoAtual = '';
        selfModal.errosPossiveisMensagens = {
          erroDatacompetenciafinalperiodo: false,
        };
        selfModal.errosPossiveis = {
          erroDatacompetenciafinalperiodo: '',
        };

        selfModal.modalEntityCreditosDescontos = modalEntityCreditosDescontos;
        selfModal.entitiesCreditosDescontosALL = entitiesCreditosDescontosALL;
        selfModal.tipo = tipo;

        selfModal.tipoNome = selfModal.modalEntityCreditosDescontos.tipo === 0 ? 'crédito' : 'desconto';
        selfModal.clienteAna = nsjGlobals.get('clienteana');
        selfModal.competencia = $rootScope.nsjGlobals.a.configuracoes.COMPETENCIA_MES_ANO;

        selfModal.$onInit = () => {
          if (!selfModal.modalEntityCreditosDescontos.isShow) {

            selfModal.modalEntityCreditosDescontos.situacao = '-';

            selfModal.modalEntityCreditosDescontos.mes = '';
            selfModal.modalEntityCreditosDescontos.ano = '';

            selfModal.modalEntityCreditosDescontos.evento = undefined;

            selfModal.modalEntityCreditosDescontos.tipoperiodo = '0';
            selfModal.setCompetenciapagamento();

            selfModal.modalEntityCreditosDescontos.evento_unidade = 'number';

            selfModal.modalEntityCreditosDescontos.referencia = '';

            if (selfModal.clienteAna) {
              selfModal.modalEntityCreditosDescontos.fim = false;
            }

          } else {

            // selfModal.modalEntityCreditosDescontos.conteudo = Number(selfModal.modalEntityCreditosDescontos.conteudo);
            selfModal.modalEntityCreditosDescontos.tipoperiodo = selfModal.modalEntityCreditosDescontos.tipoperiodo.toString();
            selfModal.modalEntityCreditosDescontos.mes = moment(selfModal.modalEntityCreditosDescontos.competenciafinalperiodoCompleta, 'DD/MM/YYYY').format('MM');
            selfModal.modalEntityCreditosDescontos.ano = moment(selfModal.modalEntityCreditosDescontos.competenciafinalperiodoCompleta, 'DD/MM/YYYY').format('YYYY');
          }

          selfModal.modalEntityCreditosDescontos.folhacreditadadata = (selfModal.modalEntityCreditosDescontos.tipocalculo === 0 ? 'Folha de adiantamento salarial' : 'Folha de pagamento') + ` da competência ${selfModal.competencia}`;

          selfModal.mesAtual = selfModal.dataAtual.getMonth();
          selfModal.anoAtual = selfModal.dataAtual.getFullYear();
          selfModal.montaSelectsData();
        };

        selfModal.setCompetenciapagamento = () => {
          selfModal.modalEntityCreditosDescontos.competenciapagamento = selfModal.competencia;
        };

        selfModal.setCompetenciafinalperiodo = () => {
          if (selfModal.modalEntityCreditosDescontos.mes !== '' && selfModal.modalEntityCreditosDescontos.ano !== '') {
            selfModal.modalEntityCreditosDescontos.competenciafinalperiodo = selfModal.modalEntityCreditosDescontos.mes + '/' + selfModal.modalEntityCreditosDescontos.ano;

            selfModal.verificaCompetenciafinalperiodoValida();
          }
        };

        selfModal.verificaCompetenciafinalperiodoValida = () => {
          let mesCompetenciapagamento = moment(selfModal.competencia, 'MM/YYYY').format('MM');
          let anoCompetenciapagamento = moment(selfModal.competencia, 'MM/YYYY').format('YYYY');

          let diferenca = moment([selfModal.modalEntityCreditosDescontos.ano, (selfModal.modalEntityCreditosDescontos.mes - 1)]).diff(moment([anoCompetenciapagamento, (Number(mesCompetenciapagamento) - 1)]), 'months', true);
          let competenciafinalperiodoCompleta = moment(selfModal.competencia, 'MM/YYYY').add(diferenca, 'months').format('MM/YYYY');

          if (!(moment(competenciafinalperiodoCompleta, 'MM/YYYY').isAfter(moment(selfModal.competencia, 'MM/YYYY')))) {
            selfModal.errosPossiveis['erroDatacompetenciafinalperiodo'] = true;
            selfModal.errosPossiveisMensagens['erroDatacompetenciafinalperiodo'] = 'A data competência final período deve ser posterior a data competência da folha selecionada: ' + selfModal.modalEntityCreditosDescontos.competenciapagamento;
          } else {
            selfModal.errosPossiveis['erroDatacompetenciafinalperiodo'] = false;
          }

          this.verificaArrayErros();
        };

        selfModal.selecionaPeriodo = () => {

          if (selfModal.modalEntityCreditosDescontos.tipoperiodo !== '2') {
            selfModal.errosPossiveis['erroDatacompetenciafinalperiodo'] = false;
            selfModal.modalEntityCreditosDescontos.mes = null;
            selfModal.modalEntityCreditosDescontos.ano = null;
            return;
          } else {

            if (!selfModal.modalEntityCreditosDescontos.ano || !selfModal.modalEntityCreditosDescontos.mes) {
              selfModal.errosPossiveis['erroDatacompetenciafinalperiodo'] = true;
              selfModal.errosPossiveisMensagens['erroDatacompetenciafinalperiodo'] = 'É necessário preencher o mês e o ano da competência final período.';
              return;
            }

          }
        };

        selfModal.selecionaRubrica = (inicializandoEdit: boolean = false) => {

          // para criar o input conteúdo correspondente a rubrica selecionada
          let inputHtml = '';
          let inputConteudo = angular.element(document.getElementById('inputConteudo'));
          if (inputConteudo) {
            inputConteudo.remove();
          }

          if (selfModal.modalEntityCreditosDescontos.evento) {
            let eventounidade = selfModal.modalEntityCreditosDescontos.evento.evento_unidade === undefined ? selfModal.modalEntityCreditosDescontos.evento.unidade : selfModal.modalEntityCreditosDescontos.evento.evento_unidade;
            switch (eventounidade) {
              case 0:
              case 8:
              case 10:
                // hora
                inputHtml = '<input id="inputConteudo" type="text" class="form-control" name="conteudo" ng-model="$ctrl.modalEntityCreditosDescontos.conteudo" ng-disabled="!$ctrl.modalEntityCreditosDescontos.evento || $ctrl.modalEntityCreditosDescontos.isEdit === false" time-mask placeholder="00:00" />';
                selfModal.modalEntityCreditosDescontos.evento_unidade = 'interval';
                break;
              case 2:
              case 12:
                // dinheiro
                inputHtml = '<input id="inputConteudo" type="text" class="form-control" name="conteudo" ng-model="$ctrl.modalEntityCreditosDescontos.conteudo"  ng-disabled="!$ctrl.modalEntityCreditosDescontos.evento || $ctrl.modalEntityCreditosDescontos.isEdit === false" ui-money-mask placeholder="0,00" />';
                selfModal.modalEntityCreditosDescontos.evento_unidade = 'currency';
                break;
              case 5:
                  // dinheiro
                  selfModal.buscandoSalario = true;
                  selfModal.TrabalhadoresService.get(selfModal.modalEntityCreditosDescontos.trabalhador.trabalhador)
                  .then(response => {
                    selfModal.modalEntityCreditosDescontos.conteudo = response.salariofixo;
                    selfModal.buscandoSalario = false;
                  })
                  .catch((error) => {
                    console.log('Erro: ', error);
                    selfModal.buscandoSalario = false;
                  });

                  inputHtml = '<input id="inputConteudo" type="text" class="form-control" name="conteudo" ng-model="$ctrl.modalEntityCreditosDescontos.conteudo" ng-disabled="true" ui-money-mask placeholder="0,00" />';
                  selfModal.modalEntityCreditosDescontos.evento_unidade = 'currency';
                  break;
              default:
                // variado
                inputHtml = '<input id="inputConteudo" type="number" class="form-control" name="conteudo" ng-model="$ctrl.modalEntityCreditosDescontos.conteudo" ng-disabled="!$ctrl.modalEntityCreditosDescontos.evento || $ctrl.modalEntityCreditosDescontos.isEdit === false" min="0" />';
                selfModal.modalEntityCreditosDescontos.evento_unidade = 'number';
            }
          } else {
            inputHtml = '<input id="inputConteudo" type="text" class="form-control" ng-disabled="true" />';
            selfModal.modalEntityCreditosDescontos.evento_unidade = 'number';
          }

          if (!inicializandoEdit) {
            selfModal.modalEntityCreditosDescontos.conteudo = '';
          }

          let compiledHtml = $compile(inputHtml)($scope);
          let inputBox = angular.element(document.getElementById('inputBox'));
          inputBox.append(compiledHtml);
        };

        selfModal.montaSelectsData = () => {
          selfModal.anosSelect = [
            {
              'ano': selfModal.anoAtual
            },
            {
              'ano': (selfModal.anoAtual + 1)
            },
            {
              'ano': (selfModal.anoAtual + 2)
            },
          ];

          selfModal.mesesSelect = [
            {
              'value': '01',
              'mes': 'Janeiro'
            },
            {
              'value': '02',
              'mes': 'Fevereiro'
            },
            {
              'value': '03',
              'mes': 'Março'
            },
            {
              'value': '04',
              'mes': 'Abril'
            },
            {
              'value': '05',
              'mes': 'Maio'
            },
            {
              'value': '06',
              'mes': 'Junho'
            },
            {
              'value': '07',
              'mes': 'Julho'
            },
            {
              'value': '08',
              'mes': 'Agosto'
            },
            {
              'value': '09',
              'mes': 'Setembro'
            },
            {
              'value': '10',
              'mes': 'Outubro'
            },
            {
              'value': '11',
              'mes': 'Novembro'
            },
            {
              'value': '12',
              'mes': 'Dezembro'
            },
          ];
        };

        selfModal.formInvalido = () => {
          if ((selfModal.modalEntityCreditosDescontos.evento && selfModal.modalEntityCreditosDescontos.evento !== undefined) &&
          (selfModal.modalEntityCreditosDescontos.lotacao)) {
            let unidade = selfModal.modalEntityCreditosDescontos.evento.evento_unidade;
            if (unidade === 0 || unidade === 1 || unidade === 2 || unidade === 8 || unidade === 10) {
              if ((selfModal.modalEntityCreditosDescontos.conteudo && selfModal.modalEntityCreditosDescontos.conteudo !== '00:00' && selfModal.modalEntityCreditosDescontos.conteudo !== undefined)) {
                if (selfModal.modalEntityCreditosDescontos.tipoperiodo === '2' && (selfModal.modalEntityCreditosDescontos.mes === '' || selfModal.modalEntityCreditosDescontos.ano === '')) {
                  return true;
                } else {
                  return false;
                }
              } else {
                  return true;
              }
            } else {
                return false;
            }
          } else {
             return true;
          }
        };

        selfModal.verificaArrayErros = () => {
          let existeErro = false;
          for (var i in this.errosPossiveis) {
            if (this.errosPossiveis[i] === true) {
              existeErro = true;
              break;
            }
          }

          return existeErro;
        };

        selfModal.verificaExistenciaSemelhante = () => {
          if (!selfModal.formInvalido() && !selfModal.verificaArrayErros()) {
            let semelhante: any = {};
            selfModal.entitiesCreditosDescontosALL.forEach((element: any) => {
              if (semelhante.conteudo === undefined) {
                if (selfModal.modalEntityCreditosDescontos.evento.evento === element.evento.evento) {
                  semelhante = angular.copy(element);
                }
              }
            });
            if (semelhante.conteudo !== undefined) {
              var modal = selfModal.MeurhCreditosdescontosAlertaduplicidadeService.open(modalEntityCreditosDescontos, semelhante);

              modal.result.then((response: any) => {
                if (response === true) {
                  selfModal.adicionar();
                } else {
                  selfModal.close();
                }
              }).catch((e: any) => { /**/ });
            } else {
              selfModal.adicionar();
            }
          }
        };

        selfModal.toggleEditar = (isEdit: boolean = false) => {
          selfModal.modalEntityCreditosDescontos.isEdit = isEdit;
        };

        selfModal.close = () => {
          $uibModalInstance.dismiss('fechar');
        };

        selfModal.adicionar = () => {
          $uibModalInstance.close(selfModal.modalEntityCreditosDescontos);
        };

        selfModal.remover = () => {
          selfModal.modalEntityCreditosDescontos.remover = true;
          $uibModalInstance.close(selfModal.modalEntityCreditosDescontos);
        };
      }],
      controllerAs: '$ctrl',
      backdrop: 'static',
      windowClass: 'nsj-modal-lg-no-height',
      resolve: {
        entity: function () {
          return angular.copy(modalEntityCreditosDescontos);
        }
      }
    });
  }
}
