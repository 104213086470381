import angular from 'angular';
import { TipoBeneficiosEnum } from '../../beneficios.enum';

export type IPlanosModal = {
    beneficio?: { beneficio: string, descricao: string },
    tipoBeneficio: TipoBeneficiosEnum,
    editable: boolean;
};

export class PlanosModalService {
    static $inject = ['$uibModal'];

    constructor(public $uibModal: ng.ui.bootstrap.IModalService) {}

    open(entity: IPlanosModal) {
        return this.$uibModal.open({
            template: require('html-loader!./planos.modal.html'),
            controller: 'PlanosModalController',
            controllerAs: 'plns_mdl_cntrllr',
            backdrop: 'static',
            resolve: {
                entity: () => angular.copy(entity)
            }
        });
    }
}
