import angular from 'angular';
import { IBeneficioTrabalhadorDetalhado } from '../../model/beneficios.model';

export class CancelamentoModalService {
    static $inject = ['$uibModal'];

    constructor(public $uibModal: ng.ui.bootstrap.IModalService) {}

    open(entity: IBeneficioTrabalhadorDetalhado) {
        return this.$uibModal.open({
            template: require('html-loader!./cancelamento.modal.html'),
            controller: 'CancelamentoModalController',
            controllerAs: '$ctrl',
            backdrop: 'static',
            windowClass: 'nsj-modal-lg-no-height',
            resolve: {
                entity: () => angular.copy(entity)
            }
        });
    }
}
