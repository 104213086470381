import angular from 'angular';
import { MeurhBeneficiosCancelamentoFormController } from './cancelamento.form.controller';

export class MeurhBeneficiosCancelamentoFormComponent implements angular.IComponentOptions {
	static selector = 'meurhBeneficiosCancelamentoFormComponent';
	static controller = MeurhBeneficiosCancelamentoFormController;
	static template = require('!!html-loader!./cancelamento.form.html');
	static transclude = true;
	static bindings = {
        colaborador: '<',
        tipoBeneficio: '<',
        limitDate: '<?',
        entity: '=',
        form: '<?',
        editable: '<'
	};
}
