import angular from 'angular';
import moment from 'moment';
import { SituacaoBeneficioEnum, TipoBeneficios, TipoBeneficiosEnum } from '../beneficios.enum';
import { TipoSolicitacaoEnum } from '../../../../../shared/enums/TipoSolicitacaoEnum';
import { MeurhBeneficiosService } from '../beneficios.service';
import { TiposDocumentosRequeridosService } from '../../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { IAnexo } from '../../../../Meurh/Anexos/models/anexo.model';
import { IInclusaoBeneficio, IInclusaoBeneficioGet, IInclusaoBeneficioSave } from '../model/inclusao.model';
import {
    IBeneficiario,
    IBeneficioTrabalhador,
    IBeneficioTrabalhadorDetalhado,
    IDependente,
    ITrabalhadorDependente
} from '../model/beneficios.model';

export class MeurhBeneficiosNewController {

    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        'MeurhBeneficiosService',
        'TiposDocumentosRequeridosService',
        'NewToaster'
    ];

    public form: angular.IFormController;
    public busy = false;
    public entity: IInclusaoBeneficioGet & {
        dependentestrabalhadores: IDependente[];
    };
    public tipoBeneficioObj: {
        nome: string,
        agrupador: TipoBeneficiosEnum
    } = this.setTipoBeneficio();
    public solicitacoesInclusao: IInclusaoBeneficio[] = [];
    public beneficiosTrabalhador: IBeneficioTrabalhadorDetalhado[] = [];

    public formAnexo: angular.IFormController;
    public tiposdocumentosrequeridos: any[];
    public entityAnexo: IAnexo = {
        casodeuso: 'INCLUSAOBENEFICIO'
    };
    public busyTipoDocumentos = false;

    constructor(
        public $rootScope: angular.IRootScopeService & {
            session: any,
            liberadoTela: (arg: string) => boolean,
        },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public MeurhBeneficiosService: MeurhBeneficiosService,
        public TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        public NewToaster: { pop: Function }
    ) {
        if (
            !this.$rootScope.liberadoTela('LIBERAR_VISUALIZACAO_BENEFICIO') ||
            !this.$rootScope.liberadoTela('LIBERAR_SOL_INCLUSAO_BENEFICIO')
        ) {
            $state.go('dp');
        }

        this.carregaTiposDocumentosRequeridos();

        $scope.$on('meurh_solicitacoesinclusaobeneficios_create_loaded', (event, args: IInclusaoBeneficioGet) => {
			if (this.tiposdocumentosrequeridos.length <= 0) {
				this.NewToaster.pop({
                    type: 'success',
                    title: 'Inclusão de benefício foi criada',
                    body: 'A inclusão de benefício foi criada com sucesso.'
                });
				this.$state.go('meurh_beneficios_show_inclusao', {
                    'solicitacao': args.solicitacao
                });
			} else {
                this.entityAnexo['solicitacao'] = args.solicitacao;
			    this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});

				this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
					this.$scope.$apply(); // para atualizar os valores dos validateResult vindos do componente anexo
					if (this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => tipodocumentorequerido.validateResult)) {
						this.NewToaster.pop({
                            type: 'success',
                            title: 'Inclusão de benefício foi criada',
                            body: 'A inclusão de benefício foi criada com sucesso.'
                        });
						this.$state.go('meurh_beneficios_show_inclusao', {
                            'solicitacao': args.solicitacao
                        });
					}
				});
			}
        });

        $scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
			this.busyTipoDocumentos = false;
			this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
				tipodocumentorequerido.validateResult = false;
				tipodocumentorequerido.fileSelecionado = false;
				return tipodocumentorequerido;
			});
		});
    }

    $onInit() {
        this.busy = true;
        const reqBeneficiosTrabalhador = this.MeurhBeneficiosService.getBeneficiosTrabalhador(
            this.$stateParams['colaborador'],
            this.tipoBeneficioObj.agrupador
        );
        const reqInclusoesBeneficio = this.MeurhBeneficiosService.getInclusoesBeneficio(
            this.$stateParams['colaborador'],
            0,
            this.tipoBeneficioObj.agrupador
        );
        Promise.all([
            reqBeneficiosTrabalhador,
            reqInclusoesBeneficio
        ]).then((values) => {
            this.busy = false;
            this.$scope.$applyAsync();
            this.setBeneficiosTrabalhadores(values[0]);
            this.setSolicitacoes(values[1]);
        });
    }

    hasBeneficioAtivo() {
        const dependentes = this.entity.dependentestrabalhadores.filter(dependente => dependente.selecionado);
        const isTitularBeneficioAtivo = this.beneficiosTrabalhador.some(beneficio => {
            return (beneficio.beneficiario.beneficiario === this.$stateParams['colaborador']) &&
                beneficio.beneficio.beneficio === this.entity.beneficio.beneficio;
        });
        if (!dependentes.length) {
            return isTitularBeneficioAtivo ? 1 : 3;
        }
        const isDependenteBeneficioAtivo = this.beneficiosTrabalhador.some(beneficio => {
            return beneficio.beneficio.beneficio === this.entity.beneficio.beneficio &&
                dependentes.some((dependente) => beneficio.beneficiario.beneficiario === dependente.dependentetrabalhador);
        });
        return (isTitularBeneficioAtivo && isDependenteBeneficioAtivo) ? 2 : 3;
    }

    submit() {
        this.form.$submitted = true;

        if (this.form.$invalid) {
            return;
        }

        const docsValid = this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
			return (
				tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio ||
				tipodocumentorequerido.fileSelecionado
			);
		});

        if (!docsValid) {
            this.NewToaster.pop({
                type: 'error',
                title: 'Há documento(s) obrigatório(s) não informado(s)'
            });
            return;
        }

        const hasBeneficiosAtivos = this.hasBeneficioAtivo();
        if (hasBeneficiosAtivos !== 3) {
            const title = hasBeneficiosAtivos === 1 ?
                'O titular já possui o benefício selecionado' :
                'Há dependente(s) com o benefício selecionado já ativo';
            this.NewToaster.pop({
                type: 'error',
                title: title
            });
            return;
        }

        this.busy = true;
        this.MeurhBeneficiosService.saveInclusaoBeneficio(this.setRequestBody());
    }

    setRequestBody(): IInclusaoBeneficioSave {
        const dependentes = this.entity.dependentestrabalhadores.filter(dependente => dependente.selecionado);
        return {
            trabalhador: this.$stateParams['colaborador'],
            situacao: 0,
            estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento,
            beneficio: this.entity.beneficio.beneficio,
            dataadesao: this.entity.dataadesao,
            observacao: this.entity.observacao,
            dependentestrabalhadores: dependentes
        };
    }

    carregaTiposDocumentosRequeridos() {
        this.busyTipoDocumentos = true;
        this.resetTiposDocumentosRequeridosService();
        this.TiposDocumentosRequeridosService.constructors = {
            tiposolicitacao: TipoSolicitacaoEnum.INCLUSAO_BENEFICIO
        };
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();
    }

	resetTiposDocumentosRequeridosService() {
		this.tiposdocumentosrequeridos = [];
		this.TiposDocumentosRequeridosService.entities = [];
		this.TiposDocumentosRequeridosService.loadParams = {
			to_load: 3,
			busy: false,
			finished: false
		};
		this.TiposDocumentosRequeridosService.after = {};
	}

    setBeneficiosTrabalhadores(entities: IBeneficioTrabalhador[]) {
        entities.forEach((entity) => {
            const entityCopy = {
                ...entity,
                ...this.setBeneficiario(entity),
                ...this.setSituacaoBeneficio(entity)
            } as IBeneficioTrabalhadorDetalhado;

            if (entityCopy.situacaoBeneficio === SituacaoBeneficioEnum.ATIVO) {
                this.beneficiosTrabalhador.push(entityCopy);
            }
        });
    }

    setSolicitacoes(entities: IInclusaoBeneficio[]) {
        entities.forEach((entity: IInclusaoBeneficio) => {
            const entityCopy = {
                ...entity,
                ...this.setBeneficiario(entity)
            } as IInclusaoBeneficio & IBeneficiario;

            this.solicitacoesInclusao.push(entityCopy);
        });
    }

    setSituacaoBeneficio(beneficiotrabalhador: IBeneficioTrabalhador) {
        const currentDate = moment();
        const dataAdesao = moment(beneficiotrabalhador.dataadesao, 'YYYY-MM-DD');

        if (currentDate.isBefore(dataAdesao)) {
            return { situacaoBeneficio: SituacaoBeneficioEnum.INATIVO };
        }

        if (beneficiotrabalhador.ultima_suspensao) {
            const inicioSuspensao = moment(beneficiotrabalhador.ultima_suspensao.datainicio, 'YYYY-MM-DD');
            const fimSuspensao = moment(beneficiotrabalhador.ultima_suspensao.datafim, 'YYYY-MM-DD');
            if (currentDate.isBetween(inicioSuspensao, fimSuspensao, undefined, '[]')) {
                return { situacaoBeneficio: SituacaoBeneficioEnum.SUSPENSO };
            }
        }

        if (beneficiotrabalhador.ultima_adesao) {
            const ultimaDataAdesao = moment(beneficiotrabalhador.ultima_adesao.dataadesao, 'YYYY-MM-DD');
            if (currentDate.isBefore(ultimaDataAdesao)) {
                return { situacaoBeneficio: SituacaoBeneficioEnum.INATIVO };
            }
            if (beneficiotrabalhador.ultima_adesao.dataexclusao) {
                const ultimaDataExclusao = moment(beneficiotrabalhador.ultima_adesao.dataexclusao, 'YYYY-MM-DD');
                if (currentDate.isBetween(ultimaDataAdesao, ultimaDataExclusao, undefined, '[]')) {
                    return { situacaoBeneficio: SituacaoBeneficioEnum.ATIVO };
                } else {
                    return { situacaoBeneficio: SituacaoBeneficioEnum.CANCELADO };
                }
            }
            return { situacaoBeneficio: SituacaoBeneficioEnum.ATIVO };
        }

        if (beneficiotrabalhador.dataexclusao) {
            const dataExclusao = moment(beneficiotrabalhador.dataexclusao, 'YYYY-MM-DD');
            if (currentDate.isBetween(dataAdesao, dataExclusao, undefined, '[]')) {
                return { situacaoBeneficio: SituacaoBeneficioEnum.ATIVO };
            } else {
                return { situacaoBeneficio: SituacaoBeneficioEnum.CANCELADO };
            }
        }

        return { situacaoBeneficio: SituacaoBeneficioEnum.ATIVO };
    }

    setBeneficiario(entity: ITrabalhadorDependente) {
        return {
            beneficiario: entity.dependentetrabalhador?.dependentetrabalhador ?
            {
                ...entity.dependentetrabalhador,
                beneficiario: entity.dependentetrabalhador.dependentetrabalhador
            } : {
                ...entity.trabalhador,
                beneficiario: entity.trabalhador.trabalhador
            }
        };
    }

    private setTipoBeneficio() {
        if (!this.$stateParams['tipobeneficio']) {
            return TipoBeneficios[TipoBeneficiosEnum.OUTROS];
        }
        const tipoBeneficioParam = parseInt(this.$stateParams['tipobeneficio'], 10);
        return TipoBeneficios[tipoBeneficioParam] ?? TipoBeneficios[TipoBeneficiosEnum.OUTROS];
    }
}
