import angular from 'angular';
import { TipoBeneficios, TipoBeneficiosEnum } from '../../beneficios.enum';
import { TrabalhadoresService } from '../../../../trabalhadores.service';
import { PlanosModalService } from '../../modal/planos/planos.modal.service';
import { ITrabalhador } from '../../../../models/trabalhador.model';
import { IInclusaoBeneficioGet } from '../../model/inclusao.model';
import { IDependente } from '../../model/beneficios.model';

export class MeurhBeneficiosInclusaoFormController {

    static $inject = [
        '$scope',
        '$rootScope',
        'PlanosModalService',
        'TrabalhadoresService'
    ];

    public colaborador: string;
    public tipoBeneficio: TipoBeneficiosEnum;
    public form: angular.IFormController;
    public editable = false;
    public entity: IInclusaoBeneficioGet & {
        dependentestrabalhadores: IDependente[];
    };

    public tiposDeBeneficiosConst = TipoBeneficios;
    public nomeBeneficiario: string;
    public trabalhador: ITrabalhador;
    public limitDate = '';
    public busy = false;

    constructor(
        public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService & { session: any },
        public PlanosModalService: PlanosModalService,
        public TrabalhadoresService: TrabalhadoresService
    ) {}

    $onInit() {
        if (this.editable) {
            this.getTrabalhador();
        } else {
            this.setBeneficiario();
        }
    }

    getTrabalhador() {
        this.busy = true;
        this.TrabalhadoresService.get(this.colaborador);
        this.$scope.$on('persona_trabalhadores_loaded', (event, args: ITrabalhador) => {
            this.trabalhador = args;
            this.limitDate = args.dataadmissao;
            this.setDependentes(args.dependentes ?? []);
            this.busy = false;
        });
    }

    setDependentes(dependentes: IDependente[]) {
        const dependentesCheck = dependentes.map(dependente => {
            return {
                ...dependente,
                selecionado: false
            };
        });
        this.entity.dependentestrabalhadores = dependentesCheck;
    }

    setBeneficiario() {
        if (!this.entity.dependentetrabalhador?.dependentetrabalhador) {
            this.nomeBeneficiario = this.entity.trabalhador.nome + ' (Titular)';
            return;
        }
        this.nomeBeneficiario = this.entity.dependentetrabalhador.nome + ' (Dependente)';
    }

    checkDependentes(checked: boolean) {
        if (
            !this.entity.dependentestrabalhadores ||
            !this.entity.dependentestrabalhadores.length
        ) {
            return;
        }
        this.entity.dependentestrabalhadores.forEach(dependente => (
            dependente.selecionado = checked
        ));
    }

    openPlanosModal() {
        const modal = this.PlanosModalService.open({
            beneficio: this.entity.beneficio,
            tipoBeneficio: this.tipoBeneficio,
            editable: this.editable
        });
        modal.result.then((response) => {
            this.entity.beneficio = response;
        }).catch(() => {/**/});
    }

}
