import angular from 'angular';
import { IControleFerias } from './models/controleferias.model';

export class ControleFeriasService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public entity: IControleFerias;
    public entities: IControleFerias[] = [];
    public entitiesRelatorio: any = [];
    public holidays: any = [];
    public loaded: boolean = false;
    public constructors: any = {};
    public after: any = {};
    public filters: any = {};
    public busySave: boolean = false;
    public loading_deferred: any = null;
    public filter: any = {
        key: '',
        filterfield: 'all'
    };
    public loadParams: any = {
        to_load: 3,
        busy: false,
        finished: false,
    };

    constructor(
        public $http: angular.IHttpService,
        public nsjRouting: any,
        public $rootScope: angular.IRootScopeService,
        public $q: angular.IQService
    ) { }

    reload() {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }
        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        return this.load();
    }

    search(a?: 'filterfield') {
        this.loadParams.finished = false;

        if (a === 'filterfield' && this.filter.key === '') {
            return [];
        }

        if (this.loadParams.busy) {
            this.loading_deferred.resolve();
        }

        this.loadParams.to_load = 1;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;

        return this.load();
    }

    async loadEntitiesRelatorioControleFerias(route_name: any) {
        this.entitiesRelatorio.length = 0;
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }
        this.loadParams.busy = true;

        await this._load(this.constructors, {}, this.filter, route_name).
            then((data: any) => {
                if (data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        this.entitiesRelatorio.push(data[i]);
                    }
                    this.after['trabalhador'] = this.entities[this.entities.length - 1]['trabalhador'];
                    this.after['nome'] = this.entities[this.entities.length - 1]['nome'];
                }
                if (data.length < 100) {
                    this.loadParams.finished = true;
                } else {
                    this.loadParams.finished = false;
                }
                this.loaded = true;

                this.loadParams.to_load--;


            }).catch((error) => {
                if (error.xhrStatus !== 'abort') {
                    this.loadParams.finished = true;
                }
            })
            .finally(() => {
                if (this.loaded || this.loadParams.finished) {
                    this.loadParams.busy = false;
                }
            });
        return this.entitiesRelatorio;
    }

    _load(constructors: any, offset: any, filter: any, route_name: string = 'meurh_solicitacoesferias_index') {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve, reject) => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate(route_name, angular.extend(constructors, { 'offset': offset, 'filter': filter }), true),
                timeout: this.loading_deferred.promise
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((response) => {
                    reject(response);
                });

        });

    }

    load() {
        if (!this.loadParams.busy && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any) => {
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }
                        this.after['trabalhador'] = this.entities[this.entities.length - 1]['trabalhador'];
                        if (this.constructors && this.constructors.order) {
                            var key = Object.keys(this.constructors.order)[0];
                            this.after[key] = this.entities[this.entities.length - 1][key];
                        }
                    }
                    if (data.length < 200) {
                        this.loadParams.finished = true;
                        this.$rootScope.$broadcast('controleferias_list_finished', this.entities);
                    } else {
                        this.$rootScope.$broadcast('controleferias_list_finished_part', this.entities);
                        this.loadParams.finished = false;
                    }

                    this.loadParams.loaded = true;
                    this.loadParams.to_load--;

                }).catch((error) => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.busy = true;
                    }
                    this.$rootScope.$broadcast('controleferias_list_error', error);
                }).finally(() => {
                    if (this.loadParams.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }
                });
        }
        return this.entities;
    }
    loadMore() {
        this.loadParams.to_load = 3;
        this.load();
    }
    _save(entity: IControleFerias,
        autosave: any,
        update: any,
        params?: { empresa: string, estabelecimento: string }) {
        let method: string, url: string;
        if (update) {
            method = 'PUT';
            url = this.nsjRouting.generate('meurh_solicitacoesferias_put', angular.extend(this.constructors, {
                'id': entity['solicitacao'],
                ...params
            }), true);
        } else {
            method = 'POST';
            url = this.nsjRouting.generate('meurh_solicitacoesferias_create',
                angular.extend({}, this.constructors, params),
            true);
        }
        entity['$$__submitting'] = true;

        let data = angular.copy(entity);
        if (data.hasOwnProperty('$$__submitting')) {
            delete data['$$__submitting'];
        }
        return this.$http({
            method: method,
            url: url,
            data: data,
        })
            .finally(() => {
                entity['$$__submitting'] = false;
            });
    }

    delete($identifier: any, force: any) {
        if (typeof ($identifier) === 'object') {
            $identifier = $identifier['solicitacao'];
        }
        if ($identifier) {
            if (force || confirm('Tem certeza que deseja deletar?')) {
                this.loading_deferred = this.$q.defer();
                this.$http
                    .delete(this.nsjRouting.generate('meurh_solicitacoesferias_delete', angular.extend(this.constructors, { 'id': $identifier }), true),
                        { timeout: this.loading_deferred.promise })
                    .then((response) => {
                        this.$rootScope.$broadcast('meurh_solicitacoesferias_deleted', {
                            entity: this.entity,
                            response: response
                        });
                    })
                    .catch((response) => {
                        this.$rootScope.$broadcast('meurh_solicitacoesferias_delete_error', {
                            entity: this.entity,
                            response: response
                        });
                    });
            }
        }
    }

    save(entity: IControleFerias,
        iTrabalhador: any,
        period: any,
        update: any,
        params?: { empresa: string, estabelecimento: string }) {
        this.busySave = true;
        this._save(entity, null, update, params)
            .then((response) => {
                entity['$$_saved'] = true;
                this.$rootScope.$broadcast('avisoferias_submitted', {
                    entity: entity,
                    response: response,
                    iTrabalhador: iTrabalhador,
                    period: period
                });
            }, (response) => {
                this.$rootScope.$broadcast('avisoferias_submit_error', {
                    entity: entity,
                    response: response,
                    iTrabalhador: iTrabalhador,
                    period: period
                });
            }).catch((response) => {
                this.$rootScope.$broadcast('avisoferias_submit_error', {
                    entity: entity,
                    response: response,
                    iTrabalhador: iTrabalhador,
                    period: period
                });
            }).finally(() => {
                this.busySave = false;
            });
    }
    confirm($identifier: any, force: any) {

        if (typeof ($identifier) === 'object') {
            $identifier = $identifier['solicitacao'];
        }

        if ($identifier) {
            if (force || confirm('Tem certeza que deseja confirmar?')) {
                this.$http({
                    method: 'POST',
                    url: this.nsjRouting.generate('avisoferias_confirmar', { 'id': $identifier }, true),
                    data: { 'id': $identifier, 'solicitacao': $identifier },
                }).then((response) => {
                    this.$rootScope.$broadcast('avisoferias_confirmed', {
                        entity: this.entity,
                        response: response
                    });
                }).catch((response) => {
                    this.$rootScope.$broadcast('avisoferias_confirm_error', {
                        entity: this.entity,
                        response: response
                    });
                });
            }
        }
    }

    _getFeriados(constructors: any, after: any, filter: any) {
        this.loading_deferred = this.$q.defer();
        return this.$q((resolve, reject) => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('controleferias_feriados', {}, true)
            }).then((response) => {
                resolve(response.data);
            })
                .catch((response) => {
                    reject(response);
                });
        });
    }

    getFeriados() {
        this._getFeriados(this.constructors, this.after, this.filter)
            .then((data: any) => {
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        this.holidays.push(data[i]['data']);
                    }
                }
            });

        return this.holidays;
    }
}
