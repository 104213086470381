import angular from 'angular';
import moment from 'moment';
import { MeurhAfastamentosService } from '../afastamentos.service';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { IAfastamento } from '../models/afastamento.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

type IMeurhAfastamentosIndexFilter = {
    empresa?: string;
    estabelecimento?: string;
    situacao?: string;
    tipohistorico?: string;
    lotacao?: {
        lotacao: string;
    } | null;
};

export class MeurhAfastamentosIndexController implements angular.IController {
    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        'MeurhAfastamentosService',
        'ListagemService',
        '$location',
        'NewToaster',
    ];

    public entities: Array<IAfastamento>;
    public filters: IMeurhAfastamentosIndexFilter = {
        situacao: '',
        tipohistorico: ''
    };
    public dataInicial: string | null = null;
    public dataFinal: string | null = null;
    public dataAtual: moment.Moment = moment();
    public busy: boolean = false;
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['visualizar_afastamentos'];
    public permissaoGrupoEmpresarial = false;
    public motivos = [
      {
        id: 0,
        descricao: 'Acidente de Trabalho',
        tipohistorico: '001'
      },
      {
        id: 1,
        descricao: 'Prorrogação decorrente do mesmo acidente de trabalho',
        tipohistorico: '002'
      },
      {
        id: 2,
        descricao: 'Doença',
        tipohistorico: '003'
      },
      {
        id: 3,
        descricao: 'Acidente não relacionado ao trabalho',
        tipohistorico: '003'
      },
      {
        id: 4,
        descricao: 'Prorrogação decorrente da mesma doença',
        tipohistorico: '004'
      },
      {
        id: 5,
        descricao: 'Prorrogação decorrente do mesmo acidente não relacionado ao trabalho',
        tipohistorico: '004'
      },
      {
        id: 6,
        descricao: 'Paternidade',
        tipohistorico: '005'
      },
      {
        id: 7,
        descricao: 'Maternidade',
        tipohistorico: '006'
      },
      {
        id: 8,
        descricao: 'Maternidade de filho com microencefalia',
        tipohistorico: '033'
      },
      {
        id: 9,
        descricao: 'Maternidade por aborto expontâneo',
        tipohistorico: '008'
      },
      {
        id: 10,
        descricao: 'Serviço militar',
        tipohistorico: '009'
      },
      {
        id: 11,
        descricao: 'Licença não remunerada ou sem vencimentos',
        tipohistorico: '011'
      },
      {
        id: 12,
        descricao: 'Suspensão',
        tipohistorico: '018'
      },
      {
        id: 13,
        descricao: 'Licença remunerada',
        tipohistorico: '025'
      },
      {
        id: 14,
        descricao: 'Adoção',
        tipohistorico: '026'
      },
      {
        id: 15,
        descricao: 'Guarda judicial de criança',
        tipohistorico: '026'
      },
      {
        id: 16,
        descricao: 'Vítima de violência - Lei Maria da Penha', // (não exibir para homens)
        tipohistorico: '028'
      },
      {
        id: 17,
        descricao: 'Prorrogação de licença maternidade',
        tipohistorico: '035'
      },
    ];

    constructor(
        public $rootScope: angular.IRootScopeService & {
          temPermissao: (arg: string) => boolean,
          temPermissaoGrupoEmpresarial: (permissoes: string[], grupo: string) => boolean,
          session: any,
          nsjGlobals: any
        },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public entityService: MeurhAfastamentosService,
        public ListagemService: ListagemService,
        public $location: angular.ILocationService,
        public NewToaster: { pop: Function },
    ) {
        entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
        entityService.filters = {};
        entityService.constructors = {};

        this.permissaoGrupoEmpresarial = $rootScope.temPermissaoGrupoEmpresarial(
            this.listagemPermissao,
            $rootScope.nsjGlobals.a.grupoempresarial
        );

        this.filters = {
            ...this.filters,
            empresa: $rootScope.session.entities.empresa.empresa,
            estabelecimento: $rootScope.session.entities.estabelecimento.estabelecimento,
        };

        this.montaListagemVisibilidade();

        this.entities = this.filterReload();

        $rootScope.$on('meurh_solicitacoesafastamentos_list_finished', () => {
            this.busy = false;

        });
        $rootScope.$on('meurh_solicitacoesafastamentos_loaded', () => {
            this.busy = false;

        });
        $scope.$on('meurh_solicitacoesafastamentos_submitted', (): void => {
            this.busy = true;
            this.entities = [];
            this.entities = this.filterReload();
        });
        $scope.$on('meurh_solicitacoesafastamentos_deleted', (): void => {
            this.busy = true;
            this.entities = [];
            this.entities = this.filterReload();
        });
    }

    montaListagemVisibilidade() {
      this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
      this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa!, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos() {
      this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
      this.filters.estabelecimento = '';
      this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
        delete this.filters.lotacao;
        this.$rootScope.$broadcast('lotacao_refresh', this.constructorEmpresaEstabelecimento());
        this.filterReload();
    }

    constructorEmpresaEstabelecimento() {
        return {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento
        };
    }

    search(): Array<IAfastamento> {
        if (this.entityService.filter !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.entityService.filter }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters));
        }

        const filter = {
            search: this.entityService.filter,
            filters: angular.copy(this.entityService.filters)
        };
        return this.entityService.search(filter);
    }

    loadMore(): void {
        this.entityService.loadMore();
    }

    filterReload(): Array<IAfastamento> {
        const constructors: any = { ...this.filters };

        if (!this.valorExiste(constructors.situacao)) {
            delete constructors.situacao;
        }

        if (!this.valorExiste(constructors.tipohistorico)) {
            delete constructors.tipohistorico;
        }

        if (!this.valorExiste(constructors.lotacao)) {
            delete constructors.lotacao;
        } else {
            constructors.lotacao = constructors.lotacao.lotacao;
        }

        if (
            this.dataInicial !== null && this.dataFinal !== null &&
            this.dataInicial !== '' && this.dataFinal !== '' &&
            this.dataInicial > this.dataFinal
        ) {
            this.NewToaster.pop({
                type: 'error',
                title: 'A data inicial não pode ser posterior a data final'
            });
            return [];
        }

        const created_at: { condition: string, value: string }[] = [];

        if (
            this.dataInicial === undefined ||
            (this.dataInicial !== null && this.dataInicial.length === 0)
        ) {
            this.dataInicial = null;
            constructors.datainicial = null;
        }

        if (this.dataInicial !== null) {
            created_at.push({
                condition: 'gte',
                value: this.dataInicial + ' 00:00:00'
            });
            delete constructors.datainicial;
        }

        if (
            this.dataFinal === undefined ||
            (this.dataFinal !== null && this.dataFinal.length === 0)
        ) {
            this.dataFinal = null;
            constructors.datafinal = null;
        }

        if (this.dataFinal !== null) {
            created_at.push({
                condition: 'lte',
                value: this.dataFinal + ' 23:59:00'
            });
            delete constructors.datafinal;
        }

        constructors.created_at = created_at;
        this.entityService.constructors = constructors;
        return this.entityService.reload();
    }

    isBusy(): boolean {
        return this.entityService.loadParams.busy;
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }
}
