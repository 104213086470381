import angular from 'angular';
import moment from 'moment';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';
import { RotatividadeService } from '../rotatividade.service';
import { ListagemService } from '../../../../shared/services/listagem.service';

type IRotatividadeIndexFilter = {
    empresa?: string;
    estabelecimento?: string;
    lotacao?: {
        lotacao: string;
    } | null;
};

export class RotatividadeIndexController {
    static $inject = [
        '$rootScope',
        '$state',
        '$stateParams',
        'NewToaster',
        'RotatividadeService',
        'ListagemService',
        'RelatoriosService',
    ];

    public busy = false;
    public trabalhadores: any;
    public carregandoRelatorio: boolean;
    public modo_selecao: 'competencia' | 'range';
    public competencia: any;
    public range = {
        datainicial: moment().startOf('month').format('YYYY-MM-DD'),
        datafinal: moment().endOf('month').format('YYYY-MM-DD')
    };
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public filters: IRotatividadeIndexFilter = {};
    public listagemPermissao: Array<string> = ['relatorios_rotatividade'];
    public permissaoGrupoEmpresarial = false;

    public orderbyparams: any = {};

    constructor(
        public $rootScope: angular.IRootScopeService & {
            nsjGlobals: any,
            temPermissao: (arg: string) => boolean,
            temPermissaoGrupoEmpresarial: (permissoes: string[], grupo: string) => boolean,
            session: any
        },
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public NewToaster: { pop: Function },
        public RotatividadeService: RotatividadeService,
        public ListagemService: ListagemService,
        public RelatoriosService: any,
    ) {
        if (!$rootScope.temPermissao('relatorios_rotatividade')) {
            $state.go('dp', $stateParams);
        }

        this.filters = {
            ...this.filters,
            empresa: $rootScope.session.entities.empresa.empresa,
            estabelecimento: $rootScope.session.entities.estabelecimento.estabelecimento
        };

        this.permissaoGrupoEmpresarial = $rootScope.temPermissaoGrupoEmpresarial(
            this.listagemPermissao,
            $rootScope.nsjGlobals.a.grupoempresarial
        );

        this.montaListagemVisibilidade();

        this.orderbyparams.current = 'nome';
        RotatividadeService.constructors.order = {
            'nome': 'ASC'
        };

    }

    isBusy() {
        return this.RotatividadeService.loadParams.busy;
    }

    finished() {
        return this.RotatividadeService.loadParams.finished;
    }

    loadMore() {
        this.RotatividadeService.loadMore();
    }

    carregarRelatorio() {
        this.trabalhadores = [];
        if (this.validarData()) {
            this.RotatividadeService.constructors = this.setConstructor();
            this.busy = true;
            this.trabalhadores = this.RotatividadeService.reload();
            this.busy = false;
        }
    }

    montaListagemVisibilidade() {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa!, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos() {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
        delete this.filters.lotacao;
        this.$rootScope.$broadcast('lotacao_refresh', this.constructorEmpresaEstabelecimento());
        this.carregarRelatorio();
    }

    constructorEmpresaEstabelecimento() {
        return {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento
        };
    }

    setConstructor(relatorio: boolean = false) {
        let dataConstructor = {};
        if (this.modo_selecao === 'competencia') {
            if (this.competencia.mes === '00') {
                const data = moment({ year: this.competencia.ano });
                dataConstructor = {
                    datainicial: data.startOf('year').format('YYYY-MM-DD'),
                    datafinal: data.endOf('year').format('YYYY-MM-DD')
                };
            } else {
                const data = moment({ year: this.competencia.ano, month: (this.competencia.mes - 1) });
                dataConstructor = {
                    datainicial: data.startOf('month').format('YYYY-MM-DD'),
                    datafinal: data.endOf('month').format('YYYY-MM-DD')
                };
            }
        } else {
            dataConstructor = {
                datainicial: angular.copy(this.range.datainicial),
                datafinal: angular.copy(this.range.datafinal)
            };
        }
        return {
            relatorio,
            empresa: this.filters.empresa,
            estabelecimento: this.filters.estabelecimento,
            ...(this.filters.lotacao && { lotacao: this.filters.lotacao.lotacao }),
            ...(this.orderbyparams.current && { order: { [this.orderbyparams.current]: 'ASC' } }),
            ...dataConstructor
        };
    }

    abrirRelatorio() {
        this.carregandoRelatorio = true;
        const construtor = this.setConstructor(true);
        let callback = () => {
            this.carregandoRelatorio = false;
        };
        this.RelatoriosService.abrirRelatorio('relatoriosliberados_rotatividade', construtor, callback);
    }

    validarData() {
        if (moment(this.range.datainicial) > moment(this.range.datafinal)) {
            return false;
        }
        if (!this.range.datainicial) {
            return false;
        }
        if (!this.range.datafinal) {
            return false;
        }
        return true;
    }

    ordenarLista(campoOrdenacao: any) {
        this.orderbyparams.current = campoOrdenacao;
        this.carregarRelatorio();
    }
}
