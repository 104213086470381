import angular from 'angular';
import moment from 'moment';
import { PlanosModalService } from '../modal/planos/planos.modal.service';
import { CancelamentoModalService } from '../modal/cancelamento/cancelamento.modal.service';
import { MeurhBeneficiosService } from '../beneficios.service';
import { SituacaoBeneficioEnum, TipoBeneficios, TipoBeneficiosEnum } from '../beneficios.enum';
import { TipoSolicitacaoEnum } from '../../../../../shared/enums/TipoSolicitacaoEnum';
import {
    IBeneficiario,
    IBeneficioTrabalhador,
    IBeneficioTrabalhadorDetalhado,
    ITrabalhadorDependente
} from '../model/beneficios.model';
import { IInclusaoBeneficio } from '../model/inclusao.model';
import { ICancelamentoBeneficio } from '../model/cancelamento.model';

type ITiposDeBeneficios = {
    [key in TipoBeneficiosEnum]: {
        solicitacoes: ((IInclusaoBeneficio | ICancelamentoBeneficio) & IBeneficiario)[],
        beneficiostrabalhador: IBeneficioTrabalhadorDetalhado[]
    }
};

export class MeurhBeneficiosIndexController {

    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        'PlanosModalService',
        'CancelamentoModalService',
        'MeurhBeneficiosService'
    ];

    public busy = false;
    public loading = false;
    public colaborador: string;
    public situacaoBeneficioEnum = SituacaoBeneficioEnum;
    public tipoSolicitacaoEnum = TipoSolicitacaoEnum;
    public tiposDeBeneficiosConst = TipoBeneficios;
    public tiposDeBeneficiosDisponiveis: TipoBeneficiosEnum[] = [];
    public tiposDeBeneficiosKeys: TipoBeneficiosEnum[] = [];
    public tiposDeBeneficios = this.setTiposBeneficios();
    public switchAtivos = true;
    public filterAtivos: {} = {
        situacaoBeneficio: SituacaoBeneficioEnum.ATIVO
    };
    public inclusaoBtnVisibility = {
        visible: false,
        items: {} as { [key in TipoBeneficiosEnum]: boolean }
    };

    constructor(
        public $rootScope: angular.IRootScopeService & {
            liberadoTela: (arg: string) => boolean,
            session: any
        },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public PlanosModalService: PlanosModalService,
        public CancelamentoModalService: CancelamentoModalService,
        public MeurhBeneficiosService: MeurhBeneficiosService
    ) {}

    $onInit() {
        this.loading = true;
        const reqBeneficiosTrabalhador = this.MeurhBeneficiosService.getBeneficiosTrabalhador(this.colaborador);
        const reqInclusoesBeneficio = this.MeurhBeneficiosService.getInclusoesBeneficio(this.colaborador, 0);
        const reqCancelamentoBeneficio = this.MeurhBeneficiosService.getCancelamentosBeneficio(this.colaborador, 0);
        const reqTiposDeBeneficios = this.MeurhBeneficiosService.getTiposBeneficios(this.$rootScope.session.entities.empresa.empresa);
        Promise.all([
            reqBeneficiosTrabalhador,
            reqInclusoesBeneficio,
            reqCancelamentoBeneficio,
            reqTiposDeBeneficios
        ]).then((values) => {
            this.loading = false;
            this.setTiposDeBeneficios(values[0], values[1], values[2]);
            this.tiposDeBeneficiosDisponiveis = values[3];
            this.inclusaoBtnVisibility = this.setInclusaoBtnVisible(values[3]);
            this.$scope.$applyAsync();
        });
    }

    openPlanosModal(beneficioTrabalhador: IBeneficioTrabalhadorDetalhado) {
        const modal = this.PlanosModalService.open({
            beneficio: beneficioTrabalhador.beneficio,
            tipoBeneficio: beneficioTrabalhador.agrupador,
            editable: false
        });
        modal.result.then((response) => {/**/}).catch(() => {/**/});
    }

    openHistorico(beneficioTrabalhador: IBeneficioTrabalhadorDetalhado) {
        this.$rootScope.$broadcast('beneficios_historico_open', { beneficioTrabalhador });
    }

    openCancelamentoModal(beneficioTrabalhador: IBeneficioTrabalhadorDetalhado) {
        const modal = this.CancelamentoModalService.open(beneficioTrabalhador);
        modal.result.then((response) => {/**/}).catch(() => {/**/});
    }

    setTiposDeBeneficios(
        beneficiosTrabalhador: IBeneficioTrabalhador[],
        inclusoesBeneficio: IInclusaoBeneficio[],
        cancelamentosBeneficio: ICancelamentoBeneficio[]
    ) {
        const keyBeneficiosTrabalhadores = this.setBeneficiosTrabalhadores(beneficiosTrabalhador);
        const keyInclusoesBeneficio = this.setSolicitacoes(inclusoesBeneficio);
        const keyCancelamentosBeneficio = this.setSolicitacoes(cancelamentosBeneficio);
        const keys = new Set([
            ...keyBeneficiosTrabalhadores,
            ...keyInclusoesBeneficio,
            ...keyCancelamentosBeneficio
        ]);
        this.tiposDeBeneficiosKeys = Array.from(keys);
    }

    setBeneficiosTrabalhadores(entities: IBeneficioTrabalhador[]) {
        const keys = new Set<TipoBeneficiosEnum>();
        entities.forEach((entity) => {
            const entityCopy = {
                ...entity,
                ...this.setBeneficiario(entity),
                ...this.setSituacaoBeneficio(entity)
            } as IBeneficioTrabalhadorDetalhado;

            if (entityCopy.situacaoBeneficio && TipoBeneficiosEnum[entityCopy.agrupador]) {
                this.tiposDeBeneficios[entityCopy.agrupador].beneficiostrabalhador.push(entityCopy);
                keys.add(entityCopy.agrupador);
            }
        });
        return Array.from(keys);
    }

    setSolicitacoes(entities: IInclusaoBeneficio[] | ICancelamentoBeneficio[]) {
        const keys = new Set<TipoBeneficiosEnum>();
        entities.forEach((entity: IInclusaoBeneficio | ICancelamentoBeneficio) => {
            const entityCopy = {
                ...entity,
                ...this.setBeneficiario(entity)
            } as (IInclusaoBeneficio | ICancelamentoBeneficio) & IBeneficiario;

            if (TipoBeneficiosEnum[entityCopy.agrupador]) {
                this.tiposDeBeneficios[entityCopy.agrupador].solicitacoes.push(entityCopy);
                keys.add(entityCopy.agrupador);
            }
        });
        return Array.from(keys);
    }

    setSituacaoBeneficio(beneficiotrabalhador: IBeneficioTrabalhador) {
        const currentDate = moment();
        const dataAdesao = moment(beneficiotrabalhador.dataadesao, 'YYYY-MM-DD');

        if (currentDate.isBefore(dataAdesao)) {
            return { situacaoBeneficio: SituacaoBeneficioEnum.INATIVO };
        }

        if (beneficiotrabalhador.ultima_suspensao) {
            const inicioSuspensao = moment(beneficiotrabalhador.ultima_suspensao.datainicio, 'YYYY-MM-DD');
            const fimSuspensao = moment(beneficiotrabalhador.ultima_suspensao.datafim, 'YYYY-MM-DD');
            if (currentDate.isBetween(inicioSuspensao, fimSuspensao, undefined, '[]')) {
                return { situacaoBeneficio: SituacaoBeneficioEnum.SUSPENSO };
            }
        }

        if (beneficiotrabalhador.ultima_adesao) {
            const ultimaDataAdesao = moment(beneficiotrabalhador.ultima_adesao.dataadesao, 'YYYY-MM-DD');
            if (currentDate.isBefore(ultimaDataAdesao)) {
                return { situacaoBeneficio: SituacaoBeneficioEnum.INATIVO };
            }
            if (beneficiotrabalhador.ultima_adesao.dataexclusao) {
                const ultimaDataExclusao = moment(beneficiotrabalhador.ultima_adesao.dataexclusao, 'YYYY-MM-DD');
                if (currentDate.isBetween(ultimaDataAdesao, ultimaDataExclusao, undefined, '[]')) {
                    return { situacaoBeneficio: SituacaoBeneficioEnum.ATIVO };
                } else {
                    return { situacaoBeneficio: SituacaoBeneficioEnum.CANCELADO };
                }
            }
            return { situacaoBeneficio: SituacaoBeneficioEnum.ATIVO };
        }

        if (beneficiotrabalhador.dataexclusao) {
            const dataExclusao = moment(beneficiotrabalhador.dataexclusao, 'YYYY-MM-DD');
            if (currentDate.isBetween(dataAdesao, dataExclusao, undefined, '[]')) {
                return { situacaoBeneficio: SituacaoBeneficioEnum.ATIVO };
            } else {
                return { situacaoBeneficio: SituacaoBeneficioEnum.CANCELADO };
            }
        }

        return { situacaoBeneficio: SituacaoBeneficioEnum.ATIVO };
    }

    setBeneficiario(entity: ITrabalhadorDependente) {
        return {
            beneficiario: entity.dependentetrabalhador?.dependentetrabalhador ?
            {
                ...entity.dependentetrabalhador,
                beneficiario: entity.dependentetrabalhador.dependentetrabalhador
            } : {
                ...entity.trabalhador,
                beneficiario: entity.trabalhador.trabalhador
            }
        };
    }

    setTiposBeneficios() {
        const obj = {} as ITiposDeBeneficios;
        Object.values(TipoBeneficiosEnum)
            .filter((v) => !isNaN(Number(v)))
            .map(key => {
                obj[key] = {
                    solicitacoes: [],
                    beneficiostrabalhador: []
                };
            });
        return obj;
    }

    hasSolicitacaoCancelamento(beneficiario: IBeneficiario['beneficiario'], keyTipoBeneficio: TipoBeneficiosEnum) {
        const hasSolicitacao: boolean = this.tiposDeBeneficios[keyTipoBeneficio]
            .solicitacoes.some((solicitacao) => {
                return solicitacao.beneficiario.beneficiario === beneficiario.beneficiario &&
                    solicitacao.tiposolicitacao === TipoSolicitacaoEnum.CANCELAMENTO_BENEFICIO;
            });
        return hasSolicitacao;
    }

    setInclusaoBtnVisible(tipoBeneficiosDisponiveis: TipoBeneficiosEnum[]) {
        const obj = {
            visible: false,
            items: {} as { [key in TipoBeneficiosEnum]: boolean }
        };
        tipoBeneficiosDisponiveis.forEach(elem => {
            obj.items[elem] = !this.tiposDeBeneficios[elem]
                .solicitacoes.some((solicitacao) => {
                    return solicitacao.tiposolicitacao === this.tipoSolicitacaoEnum.INCLUSAO_BENEFICIO;
                });
            if (obj.items[elem]) {
                obj.visible = obj.items[elem];
            }
        });
        return obj;
    }

    toggleFilterAtivos(checked: boolean) {
        if (checked) {
            this.filterAtivos['situacaoBeneficio'] = SituacaoBeneficioEnum.ATIVO;
        } else {
            this.filterAtivos = {};
        }
    }
}
