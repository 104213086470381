import angular from 'angular';
import { MeurhBeneficiosIndexController } from './index/beneficios.index.controller';

export class MeurhBeneficiosComponent implements angular.IComponentOptions {
	static selector = 'meurhBeneficiosComponent';
	static controller = MeurhBeneficiosIndexController;
	static template = require('!!html-loader!./index/beneficios.index.html');
	static transclude = true;
	static bindings = {
        colaborador: '<'
	};
}
