import * as angular from 'angular';
import { MeurhSolicitacoesalteracoesdadossalariaisService } from './solicitacoesalteracoesdadossalariais.service';

export const MeurhSolicitacoesalteracoesdadossalariaisListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {

    let resolve = {
        'entity': [
            'MeurhSolicitacoesalteracoesdadossalariaisService',
            '$stateParams',
            '$state',
            '$q',
            function (
                entityService: MeurhSolicitacoesalteracoesdadossalariaisService,
                $stateParams: angular.ui.IStateParamsService,
                $state: angular.ui.IStateService,
                $q: angular.IQService
            ): angular.ui.IStateParamsService | angular.IQService | {} {

                if ($stateParams['entity']) {
                    return $stateParams['entity'];
                } else {
                    if ($stateParams['solicitacao']) {
                        return $q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
                            entityService.get($stateParams['solicitacao'])
                                .then((data: any) => {
                                    resolve(data);
                                })
                                .catch((error: any) => {
                                    if (error.status === 404) {
                                        if ($state.href('meurh_solicitacoesalteracoestiporecebimento_not_found', $stateParams)) {
                                            $state.go('meurh_solicitacoesalteracoestiporecebimento_not_found', $stateParams);
                                        } else {
                                            $state.go('not_found', $stateParams);
                                        }
                                    }
                                });
                        });
                    } else {
                        return {};
                    }
                }
            }
        ]
    };

    $stateProvider
        .state('meurh_solicitacoesalteracoestiporecebimento', {
            url: '/solicitacoes/alteracaorecebimento?q?tipo',
            template: require('html-loader!./index/solicitacoesalteracoesdadossalariais.index.html'),
            controller: 'Meurh\SolicitacoesalteracoesdadossalariaisIndexController',
            controllerAs: 'mrh_alteracoesdadossalariais_lst_cntrllr',
            reloadOnSearch: false
        })
        .state('meurh_solicitacoesalteracoestiporecebimento_new', {
            parent: 'meurh_solicitacoesalteracoestiporecebimento',
            url: '/new',
            resolve: resolve,
            template: require('html-loader!./new/solicitacoesalteracoesdadossalariais.new.html'),
            controller: 'MeurhSolicitacoesalteracoesdadossalariaisNewController',
            controllerAs: 'mrh_alteracoesdadossalariais_frm_nw_cntrllr'
        })
        .state('meurh_solicitacoesalteracoestiporecebimento_show', {
            parent: 'meurh_solicitacoesalteracoestiporecebimento',
            url: '/:solicitacao?path',
            resolve: resolve,
            template: require('html-loader!./show/solicitacoesalteracoesdadossalariais.show.html'),
            controller: 'MeurhSolicitacoesalteracoesdadossalariaisShowController',
            controllerAs: 'mrh_alteracoesdadossalariais_frm_shw_cntrllr'
        });
    }
];
