export enum TipoBeneficiosEnum {
    ALIMENTACAO = 1,
    REFEICAO,
    COMBUSTIVEL,
    SAUDE,
    ODONTOLOGICO,
    CRECHE,
    CULTURA,
    PRESENTE,
    OUTROS,
    TRANSPORTE,
    SEGURODEVIDA,
    FLEXIVEL
}

export const TipoBeneficios = {
    [TipoBeneficiosEnum.ALIMENTACAO]: {
        nome: 'Vale-alimentação',
        agrupador: TipoBeneficiosEnum.ALIMENTACAO
    },
    [TipoBeneficiosEnum.REFEICAO]: {
        nome: 'Vale-refeição',
        agrupador: TipoBeneficiosEnum.REFEICAO
    },
    [TipoBeneficiosEnum.COMBUSTIVEL]: {
        nome: 'Combustível',
        agrupador: TipoBeneficiosEnum.COMBUSTIVEL
    },
    [TipoBeneficiosEnum.SAUDE]: {
        nome: 'Plano de saúde',
        agrupador: TipoBeneficiosEnum.SAUDE
    },
    [TipoBeneficiosEnum.ODONTOLOGICO]: {
        nome: 'Plano odontológico',
        agrupador: TipoBeneficiosEnum.ODONTOLOGICO
    },
    [TipoBeneficiosEnum.CRECHE]: {
        nome: 'Auxílio creche',
        agrupador: TipoBeneficiosEnum.CRECHE
    },
    [TipoBeneficiosEnum.CULTURA]: {
        nome: 'Vale-cultura',
        agrupador: TipoBeneficiosEnum.CULTURA
    },
    [TipoBeneficiosEnum.PRESENTE]: {
        nome: 'Vale-presente',
        agrupador: TipoBeneficiosEnum.PRESENTE
    },
    [TipoBeneficiosEnum.OUTROS]: {
        nome: 'Outros',
        agrupador: TipoBeneficiosEnum.OUTROS
    },
    [TipoBeneficiosEnum.TRANSPORTE]: {
        nome: 'Vale-transporte',
        agrupador: TipoBeneficiosEnum.TRANSPORTE
    },
    [TipoBeneficiosEnum.SEGURODEVIDA]: {
        nome: 'Seguro de vida',
        agrupador: TipoBeneficiosEnum.SEGURODEVIDA
    },
    [TipoBeneficiosEnum.FLEXIVEL]: {
        nome: 'Flexível',
        agrupador: TipoBeneficiosEnum.FLEXIVEL
    },
};

export enum SituacaoBeneficioEnum {
    INATIVO,
    ATIVO,
    CANCELADO,
    SUSPENSO,
    REATIVADO
}
