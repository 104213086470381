import angular from 'angular';
import moment from 'moment';
import { IAlteracaoDadosSalariais } from '../models/alteracaodadossalariais.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';
import { MeurhSolicitacoesalteracoesdadossalariaisService } from '../solicitacoesalteracoesdadossalariais.service';
import { ListagemService } from '../../../../shared/services/listagem.service';

type IMeurhSolicitacoesalteracoesdadossalariaisIndexFilter = {
    empresa?: string;
    estabelecimento?: string;
    lotacao?: {
        lotacao: string;
    } | null;
};

export class MeurhSolicitacoesalteracoesdadossalariaisIndexController implements angular.IController {
    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        '$location',
        'NewToaster',
        'MeurhSolicitacoesalteracoesdadossalariaisService',
        'ListagemService'
    ];

    public entities: Array<IAlteracaoDadosSalariais>;
    public busy: boolean = false;
    public filters: IMeurhSolicitacoesalteracoesdadossalariaisIndexFilter = {};
    public dataInicial: string | null = '';
    public dataFinal: string | null = '';
    public dataAtual = moment();

    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['visualizar_alteracaotiporecebimento'];
    public permissaoGrupoEmpresarial = false;

    constructor(
        public $rootScope: angular.IRootScopeService & {
            temPermissao: (arg: string) => boolean,
            temPermissaoGrupoEmpresarial: (permissoes: string[], grupo: string) => boolean,
            session: any,
            nsjGlobals: any
        },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
        public NewToaster: { pop: Function },
        public entityService: MeurhSolicitacoesalteracoesdadossalariaisService,
        public ListagemService: ListagemService
    ) {
        if (!$rootScope.temPermissao('visualizar_alteracaotiporecebimento')) {
            $state.go('dp', $stateParams);
        }

        entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
        entityService.filters = {};
        entityService.constructors = {};

        this.permissaoGrupoEmpresarial = $rootScope.temPermissaoGrupoEmpresarial(
            this.listagemPermissao,
            $rootScope.nsjGlobals.a.grupoempresarial
        );

        this.filters = {
            ...this.filters,
            empresa: $rootScope.session.entities.empresa.empresa,
            estabelecimento: $rootScope.session.entities.estabelecimento.estabelecimento,
        };

        this.montaListagemVisibilidade();
        this.filterReload();

        $scope.$on('meurh_solicitacoesalteracoestiporecebimento_deleted', () => {
            this.reload();
        });

        $scope.$on('$destroy', () => {
            if (entityService.loading_deferred) {
                entityService.loading_deferred.resolve();
            }
        });

        $rootScope.$on('meurh_solicitacoesalteracoestiporecebimento_submitted', () => {
            this.reload();
        });
    }

    montaListagemVisibilidade() {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa!, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos() {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
        delete this.filters.lotacao;
        this.$rootScope.$broadcast('lotacao_refresh', this.constructorEmpresaEstabelecimento());
        this.filterReload();
    }

    constructorEmpresaEstabelecimento() {
        return {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento
        };
    }

    search(): Array<IAlteracaoDadosSalariais> {
        if (this.entityService.filter !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.entityService.filter }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters));
        }

        const filter = {
            search: this.entityService.filter,
            filters: angular.copy(this.entityService.filters)
        };
        return this.entityService.search(filter);
    }

    loadMore(): void {
        this.entityService.loadMore();
    }

    finished(): boolean {
        return this.entityService.loadParams.finished;
    }

    reload(): void {
        this.entities = this.entityService.reload();
    }

    filterReload(): void {
        const constructors: any = { ...this.filters };

        if (!this.valorExiste(constructors.lotacao)) {
            delete constructors.lotacao;
        } else {
            constructors.lotacao = constructors.lotacao.lotacao;
        }

        if (
            this.dataInicial !== null && this.dataFinal !== null &&
            this.dataInicial !== '' && this.dataFinal !== '' &&
            this.dataInicial > this.dataFinal
        ) {
            this.NewToaster.pop({
                type: 'error',
                title: 'A data inicial não pode ser posterior a data final'
            });
            return;
        }

        const created_at: { condition: string, value: string }[] = [];

        if (
            this.dataInicial === undefined ||
            (this.dataInicial !== null && this.dataInicial.length === 0)
        ) {
            this.dataInicial = null;
            constructors.datainicial = null;
        }

        if (this.dataInicial !== null) {
            created_at.push({
                condition: 'gte',
                value: this.dataInicial + ' 00:00:00'
            });
            delete constructors.datainicial;
        }

        if (
            this.dataFinal === undefined ||
            (this.dataFinal !== null && this.dataFinal.length === 0)
        ) {
            this.dataFinal = null;
            constructors.datafinal = null;
        }

        if (this.dataFinal !== null) {
            created_at.push({
                condition: 'lte',
                value: this.dataFinal + ' 23:59:00'
            });
            delete constructors.datafinal;
        }

        constructors.created_at = created_at;
        this.entityService.constructors = constructors;
        this.reload();
    }

    isBusy() {
        return this.entityService.loadParams.busy;
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }
}
