import angular from 'angular';
import { TipoBeneficios } from '../../beneficios.enum';
import { TipoSolicitacaoEnum } from '../../../../../../shared/enums/TipoSolicitacaoEnum';
import { MeurhBeneficiosService } from '../../beneficios.service';
import { TiposDocumentosRequeridosService } from '../../../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { IBeneficioTrabalhadorDetalhado } from '../../model/beneficios.model';
import { IAnexo } from '../../../../../Meurh/Anexos/models/anexo.model';
import { ICancelamentoBeneficioGet, ICancelamentoBeneficioSave } from '../../model/cancelamento.model';

export class CancelamentoModalController {
    static $inject = [
        '$uibModalInstance',
        '$rootScope',
        '$scope',
        '$state',
        'entity',
        'MeurhBeneficiosService',
        'TiposDocumentosRequeridosService',
        'NewToaster'
    ];

    public form: angular.IFormController;
    public entityForm: {
        dataexclusao: Date | string,
        observacao: string
    };
    public tipoBeneficios = TipoBeneficios;
    public busy = false;

    public formAnexo: angular.IFormController;
    public entityAnexo: IAnexo = {
		casodeuso: 'CANCELAMENTOBENEFICIO'
	};
    public tiposdocumentosrequeridos: any[];
    public busyTipoDocumentos = false;

    constructor(
        public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        public $rootScope: angular.IRootScopeService & { session: any },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public entity: IBeneficioTrabalhadorDetalhado,
        public MeurhBeneficiosService: MeurhBeneficiosService,
        public TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        public NewToaster: { pop: Function }
    ) {
        this.carregaTiposDocumentosRequeridos();

        $scope.$on('meurh_solicitacoescancelamentobeneficios_create_loaded', (event, args: ICancelamentoBeneficioGet) => {
			if (this.tiposdocumentosrequeridos.length <= 0) {
				this.NewToaster.pop({
                    type: 'success',
                    title: 'Cancelamento de benefício foi criado',
                    body: 'O cancelamento de benefício foi criado com sucesso.'
                });
				this.$state.go('meurh_beneficios_show_cancelamento', {
                    'solicitacao': args.solicitacao
                });
                this.$uibModalInstance.close();
                this.busy = false;
			} else {
                this.entityAnexo['solicitacao'] = args.solicitacao;
			    this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});

				this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
					this.$scope.$apply(); // para atualizar os valores dos validateResult vindos do componente anexo
					if (this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => tipodocumentorequerido.validateResult)) {
						this.NewToaster.pop({
                            type: 'success',
                            title: 'Cancelamento de benefício foi criado',
                            body: 'O cancelamento de benefício foi criado com sucesso.'
                        });
						this.$state.go('meurh_beneficios_show_cancelamento', {
                            'solicitacao': args.solicitacao
                        });
                        this.$uibModalInstance.close();
                        this.busy = false;
					}
				});
			}
        });

        $scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
			this.busyTipoDocumentos = false;
			this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
				tipodocumentorequerido.validateResult = false;
				tipodocumentorequerido.fileSelecionado = false;
				return tipodocumentorequerido;
			});
		});
    }

    submit() {
        this.form.$submitted = true;

        if (this.form.$invalid) {
            return;
        }

        const docsValid = this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
            return (
                tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio ||
                tipodocumentorequerido.fileSelecionado
            );
        });
        if (!docsValid) {
            this.NewToaster.pop({
                type: 'error',
                title: 'Há documento(s) obrigatório(s) não informado(s)'
            });
            return;
        }

        this.busy = true;
        this.MeurhBeneficiosService.saveCancelamentoBeneficio(this.setRequestBody());
    }

    close() {
        this.$uibModalInstance.dismiss('fechar');
    }

    setRequestBody(): ICancelamentoBeneficioSave {
        return {
            trabalhador: this.entity.trabalhador.trabalhador,
            situacao: 0,
            estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento,
            beneficio: this.entity.beneficio.beneficio,
            dataexclusao: this.entityForm.dataexclusao,
            observacao: this.entityForm.observacao,
            beneficiotrabalhador: this.entity.beneficiotrabalhador
        };
    }

    carregaTiposDocumentosRequeridos() {
        this.busyTipoDocumentos = true;
        this.resetTiposDocumentosRequeridosService();
        this.TiposDocumentosRequeridosService.constructors = {
            tiposolicitacao: TipoSolicitacaoEnum.CANCELAMENTO_BENEFICIO
        };
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();
    }

	resetTiposDocumentosRequeridosService() {
		this.tiposdocumentosrequeridos = [];
		this.TiposDocumentosRequeridosService.entities = [];
		this.TiposDocumentosRequeridosService.loadParams = {
			to_load: 3,
			busy: false,
			finished: false
		};
		this.TiposDocumentosRequeridosService.after = {};
	}
}
