import angular from 'angular';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { MeurhBeneficiosService } from './beneficios.service';
import { IInclusaoBeneficioGet } from './model/inclusao.model';
import { ICancelamentoBeneficioGet } from './model/cancelamento.model';

export const MeurhBeneficiosListRouting = [
	'$stateProvider',
	function ($stateProvider: angular.ui.IStateProvider) {
        const resolve = {
            'entity': [
                'MeurhBeneficiosService',
                '$rootScope',
                '$stateParams',
                '$state',
                '$q',
                function (
                    entityService: MeurhBeneficiosService,
                    $rootScope: angular.IRootScopeService & {
                        liberadoTela: (arg: string) => boolean,
                    },
                    $stateParams: angular.ui.IStateParamsService,
                    $state: angular.ui.IStateService,
                    $q: angular.IQService
                ) {
                    if (!$rootScope.liberadoTela('LIBERAR_VISUALIZACAO_BENEFICIO')) {
                        $state.go('dp');
                    }

                    if (!$stateParams['solicitacao']) {
                        $state.go('not_found', $stateParams);
                    }

                    return $q((resolve: angular.IQResolveReject<IInclusaoBeneficioGet | ICancelamentoBeneficioGet>, reject) => {
                        if ($stateParams['tiposolicitacao'] === TipoSolicitacaoEnum.INCLUSAO_BENEFICIO) {
                            entityService.getInclusaoBeneficio($stateParams['solicitacao'])
                                .then((data) => {
                                    resolve(data);
                                })
                                .catch((error: any) => {
                                    if (error.status === 404) {
                                        $state.go('not_found', $stateParams);
                                    }
                                });
                        } else if ($stateParams['tiposolicitacao'] === TipoSolicitacaoEnum.CANCELAMENTO_BENEFICIO) {
                            entityService.getCancelamentoBeneficio($stateParams['solicitacao'])
                                .then((data) => {
                                    resolve(data);
                                })
                                .catch((error: any) => {
                                    if (error.status === 404) {
                                        $state.go('not_found', $stateParams);
                                    }
                            });
                        } else {
                            $state.go('not_found', $stateParams);
                        }
                    });
                }
            ]
        };

		$stateProvider
            .state('meurh_beneficios_new', {
                url: '/colaboradores/{colaborador}/beneficios/{tipobeneficio}/new',
                template: require('html-loader!./new/beneficios.new.html'),
                controller: 'MeurhBeneficiosNewController',
                controllerAs: 'mrh_bnfcs_nw_cntrllr'
            })
			.state('meurh_beneficios_show_inclusao', {
				url: '/colaboradores/beneficios/inclusao/show/{solicitacao}?path',
				template: require('html-loader!./show/beneficios.show.html'),
                params: {
                    tiposolicitacao: TipoSolicitacaoEnum.INCLUSAO_BENEFICIO
                },
                resolve,
				controller: 'MeurhBeneficiosShowController',
				controllerAs: 'mrh_bnfcs_shw_cntrllr'
			})
            .state('meurh_beneficios_show_cancelamento', {
				url: '/colaboradores/beneficios/cancelamento/show/{solicitacao}?path',
				template: require('html-loader!./show/beneficios.show.html'),
                params: {
                    tiposolicitacao: TipoSolicitacaoEnum.CANCELAMENTO_BENEFICIO
                },
                resolve,
				controller: 'MeurhBeneficiosShowController',
				controllerAs: 'mrh_bnfcs_shw_cntrllr'
			});
	}
];
