
import angular from 'angular';
import moment from 'moment';
import { IVtadicional } from '../models/vtadicional.model';
import { MeurhSolicitacoesvtsadicionaisService } from '../solicitacoesvtsadicionais.service';

export class MeurhSolicitacoesvtsadicionaisNewController implements angular.IController {
	static $inject = [
        '$scope',
        '$stateParams',
        '$state',
        'MeurhSolicitacoesvtsadicionaisService',
        'entity',
        'NewToaster',
        'ModalConfirmService',
        '$q',
        '$transitions'
    ];

    public constructors: any = {};
    public action = 'insert';
    public tipo = this.$stateParams['tipo'];
	public form: angular.IFormController;
    public allowRefresh: boolean = false;
    public formchanged: boolean = false;

    constructor(
		public $scope: angular.IScope,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IStateService,
        public entityService: MeurhSolicitacoesvtsadicionaisService,
        public entity: IVtadicional,
        public NewToaster: any,
        public ModalConfirmService: any,
        public $q: any,
        public $transitions: any) {

        this.entity.datalimiteinicial = this.entity.datalimitefinal = new Date();
        this.entity.datalimiteinicial = moment().format('YYYY-MM-DD');
        this.entity.tipoconteudo = 0;
        this.entity.contentType = 'currency';

        $scope.$watch('mrh_slctcsvtsdcns_frm_nw_cntrllr.entity.tipoconteudo', (newValue: any, oldValue: any) => {
            if (newValue !== oldValue) {
                this.entity.contentType = newValue === 1 ? 'currency' : 'number';
                this.entity.tipoconteudo = newValue;
            }
        }, true);

        this.$scope.$watch('mrh_slctcsvtsdcns_frm_nw_cntrllr.entity.trabalhador', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                this.formchanged = true;
            }
        }, true);

        document.querySelector('form')?.addEventListener('change', (e) => {
            this.formchanged = true;
        });

        this.onSubmitSuccess();
        this.onSubmitError();
        this.exitScreenDescartarModal();
        document.onkeydown = this.refreshDescartarModal;
        this.allowRefresh = true;
    }

    submit(): void {
        let keyRemovida = null;
        angular.forEach(this.form.$$controls, (field: any, key: any) => {
            if (field.$validators.pattern !== undefined && field.$name === 'conteudo' && this.entity.tipoconteudo === 1 && field.$valid === false) {
                keyRemovida = key;
            }
            if (field.$validators.pattern === undefined && field.$name === 'conteudo' && this.entity.tipoconteudo === 0 && field.$valid === false) {
                keyRemovida = key;
            }
        });
        if (keyRemovida !== null) {
            this.form.$removeControl(this.form.$$controls[keyRemovida]);
        }
        angular.forEach(this.form.$$controls, (field: any) => {
            field.$validate();
        });
        if (this.form.$valid && !this.entity.$$__submitting && this.entity.conteudo > 0) {
            this.allowRefresh = false;
            this.entityService.save(this.entity, {
                empresa: this.entity.trabalhador.empresaobj.empresa,
                estabelecimento: this.entity.trabalhador.estabelecimentoobj.estabelecimento
            });
        } else {
            if (this.entity.conteudo <= 0) {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'O campo de conteúdo deve ser maior do que 0'
                });
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Alguns campos do formulário apresentam erros.'
                });
            }
        }
    }

    onSubmitSuccess() {
        this.$scope.$on('meurh_solicitacoesvtsadicionais_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A Solicitação de VT Adicional foi enviada com sucesso!'
            });
            this.$state.go('meurh_solicitacoesvtsadicionais_show', {
                solicitacao: args.entity.solicitacao
            });
        });
    }

    onSubmitError() {
        this.$scope.$on('meurh_solicitacoesvtsadicionais_submit_error', (event: angular.IAngularEvent, args: any): void => {
            if (args.response.status === 409) {
                if (confirm(args.response.data.message)) {
                    this.entity[''] = args.response.data.entity[''];
                    this.entityService.save(this.entity, {
                        empresa: this.entity.trabalhador.empresaobj.empresa,
                        estabelecimento: this.entity.trabalhador.estabelecimentoobj.estabelecimento
                    });
                }
            } else {
                if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                    if (args.response.data.message === 'Validation Failed') {
                        let message = '';
                        let children = args.response.data.errors.children;
                        for (let child in children) {
                            if (children[child].errors !== undefined) {
                                for (let error in children[child].errors) {
									if (children[child].errors.hasOwnProperty(error)) {
										message += '<li>' + children[child].errors[error] + '</li>';
									}
                                }
                            }
                        }
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Erro de Validação',
                            body: 'Os seguintes itens precisam ser alterados: <ul>' + message + '</ul>',
                            bodyOutputType: 'trustedHtml'
                        });
                    } else {
                        this.NewToaster.pop({
                            type: 'error',
                            title: args.response.data.message
                        });
                    }
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Ocorreu um erro ao enviar a Solicitação de VT Adicional!'
                    });
                }
            }
        });
    }

    buttonDescartarModal() {
        if (this.formchanged) {
            let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', false);
            confirm.result.then((): void => {
                this.allowRefresh = false;
                this.$state.go('meurh_solicitacoesvtsadicionais', angular.extend(this.entityService.constructors));
            })
            .catch((error: any): void => {/* */ });
        } else {
            this.$state.go('meurh_solicitacoesvtsadicionais', angular.extend(this.entityService.constructors));
        }
    }

    refreshDescartarModal = (e: any) => {
        e = e || window.event;
        if (this.formchanged && this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                })
                .catch((error: any): void => {/* */ });
            }
        }
    }

    exitScreenDescartarModal() {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (trans._targetState._identifier !== 'meurh_solicitacoesvtsadicionais_new' && this.allowRefresh && this.formchanged) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                })
                .catch(function () { /**/ });
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }
}
