import angular from 'angular';
import { MeurhSolicitacoesadiantamentosavulsosService } from './solicitacoesadiantamentosavulsos.service';

export const AdiantamentosAvulsosRouting = ['$stateProvider', function ($stateProvider: angular.ui.IStateProvider) {
  var resolve = {
    'entity': ['MeurhSolicitacoesadiantamentosavulsosService', '$stateParams', '$state', '$q', function (entityService: MeurhSolicitacoesadiantamentosavulsosService, $stateParams: angular.ui.IStateParamsService,
      $state: angular.ui.IStateService,
      $q: angular.IQService) {
      if ($stateParams['entity']) {
        return $stateParams['entity'];
      } else {
        if ($stateParams['solicitacao']) {
          return $q((resolve: any) => {
            entityService.get($stateParams['solicitacao'])
              .then((data: any) => {
                resolve(data);
              })
              .catch((error: any) => {
                if (error.status === 404) {
                  if ($state.href('meurh_solicitacoesadiantamentosavulsos_not_found', $stateParams)) {
                    $state.go('meurh_solicitacoesadiantamentosavulsos_not_found', $stateParams);
                  } else {
                    $state.go('not_found', $stateParams);
                  }
                }
              });
          });
        } else {
          return {};
        }
      }
    }]
  };

  $stateProvider
    .state('meurh_solicitacoesadiantamentosavulsos', {
      url: '/solicitacoes/adiantamentos-avulsos?q',
      template: require('html-loader!./index/solicitacoesadiantamentosavulsos.index.html'),
      controller: 'Meurh\SolicitacoesadiantamentosavulsosListController',
      controllerAs: 'mrh_slctcsdntmntsvlss_lst_cntrllr',
      reloadOnSearch: false
    })
    .state('meurh_solicitacoesadiantamentosavulsos_new', {
      parent: 'meurh_solicitacoesadiantamentosavulsos',
      url: '/new',
      resolve: resolve,
      template: require('html-loader!./new/solicitacoesadiantamentosavulsos.new.html'),
      controller: 'MeurhSolicitacoesadiantamentosavulsosFormNewController',
      controllerAs: 'mrh_slctcsdntmntsvlss_frm_nw_cntrllr'
    })
    .state('meurh_solicitacoesadiantamentosavulsos_show', {
      parent: 'meurh_solicitacoesadiantamentosavulsos',
      url: '/:solicitacao?path',
      resolve: resolve,
      template: require('html-loader!./show/solicitacoesadiantamentosavulsos.show.html'),
      controller: 'MeurhSolicitacoesadiantamentosavulsosFormShowController',
      controllerAs: 'mrh_slctcsdntmntsvlss_frm_shw_cntrllr'
    })
    .state('meurh_solicitacoesadiantamentosavulsos_edit', {
      parent: 'meurh_solicitacoesadiantamentosavulsos',
      url: '/:solicitacao/edit/edit',
      resolve: resolve,
      template: require('html-loader!./edit/solicitacoesadiantamentosavulsos.edit.html'),
      controller: 'Meurh\SolicitacoesadiantamentosavulsosFormController',
      controllerAs: 'mrh_slctcsdntmntsvlss_frm_edit_cntrllr'
    });
}];
